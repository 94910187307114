import { Hero, HeroClass } from './Hero'
import { Item } from './Item'
import { Rarity } from './Main'

// TODO reinforcement: number (BACKEND EquipmentParamsAttributes)
export interface EquipmentParams {
    vitality: number
    physicalDefense: number
    power: number
    durability: number
    agility: number
    magicDefense: number
    speed: number
    luck: number
    minDamage: number
    maxDamage: number
    sharpening: number
    reinforcement: number
    criticalDamage: number
    criticalStrikeChance: number
}

export interface Equipment extends EquipmentParams {
    readonly id: string
    name: EquipmentName

    computed?: EquipmentConfigType | null

    itemId: string
    heroId: string | null
    tokenId: string | null

    item?: Item
    hero?: Hero
    // enchantment: number
}

export enum EquipmentName {
    /**
     * weapons
     */
    RUSTY_SWORD = 'RUSTY_SWORD',
    RUSTY_AXE = 'RUSTY_AXE',
    ROUGH_IRON_AXE = 'ROUGH_IRON_AXE',
    IRON_AXE = 'IRON_AXE',
    STEEL_AXE = 'STEEL_AXE',
    FIRE_AXE = 'FIRE_AXE',
    DARK_STEEL_AXE = 'DARK_STEEL_AXE',
    HARDENED_AXE = 'HARDENED_AXE',
    DEMONIC_AXE = 'DEMONIC_AXE',
    LAVA_STONE_AXE = 'LAVA_STONE_AXE',
    BLOOD_CRYSTAL_AXE = 'BLOOD_CRYSTAL_AXE',

    /**
     * armors
     */

    STEEL_POLEAXE = 'STEEL_POLEAXE',

    /**
     * accessories
     */
}

export enum EquipmentClass {
    WEAPON = 'WEAPON',
    ARMOR = 'ARMOR',
    ACCESSORY = 'ACCESSORY',
}

export enum EquipmentType {
    HELMET = 'HELMET',
    BREASTPLATE = 'BREASTPLATE',
    ROB = 'ROB',
    PANTS = 'PANTS',
    BOOTS = 'BOOTS',
    GLOVES = 'GLOVES',
    BUCKLER = 'BUCKLER',
    BOW = 'BOW',
    DAGGER = 'DAGGER',
    HAMMER = 'HAMMER',
    SWORD = 'SWORD',
    DUAL_SWORD = 'DUAL_SWORD',
    STAFF = 'STAFF',
    TOWER_SHIELD = 'TOWER_SHIELD',
    SPELL_BOOK = 'SPELL_BOOK',
    DUAL_AXE = 'DUAL_AXE',
    RING = 'RING',
    AMULET = 'AMULET',
}

export enum EquipmentTypeWeapon {
    BUCKLER = 'BUCKLER',
    BOW = 'BOW',
    DAGGER = 'DAGGER',
    HAMMER = 'HAMMER',
    SWORD = 'SWORD',
    DUAL_SWORD = 'DUAL_SWORD',
    STAFF = 'STAFF',
    TOWER_SHIELD = 'TOWER_SHIELD',
    SPELL_BOOK = 'SPELL_BOOK',
    DUAL_AXE = 'DUAL_AXE',
}

export enum EquipmentMaterial {
    FABRIC = 'FABRIC',
    LEATHER = 'LEATHER',
    CHAIN_MAIL = 'CHAIN_MAIL',
    PLATE = 'PLATE',
}

export enum EquipmentGrid {
    ONE = 'ONE',
    TWO = 'TWO',
}

export enum EquipmentBodyPart {
    HANDS = 'HANDS',
    HEAD = 'HEAD',
    BODY = 'BODY',
    LEGS = 'LEGS',
    FOOTS = 'FOOTS',
    WEAPON = 'WEAPON',
}

export type EquipmentTypeBaseConfig =
    | {
          readonly type: EquipmentType
          readonly class: EquipmentClass
          readonly heroClasses: HeroClass[]
          readonly grid?: EquipmentGrid
          readonly bodyPart: EquipmentBodyPart
      }
    | undefined

export type EquipmentBaseConfig = {
    readonly rarity: Rarity
    readonly params: EquipmentParams
}

export type EquipmentConfigType = {
    base: EquipmentTypeBaseConfig
    material?: EquipmentMaterial
} & EquipmentBaseConfig

export type EquipmentSlot = {
    heroPart: EquipmentBodyPart
    equipment?: Equipment
    icon?: string
}
