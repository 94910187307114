import { Action, createAction, handleActions } from 'redux-actions'

export function createStoreState<T>(act: string, def: T) {
    const action = createAction(act, (val: T) => val)

    const initialState: T = def

    const reducer = handleActions(
        {
            [act]: (state, action: Action<T>) => action.payload,
        },
        initialState
    )
    return { action, reducer }
}
