import { FC, useEffect, useRef, useState } from 'react'
import { RectSize } from '../../Config/Field'
import { DragEventer } from '../../Core/DragEventer'

const xCenter = window.innerWidth / 2
const yCenter = window.innerHeight / 2

export const Map: FC<{
    type?: 'tile'
    minX?: number
    minY?: number
    maxX?: number
    maxY?: number
}> = ({ children, type, minX, minY, maxX, maxY }) => {
    const [currentPos, setCurrentPos] = useState({
        x: xCenter,
        y: yCenter,
    })

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!ref.current) return
        const eventer = new DragEventer(ref.current, {
            defaultX: xCenter,
            defaultY: yCenter,
            maxX,
            minX,
            maxY,
            minY,
            smooth: 0.3,
            onMove: ({ x, y }) => {
                setCurrentPos(() => ({
                    x,
                    y,
                }))
            },
        })
        return () => eventer.destroy()
    }, [ref, maxX, maxY, minX, minY])

    return (
        <div
            className={`map ${type ? `map_${type}` : ''}`}
            style={{
                backgroundPosition: `${currentPos.x}px ${currentPos.y}px`,
                backgroundSize: `${RectSize * 20}px`,
            }}
            ref={ref}
        >
            <div
                className="map__inner"
                style={{
                    transform: `translate3d(${currentPos.x}px, ${currentPos.y}px, 0)`,
                }}
            >
                {children}
            </div>
        </div>
    )
}
