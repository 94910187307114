import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SetStateAction } from '../../../../../Types/Main'
import { useTypedSelector } from '../../../../../Hooks/useTypedSelector'
import { gameAPI } from '../../../../../Common/Api'
import { BN } from 'bn.js'
import { LootboxImage, LootboxPrice } from '../HallOfKeys/helpers'
import { Button } from '../../../../UI/Button'
import { EggPower, EggSummonChances } from './helpers'
import { EggResult } from './EggResult'
import { HeroEgg, HeroEggOpenResult } from '../../../../../Types/HeroEgg'
import { useEgg } from '../../../../../Hooks/useEgg'

// !! Seed Params
const priceHatchEgg = 10000

export const ItemEgg: FC<{
    selectedEgg: HeroEgg
    setSelectedEgg: SetStateAction<HeroEgg | undefined>
}> = ({ selectedEgg, setSelectedEgg }) => {
    const [lootboxOpenResult, setLootboxOpenResult] =
        useState<HeroEggOpenResult>()
    const { img } = useEgg(selectedEgg)
    const player = useTypedSelector((s) => s.player)

    const closeResult = useCallback(
        (item: HeroEgg | undefined) => {
            setSelectedEgg(item)
            setLootboxOpenResult(undefined)
        },
        [setSelectedEgg, setLootboxOpenResult]
    )

    async function handleOpenLootbox() {
        const res = await gameAPI.heroEgg.open({
            item_id: selectedEgg.itemId,
        })

        setLootboxOpenResult(res)
    }

    const isCanOpenLootbox = useMemo(() => {
        // TODO !! Handle player is undefined
        if (!player) return false

        const gold = new BN(player.gold_value)
        const price = new BN(priceHatchEgg)

        return gold.gte(price)
    }, [player])

    return (
        <div className="lootbox">
            {!selectedEgg ? (
                <NoEggFound setSelectedEgg={setSelectedEgg} />
            ) : lootboxOpenResult ? (
                <EggResult
                    result={lootboxOpenResult}
                    setEggOpenResult={closeResult}
                    previousEgg={selectedEgg}
                />
            ) : (
                <>
                    <LootboxImage img={img} />
                    <div className="lootbox-info">
                        <EggPower eggPower={selectedEgg.n_power} />
                        <EggSummonChances />
                    </div>
                    <div className="lootbox__buttons center">
                        <Button onClick={() => setSelectedEgg(undefined)}>
                            Назад
                        </Button>
                        <Button
                            disabled={isCanOpenLootbox}
                            onClick={handleOpenLootbox}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                Summon a Hero!
                                <LootboxPrice price={priceHatchEgg} />
                            </div>
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

const NoEggFound: FC<{
    setSelectedEgg: SetStateAction<HeroEgg | undefined>
}> = ({ setSelectedEgg }) => {
    useEffect(() => setSelectedEgg(undefined), [setSelectedEgg])
    return <></>
}
