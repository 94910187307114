import classNames from 'classnames'
import { FC, useState } from 'react'
import { images } from '../../Assets'
import { usePlayer } from '../../Hooks/usePlayer'
import { Player } from '../../Types/Player'
import { User } from '../../Types/User'
import { Popup } from '../Popup'
import { ProfileWrapper } from '../Profile'
import { ProgressBarGexed } from '../UI/ProgressBar'

export const UserBar: FC<{ user: User; player: Player; className?: string }> =
    ({ user, player, className }) => {
        const [showProfile, setShowProfile] = useState(false)

        const { nextExp, currentExp, hideExp } = usePlayer(player)

        return (
            <>
                <div
                    className={classNames('user-bar', className)}
                    onClick={() => setShowProfile(true)}
                >
                    <div className="user-bar__avatar">
                        <img src={images.icon_profile_avatar} alt="" />
                    </div>
                    <div className="user-bar__info">
                        <div className="user-bar__name">{user.name}</div>
                        <ProgressBarGexed
                            current={currentExp}
                            max={nextExp}
                            hideValue={hideExp}
                            className="user-bar__progress"
                        ></ProgressBarGexed>
                    </div>
                    <div className="user-bar__vip">{player.kingLevel}</div>
                </div>
                <Popup show={[showProfile, setShowProfile]} title="Profile">
                    <ProfileWrapper user={user} player={player} />
                </Popup>
            </>
        )
    }
