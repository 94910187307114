import { FC, useCallback } from 'react'
import { EggHatch } from './EggHatch'
import {
    EggStatus,
    HeroEgg,
    HeroEggOpenResult,
} from '../../../../../Types/HeroEgg'
import { EggRarityUp } from './EggRarityUp'
import { EggPowerUp } from './EggPowerUp'
import { isEggRarityUp } from './helpers'

export const EggResult: FC<{
    previousEgg: HeroEgg
    result: HeroEggOpenResult
    setEggOpenResult: (egg: HeroEgg | undefined) => void
}> = ({ previousEgg, result, setEggOpenResult }) => {
    const closeResultAndOpenList = useCallback(
        () => setEggOpenResult(undefined),
        [setEggOpenResult]
    )

    return (
        <>
            {result.status === EggStatus.Hero && result.hero ? (
                <EggHatch
                    hero={result.hero}
                    closeEggResult={closeResultAndOpenList}
                />
            ) : result.egg?.heroEgg &&
              isEggRarityUp(previousEgg, result.egg.heroEgg) ? (
                <EggRarityUp
                    resultEgg={result.egg.heroEgg}
                    closeEggResult={closeResultAndOpenList}
                    previousRarity={previousEgg.rarity}
                />
            ) : result.egg?.heroEgg ? (
                <EggPowerUp
                    closeEggResult={() => setEggOpenResult(result.egg?.heroEgg)}
                    egg={result.egg.heroEgg}
                />
            ) : (
                <></>
            )}
        </>
    )
}
