import { FC } from 'react'

export const LootboxImage: FC<{
    img?: string
}> = ({ img }) => {
    return (
        <div className="lootbox-image">
            <img src={img} alt="" />
        </div>
    )
}
