import { localErrors } from '../../Common/Api/Errors'
import { Rarity } from '../../Types/Main'
import { ResourceType } from '../../Types/Resource'

export const en = {
    translation: {
        error: {
            'Invalid value': '{{param}} is invalid value',
            [localErrors.emailOrPasswordDoesntMatch]:
                "E-mail or password doesn't match",
            [localErrors.userIsUndefined]: "User isn't found",
            [localErrors.ethAddressIsAlreadyUsing]:
                'Your address is already using',
            [localErrors.passwordDoesntMatch]: "Old password doesn't match",
        },
        field: {
            old_pass: 'Old password',
            new_pass: 'New password',
            email: 'E-mail',
        },
        name: {
            lootbox: {
                [Rarity.COMMON]: 'Common Lootbox',
                [Rarity.RARE]: 'Rare Lootbox',
                [Rarity.EPIC]: 'Epic Lootbox',
                [Rarity.MYTHICAL]: 'Mythical Lootbox',
                [Rarity.LEGENDARY]: 'Legendary Lootbox',
            },
            heroegg: {
                [Rarity.COMMON]: 'Common Hero Egg',
                [Rarity.RARE]: 'Rare Hero Egg',
                [Rarity.EPIC]: 'Epic Hero Egg',
                [Rarity.MYTHICAL]: 'Mythical Hero Egg',
                [Rarity.LEGENDARY]: 'Legendary Hero Egg',
            },
            resource: {
                [ResourceType.WildFireCom]: 'Wild Fire',
                [ResourceType.WildFireRare]: 'Wild Fire',
                [ResourceType.WildFireEpic]: 'Wild Fire',
                [ResourceType.WildFireMythical]: 'Wild Fire',
                [ResourceType.WildFireLegendary]: 'Wild Fire',
                [ResourceType.ArcaneDustT1]: 'Arcane Dust',
                [ResourceType.ArcaneDustT2]: 'Arcane Dust',
                [ResourceType.ArcaneDustT3]: 'Arcane Dust',
            },
        },
        description: {
            resource: {
                [ResourceType.Leather]:
                    'It is used in the manufacture of simple weapons and equipment',
                [ResourceType.Wood]:
                    'It is used in the manufacture of simple weapons and tools',
                [ResourceType.IronOre]:
                    'Used for the manufacture of iron ingots',
                [ResourceType.SilverOre]:
                    'It is used for the manufacture of silver ingots.',
                [ResourceType.Coal]:
                    'It is actively used in the metallurgy of the Kingdom',
                [ResourceType.StarShard]:
                    'Fragments of stars are often used to create different types of resources.',
                [ResourceType.Stardust]:
                    'A beautiful resource that jewelers and gunsmiths like to use',
                [ResourceType.ArcaneDustT1]:
                    'It has magical properties, is actively used in the Kingdom.',
                [ResourceType.Parchment]:
                    'Parchment made with the help of magicians participates in the creation of weapons.',
                [ResourceType.ScorchingStone]: 'Used in alchemy and forge',
                [ResourceType.CuredLeather]:
                    'It is used to create weapons and equipment of a higher level.',
                [ResourceType.EnchantedLeather]:
                    'Magically enhanced leather is used in the manufacture of magical items',
                [ResourceType.CuredWood]:
                    'It is used in the preparation of more complex types of weapons and tools.',
                [ResourceType.EnchantedWood]:
                    'It is used in the manufacture of complex magical items.',
                [ResourceType.StarMetalOre]:
                    'This rare resource is often used to make silver ingots.',
                [ResourceType.IronIngot]:
                    'Forges often like to use this resource in the manufacture of weapons and equipment',
                [ResourceType.SilverIngot]:
                    'A rare type of ingot is used for the manufacture of complex elements of ammunition',
                [ResourceType.ArcaneDustT2]:
                    'Enhanced magical properties of magic dust allow you to make rarer items',
                [ResourceType.MysticParchment]:
                    'Magically enhanced parchment is used to make higher-level weapons.',
                [ResourceType.ArmorPlate]:
                    'A necessary item if you need to upgrade your armor in the forge.',
                [ResourceType.Whetstone]:
                    'A necessary item if you need to sharpen weapons in the forge',
                [ResourceType.InstrumentsBlacksmith]:
                    "The blacksmith's tools are used to make and improve weapons and equipment",
                [ResourceType.InstrumentsJeweler]:
                    "Jeweler's tools are used to make accessories and jewelry",
                [ResourceType.FlareCrystal]:
                    'Often used by jewelers in their business',
                [ResourceType.HardenedLeather]:
                    'Leather after complex processing in forges is used for the manufacture of weapons, equipment and more complex resources',
                [ResourceType.SpiritLeather]:
                    'Enchanted leather is used in a special way in the creation of rare types of magical equipment',
                [ResourceType.HardenedWood]:
                    'The wood processed by blacksmiths is used in the creation of rare weapons',
                [ResourceType.SpiritWood]:
                    'Enchanted by magicians, wood is used in the creation of rare magical items.',
                [ResourceType.TitaniumOre]:
                    'It is used in metallurgy and the creation of other resources.',
                [ResourceType.SteelIngot]:
                    'Used for the manufacture of weapons and uniforms',
                [ResourceType.StarMetalIngot]:
                    'This special kind of ingots is great for making magic items',
                [ResourceType.ArcaneDustT3]:
                    'This variant of dust has the greatest magical power and is used in the manufacture of complex magical items and weapons',
                [ResourceType.ArcaneParchment]:
                    'With it, you can make the most powerful spells on objects',
                [ResourceType.ExcellentArmorPlate]:
                    'Perfect for improving armor',
                [ResourceType.ExcellentWhetstone]:
                    'Sharpening weapons is even more effective',
                [ResourceType.ExcellentInstrumentsBlacksmith]:
                    'A mandatory tool of a good blacksmith, increase the effectiveness of creating weapons, are used in the manufacture of objects',
                [ResourceType.ExcellentInstrumentsJeweler]:
                    'The best tools for the jeweler, increase the efficiency of creating accessories, are used in the manufacture of accessories',
                [ResourceType.BlessedLeather]:
                    'Maximally charged with magicians, the skin is used to create equipment of high rarities',
                [ResourceType.BlessedWood]:
                    'The tree, which is maximally charged with magicians, is used to create equipment of high rarity',
                [ResourceType.TitaniumIngot]:
                    'It is used in the forge for the manufacture of weapons of high rarity',
                [ResourceType.MythrilIngot]:
                    'It is used in the forge for the manufacture of weapons of high rarity',
                [ResourceType.OrichalconIngot]:
                    'It is used in the forge for the manufacture of weapons of high rarity',
                [ResourceType.WeaponPiece]:
                    'From the wreckage, you can create weapons of high rarity',
                [ResourceType.ArmorPiece]:
                    'From the wreckage, you can create weapons of high rarity',
                [ResourceType.Gold]:
                    'One of the main resources of the Kingdom, you use it to pay for goods and services',
                [ResourceType.WildFireCom]:
                    'A particularly valuable precious resource for the jewelers of the Kingdom. \nIf you want to make a cool accessory to increase the characteristics of the heroes - this resource is for you!',
                [ResourceType.WildFireRare]:
                    'A particularly valuable precious resource for the jewelers of the Kingdom. If you want to make a cool accessory to increase the characteristics of the heroes - this resource is for you!',
                [ResourceType.WildFireEpic]:
                    'A particularly valuable precious resource for the jewelers of the Kingdom. If you want to make a cool accessory to increase the characteristics of the heroes - this resource is for you!',
                [ResourceType.WildFireMythical]:
                    'A particularly valuable precious resource for the jewelers of the Kingdom. If you want to make a cool accessory to increase the characteristics of the heroes - this resource is for you!',
                [ResourceType.WildFireLegendary]:
                    'A particularly valuable precious resource for the jewelers of the Kingdom. If you want to make a cool accessory to increase the characteristics of the heroes - this resource is for you!',
                [ResourceType.RECIPE_SWORD]: 'RECIPE SWORD',
            },
        },
    },
}
