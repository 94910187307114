import { t } from 'i18next'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { authAPI } from '../../Common/Api'
import { nErr } from '../../Common/notifications'
import { Button } from '../../Components/UI/Button'
import { ScreenCard } from '../../Components/UI/Card'
import { Form, FormElement } from '../../Components/UI/Form'
import { Input } from '../../Components/UI/Input'
import { useInit } from '../../Hooks/useInit'
import { routes } from '../../Router/RoutesName'

export const RestorePassPage = () => {
    const [codeSending, setCodeSending] = useState(false)

    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [pass, setPass] = useState('')
    const [pass_remember, setPass_remember] = useState('')

    const [codeSent, setCodeSent] = useState(false)

    const initial = useInit()

    async function sendData(e?: FormEvent) {
        e?.preventDefault()
        if (!codeSent) {
            setCodeSending(true)
            try {
                await authAPI.genCode({ email, type: 'recover' })
                setCodeSent(true)
            } catch (error) {
                console.error(error)
            }
            setCodeSending(false)

            return
        }
        if (pass !== pass_remember) return nErr(t('Password not confirmed!'))

        await authAPI.recoverPassword({
            email,
            code: +code,
            password: pass,
        })
        initial()
    }

    return (
        <ScreenCard
            title="Restore account"
            open={true}
            bg="art"
            notification={codeSent ? 'Code sent' : undefined}
            loading={codeSending}
            logo
        >
            <Form onSubmit={sendData}>
                <FormElement>
                    <Input
                        state={[email, setEmail]}
                        placeholder="E-mail"
                        disabled={codeSent}
                    ></Input>
                </FormElement>
                {codeSent && (
                    <>
                        <FormElement>
                            <Input
                                state={[code, setCode]}
                                placeholder="E-mail code"
                                type={'number'}
                            ></Input>
                        </FormElement>
                        <FormElement>
                            <Input
                                state={[pass, setPass]}
                                placeholder="Password"
                                type="password"
                            ></Input>
                        </FormElement>
                        <FormElement>
                            <Input
                                state={[pass_remember, setPass_remember]}
                                placeholder="Password Confirmation"
                                type="password"
                            ></Input>
                        </FormElement>
                    </>
                )}
                <FormElement>
                    <Link to={routes.auth.register}>Sign Up</Link>
                </FormElement>
                <FormElement>
                    <Link to={routes.auth.login}>Sign In</Link>
                </FormElement>
                <FormElement>
                    <Button
                        type="submit"
                        disabled={codeSending}
                        color="blue"
                        fill
                    >
                        {codeSent ? 'Restore' : 'Send code'}
                    </Button>
                </FormElement>
            </Form>
        </ScreenCard>
    )
}
