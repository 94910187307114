import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Quest } from '../Types/Quest'
import { useInterval } from './useInterval'

export function useQuest(quest?: Quest) {
    const [differenceTime, setDifferenceTime] = useState<string>()

    const findDiff = useCallback(() => {
        if (!quest) return
        const diff = moment(quest.finishAt).diff(moment())
        if (diff < 0) return setDifferenceTime(undefined)
        const durations = moment.duration(diff)
        setDifferenceTime(
            `${durations.hours()}:${durations.minutes()}:${durations.seconds()}`
        )
    }, [quest])

    const [, setActive] = useInterval(findDiff, 1000)

    useEffect(() => {
        if (!quest) return setActive(false)
        setActive(true)
    }, [quest, setActive])

    return {
        differenceTime,
    }
}
