import { Lootbox } from '../Types/Lootbox'

interface Param {
    name: string
    value: number
    nextValue: number
    symbol: '%' | ''
}

export const useLootboxParams = (lootbox: Lootbox): Param[] => [
    {
        name: 'Шанс открытия',
        value: lootbox?.props.total_open_chance,
        nextValue:
            lootbox?.props.next_total_open_chance -
            lootbox?.props.total_open_chance,
        symbol: '%',
    },
    {
        name: 'Мощность',
        value: lootbox?.n_power,
        nextValue: lootbox?.props.next_n_power - lootbox?.n_power,
        symbol: '',
    },
    {
        name: 'Вместимость',
        value: lootbox?.props.mass,
        nextValue: lootbox?.props.next_mass - lootbox?.props.mass,
        symbol: '',
    },
    {
        name: 'Повышение редкости сундука',
        value: lootbox?.props.fail_rarity_up_chance,
        nextValue: 1,
        symbol: '%',
    },
]
