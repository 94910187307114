import classNames from 'classnames'
import { FC, ReactNode, useRef } from 'react'
import { useHorizontalScroll } from '../../Hooks/useHorizontalScroll'

export const CastleCards: FC = ({ children }) => {
    const listRef = useRef<HTMLDivElement>(null)

    useHorizontalScroll(listRef)

    return (
        <div className="castle-slider">
            <div className="castle-slider__list" ref={listRef}>
                {children}
            </div>
        </div>
    )
}
export const CastleCardItem: FC<{
    name: string
    icon: ReactNode
    upInfo?: string
    downInfo?: ReactNode
    onClick?: (name: string) => void
    bg?: 'kingticket'
    disabled?: boolean
}> = ({ name, icon, onClick, upInfo, downInfo, bg, disabled }) => {
    return (
        <div
            key={name}
            className={classNames(
                'castle-slider__item castle-slider__item_position-marginRight',
                `castle-slider__item-${bg || 'default'}`,
                { 'castle-slider__item-disabled': disabled }
            )}
            onClick={() => onClick && onClick(name)}
        >
            <div className="castle-slider__name align-self glow">{name}</div>
            <div className="castle-slider__icon align-self glow">{icon}</div>
            {!!upInfo && (
                <div
                    className="castle-slider__upinfo"
                    data-value={upInfo}
                ></div>
            )}
            {!!downInfo && (
                <div className="castle-slider__downinfo">{downInfo}</div>
            )}
        </div>
    )
}
