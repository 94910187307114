import { FC, useState } from 'react'
import { Hero, HeroComputed } from '../../../../../Types/Hero'
import { EggImage } from './helpers'
import { images } from '../../../../../Assets'
import { Description } from '../../../../Heroes/HeroMeta/Description'
import { Params } from '../../../../Heroes/HeroMeta/Params'
import { useHeroEquipment } from '../../../../../Hooks/useHero'
import { Button } from '../../../../UI/Button'

const dropStats = new Set<keyof HeroComputed>([
    'lvl',
    'next_exp',
    'max_hp',
    'maxDamage',
    'minDamage',
    'criticalStrikeChance',
    'speed',
    'durability',
    'power',
])

export const EggHatch: FC<{
    hero: Hero
    closeEggResult: () => void
}> = ({ hero, closeEggResult }) => {
    const [showInfo, setShowInfo] = useState(false)
    const stats = useHeroEquipment(hero.computed, dropStats)
    return (
        <>
            <EggImage img={images.hero_warrior_common}></EggImage>
            <div className="lootbox-info egg_hatch">
                <div className="lootbox-info__header">
                    <p>Congratulations!</p>
                    <p>{hero.name} hails you!</p>
                </div>
                {showInfo ? (
                    <div className="heroes-hero-info__stats egg_hatch__stats lootbox_frame">
                        <Params params={stats}></Params>
                    </div>
                ) : (
                    <>
                        <div className="egg_hatch__rarity">{hero.rarity}</div>
                        <Description
                            classes="egg_hatch__description"
                            hero={hero}
                        />
                    </>
                )}
            </div>
            <div className="lootbox__buttons center">
                <Button onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? 'Hide' : 'Info'}
                </Button>
                <Button onClick={closeEggResult}>Take</Button>
            </div>
        </>
    )
}
