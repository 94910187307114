import { FC, useCallback } from 'react'
import { Equipment } from '../../../Types/Equipment'
import { EquipmentState } from '../../../Types/Inventory'
import { Item } from '../../../Types/Item'

export const Params: FC<{
    params: EquipmentState[]
    selectedItem?: Item
    currentEquipment?: Equipment
}> = ({ params, selectedItem, currentEquipment }) => {
    const changeValueParam = useCallback(
        (name: string) => {
            const selectedValue =
                new Map<string, number>(
                    Object.entries(selectedItem?.equipment || {})
                ).get(name) || 0

            const currentValue =
                new Map<string, number>(
                    Object.entries(currentEquipment || {})
                ).get(name) || 0

            const result = (
                changeValue: number,
                color: 'isGreen' | 'isRed' = 'isGreen'
            ) => ({ changeValue, color })

            if (selectedValue && currentValue) {
                if (selectedValue > currentValue)
                    return result(selectedValue - currentValue)
                if (selectedValue < currentValue && selectedValue !== 0)
                    return result(selectedValue, 'isRed')
                return
            }

            if (selectedValue && !currentValue) return result(selectedValue)
        },
        [selectedItem, currentEquipment]
    )

    return (
        <>
            {params.map((stat, i) => {
                const newParam = stat.name && changeValueParam(stat.name)
                const spanEl = newParam && (
                    <span
                        className={`heroes-hero-info__num--${newParam.color}`}
                    >
                        {`${newParam.color === 'isGreen' ? '+ ' : '- '} ${
                            newParam.changeValue
                        }`}
                    </span>
                )
                return (
                    <div className="heroes-hero-info__stat" key={i}>
                        <div className="heroes-hero-info__name">
                            {stat.label}
                        </div>
                        <div className="heroes-hero-info__row">
                            <img
                                src={stat.icon.toString()}
                                className="heroes-hero-info__icon"
                                alt={stat.label.toString()}
                            ></img>
                            <div className="heroes-hero-info__num">
                                {stat.value} {spanEl}
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
