import { FC } from 'react'
export const EggSummonChances: FC<{}> = () => {
    return (
        <div className="egg_chances">
            <p className="chances__text">
                By spending a regular egg and some gold, you can perform a
                normal summoning ritual!
            </p>
            <div className="chances__list">
                {[1, 2, 3, 4, 5].map((v, i) => (
                    <div key={i} className="chances__list_item">
                        <div className="chance_hero">
                            {/*<img src={images.hero_warrior_icon} alt="" />*/}
                        </div>

                        <span className="chance_value">20%</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
