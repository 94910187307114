import classNames from 'classnames'
import { FC } from 'react'
import { State } from '../../Types/Main'

export const Checkbox: FC<{ state: State<boolean>; label?: string }> = ({
    state,
    label,
}) => {
    const [s, setS] = state
    return (
        <div className="checkbox">
            <div
                className={classNames('checkbox__body', {
                    'checkbox__body-active': s,
                })}
                onClick={() => setS((old) => !old)}
            ></div>
            {label && <div className="checkbox__label">{label}</div>}
        </div>
    )
}
