import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../Store/main'
import { useTypedSelector } from './useTypedSelector'

export function useDebug() {
    const dispatch = useDispatch()
    const debug = useTypedSelector((s) => s.debug)
    useEffect(() => {
        const press = (e: KeyboardEvent) => {
            if (e.code !== 'KeyD') return
            dispatch(actions.debug(!debug))
        }
        document.addEventListener('keypress', press)
        return () => document.removeEventListener('keypress', press)
    }, [debug, dispatch])
}
