import { FC, useCallback, useMemo } from 'react'
import { Hero } from '../../../Types/Hero'
import { Item } from '../../../Types/Item'
import { Params } from './Params'
import { useHeroEquipment } from './../../../Hooks/useHero'
import { ProgressBar } from '../../UI/ProgressBar'
import { web3Instance } from '../../../Common/blockchain/web3'
import { useTypedSelector } from '../../../Hooks/useTypedSelector'

export const HeroMeta: FC<{
    hero: Hero
    selectedItem?: Item
}> = ({ hero, selectedItem }) => {
    const auth = useTypedSelector((s) => s.auth)

    const currentEquipment = useMemo(() => {
        if (selectedItem)
            return hero.equipment?.find(
                (eq) =>
                    eq.computed?.base?.bodyPart ===
                    selectedItem.equipment?.computed?.base?.bodyPart
            )
    }, [selectedItem, hero])

    const mint = useCallback(async () => {
        const w3i = await (await web3Instance)()
        const token = await w3i.mintHero(hero.id)
        console.log({ hero, token })
    }, [hero])

    const states = useHeroEquipment(hero.computed)
    return (
        <div className="heroes-hero-info">
            {!!auth?.eth_address && !hero.tokenId && (
                <div onClick={mint}>mint</div>
            )}
            <div className="heroes-hero-info__line"></div>
            <div className="heroes-hero-info__stats">
                <Params
                    params={states}
                    selectedItem={selectedItem}
                    currentEquipment={currentEquipment}
                />
            </div>
            <ProgressBar
                width="100%"
                currentProgress={~~hero.experience}
                maxProgress={
                    hero.computed.next_exp ? ~~hero.computed.next_exp : 0
                }
            />
        </div>
    )
}
