import { Item } from './Item'
import { Rarity } from './Main'

export interface Lootbox {
    readonly id: string
    total_power_up: number
    n_power: number //0 -
    rarity: Rarity
    garanty_open: boolean
    props: LootboxProps
    item?: Item
    itemId: string
}

export interface LootboxProps {
    total_open_chance: number
    mass: number
    fail_rarity_up_chance: number
    max_improvement: number
    next_mass: number
    next_n_power: number
    next_total_open_chance: number
}

export enum PropNext {
    'next_total_open_chance' = 'total_open_chance',
    'next_n_power' = 'n_power',
    'next_mass' = 'mass',
    'next_fail_rarity_up_chance' = 'fail_rarity_up_chance',
}

export enum DropType {
    Equipments = 'Equipments',
    Resources = 'Resources',
    HeroEggs = 'HeroEggs',
    Artifacts = 'Artifacts',
}

export enum LootboxStatus {
    'Opened' = 'opened',
    'RarityUp' = 'rarityUp',
    'NotOpened' = 'notOpened',
}

export type OpenResult = {
    status: LootboxStatus
    items: Item[]
    lootbox: Item
}
