import { FC } from 'react'
import { User } from '../Types/User'
import { images } from '../Assets'

export const Referrals: FC<{ users: User[] }> = ({ users }) => {
    return (
        <div className="referrals">
            {users.map((user) => (
                <Referral user={user} key={user.id}></Referral>
            ))}
        </div>
    )
}

export const Referral: FC<{ user: User }> = ({ user }) => {
    return (
        <div className="referral">
            <div className="referral__avatar profile-avatar">
                <img src={images.icon_profile_avatar} alt="" />
            </div>
            <div className="referral__data">
                <div className="referral__text referral__nickname">
                    {user.name}
                </div>
                <div className="referral__text">
                    Checked out sponsorship tickets
                </div>
            </div>
            {/* <div className="referral-add">
                <div className="referral-add__text">
                    To your
                    <br />
                    King Power
                </div>
                <div className="referral-add__value">+300</div>
            </div> */}
        </div>
    )
}
