import { FC } from 'react'
import { Hero } from '../../../Types/Hero'
import { images } from '../../../Assets'
import classNames from 'classnames'

//TODO: use hero image and description
export const Description: FC<{
    hero: Hero
    classes?: string
}> = ({ hero, classes }) => {
    return (
        <div className={classNames('heroes__description', classes)}>
            <img
                className="heroes__description-icon"
                src={images.hero_warrior_icon}
                alt=""
            />
            <div className="heroes__description-text">
                Description of the class, what a cool warrior we have, etc.
            </div>
        </div>
    )
}
