import _ from 'lodash'

type Events = {
    onMove?: (pos: { x: number; y: number }) => void
    onDelta?: (delta: { x: number; y: number }) => void

    defaultX?: number
    defaultY?: number

    maxX?: number
    maxY?: number
    minX?: number
    minY?: number
    smooth?: number
}
export class DragEventer {
    private firstMousePos = { x: 0, y: 0 }
    private prevMousePos = { x: 0, y: 0 }
    private currentPos = { x: 0, y: 0 }
    private diffPos = { x: 0, y: 0 }
    private moving = false
    private errorRate = 5

    private animationID: number | undefined = undefined

    constructor(private el: HTMLElement, private events: Events = {}) {
        this.el.addEventListener('mousedown', this.mouseDown)
        this.el.addEventListener('mousemove', this.mouseMove)
        this.el.addEventListener('mouseup', this.mouseUp)

        this.el.addEventListener('touchstart', this.mouseDown)
        this.el.addEventListener('touchmove', this.mouseMove)
        this.el.addEventListener('touchend', this.mouseUp)

        this.el.addEventListener('click', this.mouseUp)

        this.animate()

        this.currentPos = { x: events.defaultX || 0, y: events.defaultY || 0 }
    }

    private getCoords = (e: TouchEvent | MouseEvent) => {
        if ('touches' in e) {
            const touch = e.touches[0]

            return {
                screenX: touch?.clientX || this.prevMousePos.x,
                screenY: touch?.clientY || this.prevMousePos.y,
            }
        }
        return {
            screenX: e.screenX,
            screenY: e.screenY,
        }
    }

    /**
     * обработка инерции
     */
    private animate = () => {
        if (!this.moving && (this.diffPos.x !== 0 || this.diffPos.y !== 0)) {
            let newCoords = {
                x: this.currentPos.x + this.diffPos.x,
                y: this.currentPos.y + this.diffPos.y,
            }

            const defSmooth = 0.5

            const smooth = this.events.smooth || defSmooth

            this.move(newCoords)
            const newDiffX =
                this.diffPos.x > 0
                    ? this.diffPos.x - smooth
                    : this.diffPos.x + smooth

            const newDiffY =
                this.diffPos.y > 0
                    ? this.diffPos.y - smooth
                    : this.diffPos.y + smooth

            this.diffPos = {
                x: _.round(Math.abs(newDiffX) < smooth + 0.3 ? 0 : newDiffX, 2),
                y: _.round(Math.abs(newDiffY) < smooth + 0.3 ? 0 : newDiffY, 2),
            }
        }

        requestAnimationFrame(() => this.animate())
    }

    /**
     *
     * @param coords куда переместить
     */
    private move = (coords: { x: number; y: number }) => {
        const { maxX, maxY, defaultX, defaultY, minX, minY } = this.events

        const newXPos = coords.x
        const newYPos = coords.y

        const oldXPos = this.currentPos.x
        const oldYPos = this.currentPos.y

        this.currentPos = {
            x: newXPos,
            y: newYPos,
        }

        if (!_.isUndefined(maxX) && (defaultX || 0) - newXPos > maxX)
            this.currentPos.x = (defaultX || 0) - maxX

        if (!_.isUndefined(minX) && (defaultX || 0) - newXPos < minX)
            this.currentPos.x = (defaultX || 0) - minX

        if (!_.isUndefined(maxY) && (defaultY || 0) - newYPos > maxY)
            this.currentPos.y = (defaultY || 0) - maxY

        if (!_.isUndefined(minY) && (defaultY || 0) - newYPos < minY)
            this.currentPos.y = (defaultY || 0) - minY

        if (this.events.onMove) this.events.onMove(this.currentPos)

        this.diffPos = { x: newXPos - oldXPos, y: newYPos - oldYPos }
    }

    /**
     * отработка нажатия
     * @param e
     */
    private mouseDown = (e: MouseEvent | TouchEvent) => {
        this.moving = true
        const { screenX, screenY } = this.getCoords(e)
        this.prevMousePos = { x: screenX, y: screenY }
        this.firstMousePos = { x: screenX, y: screenY }
    }

    /**
     * обработка движений мыши
     * @param e
     * @returns
     */
    private mouseMove = (e: MouseEvent | TouchEvent) => {
        if (!this.moving) return
        e.preventDefault()
        const { screenX, screenY } = this.getCoords(e)

        const xDelta = screenX - this.prevMousePos.x
        const yDelta = screenY - this.prevMousePos.y

        const newXPos = this.currentPos.x + xDelta
        const newYPos = this.currentPos.y + yDelta

        if (this.events.onDelta) this.events.onDelta({ x: xDelta, y: yDelta })
        this.move({ x: newXPos, y: newYPos })
        this.prevMousePos = { x: screenX, y: screenY }
    }

    private mouseUp = (e: MouseEvent | TouchEvent) => {
        this.moving = false
        const { screenX, screenY } = this.getCoords(e)
        if (
            Math.abs(screenX - this.firstMousePos.x) < this.errorRate &&
            Math.abs(screenY - this.firstMousePos.y) < this.errorRate
        ) {
            return
        }
        e.preventDefault()
        e.stopPropagation()
    }

    public destroy = () => {
        this.el.removeEventListener('mousedown', this.mouseDown)
        this.el.removeEventListener('mousemove', this.mouseMove)
        this.el.removeEventListener('mouseup', this.mouseUp)

        this.el.removeEventListener('touchstart', this.mouseDown)
        this.el.removeEventListener('touchmove', this.mouseMove)
        this.el.removeEventListener('touchend', this.mouseUp)

        this.el.removeEventListener('click', this.mouseUp)

        if (this.animationID) cancelAnimationFrame(this.animationID)
    }
}
