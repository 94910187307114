import { EquipmentClass } from './Equipment'
import { HeroClass } from './Hero'
import { Rarity } from './Main'
import { Region } from './Region'
import { ResourceType } from './Resource'

type lvl = number
type probability = number
type mass = number
type count = number
export interface Field {
    id: string
    x: number
    y: number
    width: number
    height: number
    object: FieldObject
    z_index: number

    questIdentify: number | null
    questEnemies: [HeroClass, lvl][]
    questLevel: number | null

    questConfig: FieldQuest | null
    questMass: FieldQuestMassConfigType | null
    questComputed: FieldQuestComputed | null

    fractId?: string
    regionId: string
    region?: Region
}

export interface FieldQuestMassConfigType {
    questTimeMass: number
    enemyLevelMassMin: number
    enemyLevelMassMax: number
    enemyQuantityMassMin: number
    enemyQuantityMassMax: number
    equipmentProbabilityMass: number
    resourcesQuantityMass: number
}

export interface FieldQuestComputed {
    questTime: number
    enemyQuantityMin: number
    enemyQuantityMax: number
    enemyLevelMin: number
    enemyLevelMax: number
    equipmentProbability: number
}

export interface FieldQuest {
    location: FieldObject
    baseTime: number
    enemyClass: [HeroClass, probability][]
    minEnemyLvlBase: number
    maxEnemyLvlBase: number
    minEnemyCountBase: number
    maxEnemyCountBase: number
    equipment: {
        probabilityBase: number
        classProbability: [EquipmentClass, probability][]
        rarityProbability: [Rarity, probability][]
    }
    resources: [ResourceType, mass, count][]
}

export enum FieldObject {
    CASTLE_1 = 'CASTLE_1',
    CASTLE_2 = 'CASTLE_2',
    CASTLE_3 = 'CASTLE_3',
    MAGNUS_CASTLE = 'MAGNUS_CASTLE',
    SAARMAR_CASTLE = 'SAARMAR_CASTLE',
    ERENTAS_CASTLE = 'ERENTAS_CASTLE',

    RUINS = 'RUINS',
    BANDIT_CAMP = 'BANDIT_CAMP',
    CRYPT = 'CRYPT',
    ABANDONED_TOWER = 'ABANDONED_TOWER',

    MOUNTAIN_1 = 'MOUNTAIN_1',
    MOUNTAIN_2 = 'MOUNTAIN_2',
    MOUNTAIN_3 = 'MOUNTAIN_3',
    MOUNTAIN_4 = 'MOUNTAIN_4',
    MOUNTAIN_5 = 'MOUNTAIN_5',
    MOUNTAIN_6 = 'MOUNTAIN_6',
    MOUNTAIN_7 = 'MOUNTAIN_7',
    MOUNTAIN_8 = 'MOUNTAIN_8',
    MOUNTAIN_9 = 'MOUNTAIN_9',
    MOUNTAIN_10 = 'MOUNTAIN_10',
    MOUNTAIN_11 = 'MOUNTAIN_11',
    MOUNTAIN_12 = 'MOUNTAIN_12',

    OAK_1 = 'OAK_1',
    OAK_2 = 'OAK_2',
    OAK_3 = 'OAK_3',
    OAK_4 = 'OAK_4',
    OAK_5 = 'OAK_5',
    OAK_6 = 'OAK_6',
    OAK_7 = 'OAK_7',
    OAK_8 = 'OAK_8',
    OAK_9 = 'OAK_9',
    OAK_10 = 'OAK_10',
    OAK_11 = 'OAK_11',
    OAK_12 = 'OAK_12',
    OAK_13 = 'OAK_13',

    PINE_TREE_1 = 'PINE_TREE_1',
    PINE_TREE_2 = 'PINE_TREE_2',
    PINE_TREE_3 = 'PINE_TREE_3',
    PINE_TREE_4 = 'PINE_TREE_4',
    PINE_TREE_5 = 'PINE_TREE_5',
    PINE_TREE_6 = 'PINE_TREE_6',
    PINE_TREE_7 = 'PINE_TREE_7',
    PINE_TREE_8 = 'PINE_TREE_8',
    PINE_TREE_9 = 'PINE_TREE_9',
    PINE_TREE_10 = 'PINE_TREE_10',
    PINE_TREE_11 = 'PINE_TREE_11',
    PINE_TREE_12 = 'PINE_TREE_12',

    CLAIM = 'CLAIM',
}
