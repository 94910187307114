import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { gameAPI } from '../Common/Api'
import { jwt } from '../Common/Jwt'
import { fetchAuth } from '../Store/Actions/AuthActions'
import { actions } from '../Store/main'

export function useInit() {
    const dispatch = useDispatch()
    return useCallback(async () => {
        await dispatch(fetchAuth())
        const token = jwt.getToken()
        if (!token) return
        const { data } = await gameAPI.fetch()

        dispatch(actions.player(data.player))
        dispatch(actions.items(data.items))
        dispatch(actions.heroes(data.heroes))
        dispatch(actions.mission(data.missions))
        dispatch(actions.quest(data.quests))
        dispatch(actions.sponsor(data.rouletteSponsor))
        dispatch(actions.referrals(data.refs))
        dispatch(actions.rouletteSeason(data.rouletteSeason))
        dispatch(actions.kingLevelsConfig(data.configs.kingLevels))
        dispatch(actions.playerLevelsConfig(data.configs.playerLevels))
        dispatch(actions.rouletteDrops(data.rouletteDrops))
    }, [dispatch])
}
