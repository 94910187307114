import { FC, useMemo, useState } from 'react'
import { Item } from '../../../../../../Types/Item'
import { EggListItem } from './EggListItem'
import { useTypedSelector } from '../../../../../../Hooks/useTypedSelector'
import { ItemEgg } from '../ItemEgg'
import { CastleItemList } from '../../CastleItemList'
import { HeroEgg } from '../../../../../../Types/HeroEgg'

export const HeroCall: FC = () => {
    const items = useTypedSelector((s) => s.items)
    const [selectedEgg, setSelectedEgg] = useState<HeroEgg | undefined>()

    const eggs = useMemo(() => {
        const groupedLootboxes = items.reduce((group, item) => {
            const { heroEgg } = item
            if (!heroEgg) return group
            const rarityItems = group.get(heroEgg.rarity) || []
            return group.set(heroEgg.rarity, [...rarityItems, item])
        }, new Map<string, Item[]>())
        return Array.from(groupedLootboxes.values())
    }, [items])

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {selectedEgg ? (
                <ItemEgg
                    selectedEgg={selectedEgg}
                    setSelectedEgg={setSelectedEgg}
                />
            ) : (
                <CastleItemList
                    Child={EggListItem}
                    items={eggs}
                    setSelectedItem={setSelectedEgg}
                />
            )}
        </div>
    )
}
