import { FC, useState } from 'react'
import { images } from '../../../../Assets'
import { HallOfKeys } from './HallOfKeys/HallOfKeys'
import { Popup, PopupInnerCastle } from '../../../Popup'
import { TextureField } from '../Texture'
import { AltarOfSummon } from './AltarOfSummon/AltarOfSummon'

export const CastleField: FC<{ texture: string }> = ({ texture }) => {
    const [showPopup, setShowPopup] = useState(false)
    return (
        <>
            <TextureField
                texture={texture}
                onClick={() => setShowPopup(true)}
            ></TextureField>
            <Popup
                show={[showPopup, setShowPopup]}
                background={'castle'}
                title="Замок"
            >
                <PopupInnerCastle
                    pages={[
                        {
                            component: <HallOfKeys />,
                            name: 'Hall of Keys',
                            icon: <img src={images.ui_hall_of_keys} alt="" />,
                        },
                        {
                            component: <AltarOfSummon />,
                            name: 'Алтарь призыва',
                            icon: (
                                <img src={images.ui_altar_of_summon} alt="" />
                            ),
                        },
                    ]}
                ></PopupInnerCastle>
            </Popup>
        </>
    )
}
