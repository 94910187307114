import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { images } from '../../Assets'
import { GexHeight, GexWidth } from '../../Config/Field'
import { useGex } from '../../Hooks/useGex'
import { Region } from '../../Types/Region'

export const Gex: FC<{ region: Region }> = ({ region }) => {
    const { top, left, zIndex } = useGex(region.x, region.y)
    const texture = useMemo(() => {
        return images.castle_17
    }, [])
    return (
        <Link
            to={`/sector/${region.id}`}
            className="gex"
            style={{
                top,
                left,
                width: GexWidth,
                height: GexHeight,
                zIndex,
            }}
        >
            <img className="gex__texture" src={images.gex} alt=""></img>
            {texture && (
                <img
                    className="gex__building"
                    src={texture}
                    alt="building texture"
                    style={{
                        zIndex,
                    }}
                />
            )}
        </Link>
    )
}
