import classNames from 'classnames'
import _ from 'lodash'
import { FC, useMemo } from 'react'
import { Equipment } from '../Types/Equipment'
import { Hero } from '../Types/Hero'
import { Item } from '../Types/Item'
import { ItemComponent } from './Item'

export const Inventory: FC<{
    items: Item[]
    type?: 'equipment' | 'default'
    onClickActivity?: (item: Item) => void
    selectedItem?: Item | ''
    appliedEquipments?: Equipment[]
    typeEquipmentInventory?: string
    hero?: Hero
    className?: string
}> = ({
    items,
    type,
    onClickActivity,
    selectedItem,
    appliedEquipments,
    hero,
    className,
    typeEquipmentInventory,
}) => {
    const filtered = useMemo(
        () =>
            items.filter((item) => {
                if (!_.isUndefined(hero)) {
                    if (
                        !_.isEmpty(item.equipment?.heroId) &&
                        hero.id !== item.equipment?.heroId
                    )
                        return false
                    if (
                        !_.isEmpty(
                            item.equipment?.computed?.base?.heroClasses
                        ) &&
                        !_.includes(
                            item.equipment?.computed?.base?.heroClasses,
                            hero.class
                        )
                    )
                        return false

                    if (
                        !_.includes(
                            typeEquipmentInventory,
                            item.equipment?.computed?.base?.bodyPart
                        )
                    )
                        return false
                }
                return true
            }),
        [hero, items, typeEquipmentInventory]
    )

    const appliedItemIds = useMemo(() => {
        return appliedEquipments?.map((eq) => eq.itemId) || []
    }, [appliedEquipments])

    return (
        <div className={classNames(['inventory', className])}>
            <div className="inventory__items">
                {filtered.map((item) => (
                    <ItemComponent
                        item={item}
                        onClickActivity={onClickActivity}
                        isActive={
                            selectedItem ? item.id === selectedItem.id : false
                        }
                        isApplied={appliedItemIds.includes(item.id)}
                        className="inventory__item"
                        key={item.id}
                    ></ItemComponent>
                ))}
            </div>
        </div>
    )
}
