import { Item } from './Item'
import { Rarity } from './Main'

export interface Resource {
    readonly id: string
    oldCount?: number
    count: number
    type: ResourceType
    tier: 1 | 2 | 3 | Rarity

    itemId: string
    item?: Item
}

export enum ResourceType {
    Gold = 'Gold',
    Leather = 'Leather',
    CuredLeather = 'CuredLeather',
    EnchantedLeather = 'EnchantedLeather',
    HardenedLeather = 'HardenedLeather',
    SpiritLeather = 'SpiritLeather',
    BlessedLeather = 'BlessedLeather',

    Wood = 'Wood',
    CuredWood = 'CuredWood',
    EnchantedWood = 'EnchantedWood',
    HardenedWood = 'HardenedWood',
    SpiritWood = 'SpiritWood',
    BlessedWood = 'BlessedWood',

    IronOre = 'IronOre',
    SilverOre = 'SilverOre',
    Coal = 'Coal',
    StarShard = 'StarShard',
    StarMetalOre = 'StarMetalOre',
    TitaniumOre = 'TitaniumOre',
    IronIngot = 'IronIngot',
    SilverIngot = 'SilverIngot',
    SteelIngot = 'SteelIngot',
    StarMetalIngot = 'StarMetalIngot',
    TitaniumIngot = 'TitaniumIngot',
    MythrilIngot = 'MythrilIngot',
    OrichalconIngot = 'OrichalconIngot',

    Stardust = 'Stardust',
    ArcaneDustT1 = 'ArcaneDustT1',
    ArcaneDustT2 = 'ArcaneDustT2',
    ArcaneDustT3 = 'ArcaneDustT3',
    Parchment = 'Parchment',
    MysticParchment = 'MysticParchment',
    ArcaneParchment = 'ArcaneParchment',

    ArmorPlate = 'ArmorPlate',
    ExcellentArmorPlate = 'ExcellentArmorPlate',
    Whetstone = 'Whetstone',
    ExcellentWhetstone = 'ExcellentWhetstone',

    InstrumentsBlacksmith = 'InstrumentsBlacksmith',
    InstrumentsJeweler = 'InstrumentsJeweler',
    ExcellentInstrumentsBlacksmith = 'ExcellentInstrumentsBlacksmith',
    ExcellentInstrumentsJeweler = 'ExcellentInstrumentsJeweler',

    ScorchingStone = 'ScorchingStone',
    FlareCrystal = 'FlareCrystal',
    WeaponPiece = 'WeaponPiece',
    ArmorPiece = 'ArmorPiece',

    WildFireCom = 'WildFireCom',
    WildFireRare = 'WildFireRare',
    WildFireEpic = 'WildFireEpic',
    WildFireMythical = 'WildFireMythical',
    WildFireLegendary = 'WildFireLegendary',

    RECIPE_SWORD = 'RECIPE_SWORD',
}
