import { images } from '../Assets'

class Textures {
    private images: HTMLImageElement[] = []
    public init = async () => {
        this.clearCache()
        return Promise.all(
            Object.values(images).map((value) => {
                return new Promise((res, rej) => {
                    const image = new Image()
                    image.onload = res
                    image.onerror = rej
                    image.src = value
                    this.images.push(image)
                })
            })
        )
    }
    public clearCache = () => {
        this.images.forEach((i) => i.remove())
        this.images = []
    }
}

export const TexturesProvider = new Textures()
