import anime, { AnimeInstance } from 'animejs'
import { useCallback, useState } from 'react'

export function useAnime() {
    const [instance, setInstance] = useState<AnimeInstance | null>(null)

    const animate = useCallback((params: anime.AnimeParams) => {
        return new Promise<anime.AnimeInstance>((res) => {
            const instance = anime({
                ...params,
                complete(e) {
                    res(e)
                },
            })
            setInstance(instance)
        })
    }, [])

    // const stop = useCallback(() => {
    //     if(!instance) return
    //     console.log(instance);

    //     anime.remove(instance)
    //     // setInstance(null)
    // }, [instance])

    return {
        instance,
        animate,
    }
}
