import { FC, useMemo } from 'react'
import { ThreePassComponent } from './helpers/ThreePassComponent'
import { threePassTextureConfs } from '../../Config/UiTextures'
import { percent } from '../../Common/utils'
import classNames from 'classnames'

export const ProgressBar: FC<{
    currentProgress: number
    maxProgress: number
    width?: string | number
    height?: string | number
}> = ({ currentProgress, maxProgress, width, height }) => {
    const per = useMemo(
        () => percent(currentProgress, maxProgress) + '%',
        [currentProgress, maxProgress]
    )
    return (
        <ThreePassComponent
            className="progress-bar"
            preset={{ ...threePassTextureConfs.progress_bar, width, height }}
        >
            <div className="tint"></div>
            {currentProgress > 0 && (
                <ThreePassComponent
                    preset={{
                        ...threePassTextureConfs.bar,
                        width: per,
                    }}
                    className="bar"
                ></ThreePassComponent>
            )}
            <span className="progress">
                {currentProgress}/{maxProgress}
            </span>
        </ThreePassComponent>
    )
}

export const ProgressBarGexed: FC<{
    current: number
    max: number
    className?: string
    hideValue?: boolean
}> = ({ current, max, className, hideValue }) => {
    const per = useMemo(() => percent(current, max), [current, max])

    return (
        <div className={classNames('progress-bar-gexed', className)}>
            <div className="progress-bar-gexed__main">
                <div className="progress-bar-gexed__border">
                    <div
                        className="progress-bar-gexed__line"
                        style={{ width: `${per}%` }}
                    ></div>
                    {!hideValue && (
                        <div className="progress-bar-gexed__value">{`${current}/${max}`}</div>
                    )}
                </div>
            </div>
        </div>
    )
}
