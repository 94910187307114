import { useEffect, useState } from 'react'
import { State } from '../Types/Main'

export function useInterval(fn: () => void, ms: number, immediately?: boolean) {
    const [active, setActive] = useState(true)

    useEffect(() => {
        if (!active) return
        if (immediately) fn()
        const id = setInterval(fn, ms)
        return () => clearInterval(id)
    }, [active, fn, ms, immediately])

    return [active, setActive] as State<boolean>
}
