import { FC } from 'react'
import { Lootbox } from '../../../../../../Types/Lootbox'
import { useLootboxParams } from '../../../../../../Hooks/useLootboxParams'

export const ParamList: FC<{
    lootbox: Lootbox
    compareNextParams?: boolean
}> = ({ lootbox, compareNextParams }) => {
    const paramsIterator = useLootboxParams(lootbox)
    return (
        <div className="lootbox-info__params indent-left">
            {paramsIterator.map((param, i) => (
                <p key={i}>
                    {param.name}: {param.value}
                    {param.symbol}
                    {compareNextParams
                        ? `+(${param.nextValue}${param.symbol})`
                        : ''}
                </p>
            ))}
        </div>
    )
}
