import _ from 'lodash'
import { useMemo } from 'react'
import { Player } from '../Types/Player'
import { useTypedSelector } from './useTypedSelector'

export function usePlayer(player: Player) {
    const kingLevelsConfig = useTypedSelector((s) => s.kingLevelsConfig)

    const levelsConf = useMemo(
        () => new Map(kingLevelsConfig),
        [kingLevelsConfig]
    )

    const currentExpFromConf = useMemo(
        () => levelsConf.get(player.kingLevel) || 0,
        [player, levelsConf]
    )

    const nextExpFromConfRaw = useMemo(() => {
        return levelsConf.get(player.kingLevel + 1)
    }, [player, levelsConf])

    const nextExpFromConf = useMemo(() => {
        const nextExp = nextExpFromConfRaw
        if (_.isUndefined(nextExp)) return null
        return nextExp - currentExpFromConf
    }, [nextExpFromConfRaw, currentExpFromConf])

    const currentExp = useMemo(() => {
        if (_.isNull(currentExpFromConf)) return +player.kingPower
        return +player.kingPower - currentExpFromConf
    }, [currentExpFromConf, player])

    const nextExp = useMemo(() => {
        if (_.isNull(nextExpFromConf)) return +player.kingPower
        return nextExpFromConf
    }, [nextExpFromConf, player])

    const hideExp = useMemo(
        () => _.isUndefined(nextExpFromConfRaw),
        [nextExpFromConfRaw]
    )

    return {
        nextExp,
        currentExp,
        hideExp,
    }
}
