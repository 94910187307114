import _ from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { gameAPI } from '../../Common/Api'
import { useTypedSelector } from '../../Hooks/useTypedSelector'
import { Hero } from '../../Types/Hero'
import { Item } from '../../Types/Item'
import { Inventory } from '../Inventory'
import classNames from 'classnames'
import { HeroEquipments } from './HeroEquipments/Equipments'
import { HeroMeta } from './HeroMeta/Meta'
import { EquipmentParams } from './HeroEquipments/EquipmentParams'
import { EquipmentSlot } from '../../Types/Equipment'
import { getMaxEquipmentSlot } from '../../Mappers/Equipment'
import { useHeroIcon } from '../../Hooks/useHero'

export const Heroes: FC<{
    heroes: Hero[]
}> = ({ heroes }) => {
    // Кладень всех предметов игрока
    const itemsStore = useTypedSelector((s) => s.items)

    // Выбранный герой
    const [heroId, setHeroId] = useState<string>(heroes[0]?.id || '')

    // Выбранный предмет в инвентаре
    const [selectedItem, setSelectedItem] = useState<Item>()

    // Тип предметов слева в инвентаре ( когда игрок нажал на слот у героя )
    const [typeEquipmentInventory, setTypeEquipmentInventory] =
        useState<string>('')

    // Слева экрана, список героев или инвентарь игрока
    const [activeSlot, setActiveSlot] = useState<EquipmentSlot | ''>('')

    const hero = useMemo(
        () => heroes.find((h) => h.id === heroId),
        [heroId, heroes]
    )

    const items = useMemo(
        () => itemsStore.filter((i) => i.equipment) || [],
        [itemsStore]
    )

    const appliedEquipments = useMemo(
        () =>
            hero?.equipment?.filter(
                (equipment) => equipment.heroId === hero.id
            ) || [],
        [hero]
    )

    const handleClickApplyEquipment = useCallback(
        async (item: Item) => {
            if (!hero || !activeSlot) return

            const equipmentBySlot = activeSlot.equipment
            const equipmentIsAlreadyUsing = appliedEquipments.find(
                (equipment) => equipment.itemId === item.id
            )
            const equipmentClass = item.equipment?.computed?.base?.class

            if (equipmentBySlot && !equipmentIsAlreadyUsing) {
                const appliedEquipmentsByClass = appliedEquipments.filter(
                    (equipment) =>
                        equipment.computed?.base?.class === equipmentClass
                )

                if (
                    appliedEquipmentsByClass.length >=
                    getMaxEquipmentSlot(equipmentClass)
                ) {
                    await gameAPI.hero.applyEquipment({
                        item_id: equipmentBySlot.itemId,
                        hero_id: hero.id,
                    })
                }
            }

            await gameAPI.hero.applyEquipment({
                item_id: item.id,
                hero_id: hero.id,
            })

            setActiveSlot({
                heroPart: activeSlot.heroPart,
                equipment: equipmentIsAlreadyUsing ? undefined : item.equipment,
            })
        },
        [hero, activeSlot, appliedEquipments]
    )

    const handleClickActivity = useCallback(
        async (item: Item) => {
            if (!hero) return
            if (!selectedItem) return setSelectedItem(item)

            item.id === selectedItem.id
                ? setSelectedItem(undefined)
                : setSelectedItem(item)
        },
        [setSelectedItem, selectedItem, hero]
    )

    const handleСlickHeroEquipment = useCallback(
        (slot: EquipmentSlot) => {
            setActiveSlot((old) =>
                old && old.heroPart === slot.heroPart ? '' : slot
            )
            setTypeEquipmentInventory(slot.heroPart)
            setSelectedItem(slot.equipment?.item)
        },
        [setActiveSlot]
    )

    return (
        <div className="heroes">
            <div className="heroes__side">
                {activeSlot ? (
                    <Inventory
                        hero={hero}
                        items={items}
                        typeEquipmentInventory={typeEquipmentInventory}
                        onClickActivity={handleClickActivity}
                        selectedItem={selectedItem}
                        appliedEquipments={appliedEquipments}
                        type="equipment"
                    ></Inventory>
                ) : (
                    <HeroesList
                        heroes={heroes}
                        onClick={setHeroId}
                        selectedId={[heroId]}
                    ></HeroesList>
                )}
            </div>
            {!!hero && (
                <div className="heroes__side">
                    <div className="heroes-hero">
                        {selectedItem && selectedItem.equipment && (
                            <EquipmentParams
                                selectedItem={selectedItem}
                                handleClick={handleClickApplyEquipment}
                                appliedEquipments={appliedEquipments}
                            />
                        )}
                        <HeroEquipments
                            appliedEquipments={appliedEquipments}
                            handleClick={handleСlickHeroEquipment}
                            activeSlot={activeSlot}
                        />
                        <HeroMeta hero={hero} selectedItem={selectedItem} />
                    </div>
                </div>
            )}
        </div>
    )
}

export const HeroesList: FC<{
    heroes: Hero[]
    onClick?: (heroId: string) => void
    selectedId?: string[]
    cannotSelectWithNullHp?: boolean
    cannotSelectWithMissionId?: boolean
}> = ({
    heroes,
    onClick,
    selectedId,
    cannotSelectWithNullHp,
    cannotSelectWithMissionId,
}) => {
    const disabled = useCallback(
        (hero: Hero) => {
            if (cannotSelectWithMissionId && hero.questId) return true
            if (cannotSelectWithNullHp && hero.computed.current_hp <= 0)
                return true
            return false
        },
        [cannotSelectWithNullHp, cannotSelectWithMissionId]
    )

    return (
        <div className="heroes-list">
            {heroes.map((hero, i) => (
                <HeroListItem
                    key={i}
                    hero={hero}
                    onClick={onClick}
                    isDisabled={disabled}
                    selectedId={selectedId}
                ></HeroListItem>
            ))}
        </div>
    )
}

const HeroListItem: FC<{
    hero: Hero
    onClick?: (heroId: string) => void
    selectedId?: string[]
    isDisabled: (hero: Hero) => boolean
}> = ({ hero, onClick, selectedId, isDisabled }) => {
    const heroIcon = useHeroIcon(hero.class)

    const classNameHero = useCallback(
        (hero: Hero) => [
            'heroes-list__item',
            `heroes-list__item-${hero.rarity.toLowerCase()}`,
            {
                'heroes-list__item-active': _.includes(selectedId, hero.id),
                'heroes-list__item-disabled': isDisabled(hero),
            },
        ],
        [selectedId, isDisabled]
    )
    return (
        <div
            className={classNames(classNameHero(hero))}
            key={hero.id}
            onClick={() => {
                if (onClick && !isDisabled(hero)) onClick(hero.id)
            }}
        >
            <img className="heroes-list__icon" src={heroIcon} alt="" />
            <div className="heroes-list__main">
                <div className="heroes-list__info">{hero.name}</div>
                <div className="heroes-list__info">{hero.rarity}</div>
            </div>
            <div className="heroes-list__lvl">
                <div className="heroes-list__lvl-number">
                    {hero.computed.lvl}
                </div>
                <div className="heroes-list__lvl-word">LEVEL</div>
            </div>
        </div>
    )
}
