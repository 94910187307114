export type State<T> = [T, SetStateAction<T>]

export type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>

export enum Rarity {
    COMMON = 'Common',
    RARE = 'Rare',
    EPIC = 'Epic',
    MYTHICAL = 'Mythical',
    LEGENDARY = 'Legendary',
}

export type EntityState = Record<string, string | number>[]
