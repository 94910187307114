import { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { authAPI } from '../Common/Api'
import { actions } from '../Store/main'
import { Player } from '../Types/Player'
import { User } from '../Types/User'
import { ScreenCard } from './UI/Card'
import { Form, FormElement } from './UI/Form'
import { Input } from './UI/Input'
import { jwt } from '../Common/Jwt'
import { Button, ConnectWalletButton } from './UI/Button'
import { nErr, nSuc } from '../Common/notifications'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { routes } from '../Router/RoutesName'

export const Settings: FC<{ user: User; player: Player }> = ({ user }) => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmation, setConfirmation] = useState('')

    const [showPassChanger, setShowPassChanger] = useState(false)

    const history = useHistory()

    const dispatch = useDispatch()

    const changePass = useCallback(() => {
        if (!oldPass) return nErr('Old password is empty')
        if (!newPass) return nErr('New password is empty')
        if (!confirmation) return nErr('Confirmation is empty')
        if (confirmation !== newPass)
            return nErr(t('The new password does not match the confirmation'))

        authAPI
            .changePassword({ old_pass: oldPass, new_pass: newPass })
            .then(() => nSuc(t('Changed!')))
    }, [oldPass, newPass, confirmation])

    const logout = useCallback(() => {
        dispatch(actions.auth(null))
        jwt.removeToken()
        history.push(routes.auth.login)
    }, [dispatch, history])

    const openPrivacy = useCallback(() => {
        window.open(
            'https://docs.google.com/document/d/1oq7fSljnEET1VJUDmY3ZWtAobfm9pf2g/edit',
            '__blank'
        )
    }, [])
    const openHelp = useCallback(() => {
        window.open('https://t.me/imptoken', '__blank')
    }, [])

    return (
        <>
            <div className="settings">
                <div className="settings__row">
                    <ConnectWalletButton />
                    <Button onClick={() => setShowPassChanger(true)}>
                        Change password
                    </Button>
                    <Button onClick={logout}>Log Out</Button>
                </div>
                <div className="settings__row">
                    <Button onClick={openPrivacy}>Privacy</Button>
                    <Button onClick={openHelp}>Support</Button>
                </div>
            </div>
            <ScreenCard
                open={showPassChanger}
                onClose={() => setShowPassChanger(false)}
                title="Change password"
            >
                <Form>
                    <FormElement>
                        <Input
                            state={[oldPass, setOldPass]}
                            placeholder="Old Password"
                            type={'password'}
                        ></Input>
                    </FormElement>
                    <FormElement>
                        <Input
                            state={[newPass, setNewPass]}
                            placeholder="New Password"
                            type={'password'}
                        ></Input>
                    </FormElement>
                    <FormElement>
                        <Input
                            state={[confirmation, setConfirmation]}
                            placeholder="Confirmation"
                            type={'password'}
                        ></Input>
                    </FormElement>
                    <FormElement>
                        <Button onClick={changePass}>Change</Button>
                    </FormElement>
                </Form>
            </ScreenCard>
        </>
    )
}
