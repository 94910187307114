import { Item } from './Item'
import { Rarity } from './Main'
import { Hero } from './Hero'

export interface HeroEgg {
    readonly id: string
    summon_stone_durability: number
    summon_stone_power: number
    n_power: number
    saving_spell: number
    itemId: string
    rarity: Rarity
    item?: Item
}

export enum EggStatus {
    'Hero' = 'Hero',
    'HeroEgg' = 'HeroEgg',
}

export type HeroEggOpenResult = {
    status: EggStatus
    hero?: Hero
    egg?: Item
}
