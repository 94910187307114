import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { RarityColorConfig } from '../Config/Main'
import { useItem } from '../Hooks/useItem'
import { Item } from '../Types/Item'
import { Rarity } from '../Types/Main'
import { Tooltip } from './UI/Tooltip'

export const ItemTooltip: FC<{
    id: string
    prefix: string
    name?: string
    rarityColor?: Rarity
    rarityValue?: Rarity
    description?: string
    className?: string
    tier?: number | Rarity
}> = ({
    id,
    name,
    rarityColor,
    description,
    children,
    className,
    tier,
    prefix,
    rarityValue,
}) => {
    const dataFor = `item-tooltip-${prefix}-${id}`

    const color = useMemo(() => {
        if (!rarityColor) return
        return RarityColorConfig.get(rarityColor)
    }, [rarityColor])

    return (
        <>
            <div className={className || ''} data-tip data-for={dataFor}>
                {children}
            </div>
            <Tooltip id={dataFor} className="item-tooltip">
                <div className="item-tooltip__inner">
                    {!!name && (
                        <div
                            className={classNames('item-tooltip__text-name')}
                            style={{ color }}
                        >
                            {name} {!!tier && `T${tier}`}
                        </div>
                    )}
                    {!!rarityValue && (
                        <div
                            className={classNames('item-tooltip__text-rarity')}
                            style={{ color }}
                        >
                            {rarityValue}
                        </div>
                    )}
                    {!!description && (
                        <div className="item-tooltip__text item-tooltip__text-description">
                            {description}
                        </div>
                    )}
                </div>
            </Tooltip>
        </>
    )
}

export const ItemComponent: FC<{
    item: Item
    onClickActivity?: (item: Item) => void
    isActive?: boolean
    isApplied?: boolean
    className?: string
    disabled?: boolean //нужно переосмыслить
}> = ({ item, onClickActivity, isActive, isApplied, className, disabled }) => {
    const { img, info } = useItem(item)
    // const auth = useTypedSelector((s) => s.auth)

    // const rightClick = useCallback(
    //     async (e: MouseEvent<HTMLButtonElement>) => {
    //         e.preventDefault()
    //         const equipmentId = item.equipment?.id
    //         if (
    //             !!equipmentId &&
    //             !!auth?.eth_address &&
    //             !item.equipment?.tokenId
    //         ) {
    //             const w3i = await (await web3Instance)()
    //             await w3i?.mintEquipment(equipmentId)
    //         }
    //     },
    //     [item, auth]
    // )

    return (
        <ItemTooltip
            id={item.id}
            prefix="inventory"
            name={info.name}
            rarityColor={info.rarityAsColor}
            rarityValue={info.rarityAsValue}
            tier={info.tier}
            description={info.description}
        >
            <button
                className={classNames(
                    'item',
                    [
                        !!info.itemClass &&
                            `item-${info.itemClass}__${
                                info.rarityAsColor?.toLowerCase() || 'common'
                            }`,
                    ],
                    className
                )}
                onClick={() => {
                    onClickActivity && onClickActivity(item)
                }}
                // onContextMenu={rightClick}
                disabled={!onClickActivity || disabled}
            >
                <div
                    className={classNames([
                        'item__inner',
                        {
                            'item__inner-active': isActive,
                            'item__inner-applied': isApplied,
                        },
                    ])}
                >
                    <img src={img} alt="" className="item__icon" />
                    {info.count > 0 && (
                        <div className="item__count">{info.count}</div>
                    )}
                </div>
            </button>
        </ItemTooltip>
    )
}
