import { useEffect } from 'react'

export function useEvent<K extends keyof WindowEventMap>(
    event: K,
    handler: (ev: WindowEventMap[K]) => void,
    passive?: boolean
) {
    useEffect(() => {
        window.addEventListener(event, handler, {
            passive,
        })

        return function cleanup() {
            window.removeEventListener(event, handler)
        }
    }, [event, handler, passive])
}
