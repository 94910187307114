import { useMemo } from 'react'
import { images } from '../Assets'
import { QuestField } from '../Components/Core/Fields/Quest/QuestField'
import { CastleField } from '../Components/Core/Fields/Castle/Castle'
import { TextureField } from '../Components/Core/Fields/Texture'
import { Field, FieldObject } from '../Types/Field'

interface FieldProps {
    field: Field
}

const fields = new Map<FieldObject, (props: FieldProps) => JSX.Element>([
    [FieldObject.CASTLE_1, () => <CastleField texture={images.castle_17} />],
    [FieldObject.CASTLE_2, () => <CastleField texture={images.castle_18} />],
    [FieldObject.CASTLE_3, () => <CastleField texture={images.castle_19} />],
    [
        FieldObject.MAGNUS_CASTLE,
        () => <CastleField texture={images.castle_20} />,
    ],
    [
        FieldObject.SAARMAR_CASTLE,
        () => <CastleField texture={images.castle_21} />,
    ],
    [
        FieldObject.ERENTAS_CASTLE,
        () => <CastleField texture={images.castle_22} />,
    ],
    [FieldObject.CLAIM, () => <TextureField texture={images.object_claim} />],
    [
        FieldObject.CRYPT,
        ({ field }) => (
            <QuestField field={field} texture={images.object_crypt} />
        ),
    ],
    [
        FieldObject.RUINS,
        ({ field }) => (
            <QuestField field={field} texture={images.object_ruins} />
        ),
    ],
    [
        FieldObject.ABANDONED_TOWER,
        ({ field }) => (
            <QuestField field={field} texture={images.object_abandoned_tower} />
        ),
    ],
    [
        FieldObject.BANDIT_CAMP,
        ({ field }) => (
            <QuestField field={field} texture={images.object_bandit_camp} />
        ),
    ],
    [
        FieldObject.MOUNTAIN_1,
        () => <TextureField texture={images.mountain_1} />,
    ],
    [
        FieldObject.MOUNTAIN_2,
        () => <TextureField texture={images.mountain_2} />,
    ],
    [
        FieldObject.MOUNTAIN_3,
        () => <TextureField texture={images.mountain_3} />,
    ],
    [
        FieldObject.MOUNTAIN_4,
        () => <TextureField texture={images.mountain_4} />,
    ],
    [
        FieldObject.MOUNTAIN_5,
        () => <TextureField texture={images.mountain_5} />,
    ],
    [
        FieldObject.MOUNTAIN_6,
        () => <TextureField texture={images.mountain_6} />,
    ],
    [
        FieldObject.MOUNTAIN_7,
        () => <TextureField texture={images.mountain_7} />,
    ],
    [
        FieldObject.MOUNTAIN_8,
        () => <TextureField texture={images.mountain_8} />,
    ],
    [
        FieldObject.MOUNTAIN_9,
        () => <TextureField texture={images.mountain_9} />,
    ],
    [
        FieldObject.MOUNTAIN_10,
        () => <TextureField texture={images.mountain_10} />,
    ],
    [
        FieldObject.MOUNTAIN_11,
        () => <TextureField texture={images.mountain_11} />,
    ],
    [
        FieldObject.MOUNTAIN_12,
        () => <TextureField texture={images.mountain_12} />,
    ],
    [FieldObject.OAK_1, () => <TextureField texture={images.oak_1} />],
    [FieldObject.OAK_2, () => <TextureField texture={images.oak_2} />],
    [FieldObject.OAK_3, () => <TextureField texture={images.oak_3} />],
    [FieldObject.OAK_4, () => <TextureField texture={images.oak_4} />],
    [FieldObject.OAK_5, () => <TextureField texture={images.oak_5} />],
    [FieldObject.OAK_6, () => <TextureField texture={images.oak_6} />],
    [FieldObject.OAK_7, () => <TextureField texture={images.oak_7} />],
    [FieldObject.OAK_8, () => <TextureField texture={images.oak_8} />],
    [FieldObject.OAK_9, () => <TextureField texture={images.oak_9} />],
    [FieldObject.OAK_10, () => <TextureField texture={images.oak_10} />],
    [FieldObject.OAK_11, () => <TextureField texture={images.oak_11} />],
    [FieldObject.OAK_12, () => <TextureField texture={images.oak_12} />],
    [FieldObject.OAK_13, () => <TextureField texture={images.oak_13} />],
    [
        FieldObject.PINE_TREE_1,
        () => <TextureField texture={images.pine_tree_1} />,
    ],
    [
        FieldObject.PINE_TREE_2,
        () => <TextureField texture={images.pine_tree_2} />,
    ],
    [
        FieldObject.PINE_TREE_3,
        () => <TextureField texture={images.pine_tree_3} />,
    ],
    [
        FieldObject.PINE_TREE_4,
        () => <TextureField texture={images.pine_tree_4} />,
    ],
    [
        FieldObject.PINE_TREE_5,
        () => <TextureField texture={images.pine_tree_5} />,
    ],
    [
        FieldObject.PINE_TREE_6,
        () => <TextureField texture={images.pine_tree_6} />,
    ],
    [
        FieldObject.PINE_TREE_7,
        () => <TextureField texture={images.pine_tree_7} />,
    ],
    [
        FieldObject.PINE_TREE_8,
        () => <TextureField texture={images.pine_tree_8} />,
    ],
    [
        FieldObject.PINE_TREE_9,
        () => <TextureField texture={images.pine_tree_9} />,
    ],
    [
        FieldObject.PINE_TREE_10,
        () => <TextureField texture={images.pine_tree_10} />,
    ],
    [
        FieldObject.PINE_TREE_11,
        () => <TextureField texture={images.pine_tree_11} />,
    ],
    [
        FieldObject.PINE_TREE_12,
        () => <TextureField texture={images.pine_tree_12} />,
    ],
])

export function useFields(field: Field) {
    return useMemo(() => fields.get(field.object), [field])
}
