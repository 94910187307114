import { RefObject, useEffect } from 'react'
import { DragEventer } from '../Core/DragEventer'

export function useHorizontalScroll(ref: RefObject<HTMLDivElement>) {
    useEffect(() => {
        const { current } = ref
        if (!current) return

        const move = (delta: { x: number; y: number }) => {
            current.scrollLeft = current.scrollLeft - delta.x
        }

        const scroll = (e: WheelEvent) => {
            if (e.deltaY !== 0) {
                e.preventDefault()
                current.scrollTo({
                    left: current.scrollLeft + e.deltaY,
                    // behavior: 'smooth'
                })
            }
        }

        const drag = new DragEventer(current, { onDelta: move })
        current.addEventListener('wheel', scroll)
        return () => {
            current.removeEventListener('wheel', scroll)
            drag.destroy()
        }
    }, [ref])
}
