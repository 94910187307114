import { FC, useCallback, useMemo, useState } from 'react'
import { gameAPI } from '../../../../Common/Api'
import { useQuest } from '../../../../Hooks/useQuest'
import { useTypedSelector } from '../../../../Hooks/useTypedSelector'
import { Field } from '../../../../Types/Field'
import { QuestStatus } from '../../../../Types/Quest'
import { Popup, PopupInner } from '../../../Popup'
import { TextureField } from '../Texture'
import { QuestFieldQuest } from './QuestFieldQuest'
import { QuestFieldDetachment } from './QuestFieldDetachment'
import { QuestFieldInfo } from './QuestFieldInfo'
import { images } from '../../../../Assets'
import { Button } from '../../../UI/Button'

export const QuestField: FC<{
    field: Field
    texture: string
}> = ({ texture, field }) => {
    const [showPopup, setShowPopup] = useState(false)
    const heroesId = useState<string[]>([])
    const [ids] = heroesId
    const questStore = useTypedSelector((s) => s.quest)

    const send = useCallback(async () => {
        await gameAPI.quests.start(field.id, ids)
        setShowPopup(false)
    }, [ids, field.id])

    const questMap = useMemo(
        () => new Map(questStore.map((q) => [q.fieldId, q])),
        [questStore]
    )
    const quest = useMemo(() => questMap?.get(field.id), [field, questMap])

    const questMeta = useQuest(quest)

    return (
        <>
            {!!quest &&
            (quest.status === QuestStatus.STARTED ||
                quest.status === QuestStatus.PROCESSING) ? (
                <div className="quest-field">
                    {!!questMeta.differenceTime && (
                        <div className="quest-field__info">
                            {`Will end: ${questMeta.differenceTime}`}
                        </div>
                    )}
                    <TextureField texture={texture}></TextureField>
                </div>
            ) : (
                <>
                    <TextureField
                        texture={texture}
                        onClick={() => setShowPopup(true)}
                    ></TextureField>

                    <Popup show={[showPopup, setShowPopup]}>
                        <PopupInner
                            className="tabs-quest"
                            pages={[
                                {
                                    component: (
                                        <QuestFieldQuest
                                            field={field}
                                        ></QuestFieldQuest>
                                    ),
                                    icon: (
                                        <img
                                            src={images.ui_popup_icon_quest}
                                            alt=""
                                        />
                                    ),
                                    name: 'Quest',
                                },
                                {
                                    component: (
                                        <QuestFieldDetachment
                                            field={field}
                                            selectedHeroesIdState={heroesId}
                                        ></QuestFieldDetachment>
                                    ),
                                    icon: (
                                        <img
                                            src={
                                                images.ui_popup_icon_detachment
                                            }
                                            alt=""
                                        />
                                    ),
                                    name: 'Detachment',
                                },
                                {
                                    component: (
                                        <QuestFieldInfo
                                            field={field}
                                        ></QuestFieldInfo>
                                    ),
                                    icon: (
                                        <img
                                            src={images.ui_popup_icon_info}
                                            alt=""
                                        />
                                    ),
                                    name: 'Info',
                                },
                            ]}
                            buttons={[
                                <Button
                                    disabled={ids.length < 1}
                                    className="popup_button"
                                    onClick={send}
                                >
                                    Send
                                </Button>,
                            ]}
                        ></PopupInner>
                    </Popup>
                </>
            )}
        </>
    )
}
