import { useMemo } from 'react'
import { images } from '../Assets'
import { Hero, HeroClass, HeroComputed } from '../Types/Hero'
import { heroDescriptionMap, stateHeroMap } from '../Config/Hero'
import { EquipmentState } from '../Types/Inventory'
import { Rarity } from '../Types/Main'
import _ from 'lodash'

const heroesImages = new Map([
    [
        HeroClass.BANDIT,
        new Map([
            [Rarity.COMMON, images.hero_bandit_common],
            [Rarity.RARE, images.hero_bandit_rare],
            [Rarity.EPIC, images.hero_bandit_rare],
            [Rarity.MYTHICAL, images.hero_bandit_rare],
            [Rarity.LEGENDARY, images.hero_bandit_rare],
        ]),
    ],
    [
        HeroClass.MAGE,
        new Map([
            [Rarity.COMMON, images.hero_mage_common],
            [Rarity.RARE, images.hero_mage_rare],
            [Rarity.EPIC, images.hero_mage_rare],
            [Rarity.MYTHICAL, images.hero_mage_rare],
            [Rarity.LEGENDARY, images.hero_mage_rare],
        ]),
    ],
    [
        HeroClass.PALADIN,
        new Map([
            [Rarity.COMMON, images.hero_paladin_common],
            [Rarity.RARE, images.hero_paladin_rare],
            [Rarity.EPIC, images.hero_paladin_epic],
            [Rarity.MYTHICAL, images.hero_paladin_common],
            [Rarity.LEGENDARY, images.hero_paladin_legendary],
        ]),
    ],
    [
        HeroClass.WARRIOR,
        new Map([
            [Rarity.COMMON, images.hero_warrior_common],
            [Rarity.RARE, images.hero_warrior_rare],
            [Rarity.EPIC, images.hero_warrior_epic],
            [Rarity.MYTHICAL, images.hero_warrior_mythic],
            [Rarity.LEGENDARY, images.hero_warrior_rare],
        ]),
    ],
])

const heroesIcons = new Map([
    [HeroClass.WARRIOR, images.hero_warrior_icon],
    [HeroClass.BANDIT, images.hero_bandit_icon],
    [HeroClass.PALADIN, images.hero_paladin_icon],
    [HeroClass.MAGE, images.hero_mage_icon],
])

const heroesAvatars = new Map([
    [HeroClass.WARRIOR, images.hero_warrior_mini],
    [HeroClass.BANDIT, images.hero_bandit_mini],
    [HeroClass.PALADIN, images.hero_paladin_mini],
    [HeroClass.MAGE, images.hero_mage_mini],
])

export const useHeroAvatar = (heroClass?: HeroClass) =>
    useMemo(() => heroClass && heroesAvatars.get(heroClass), [heroClass])

export const useHeroIcon = (heroClass: HeroClass) =>
    useMemo(() => heroesIcons.get(heroClass), [heroClass])

export function useHero(hero?: Hero) {
    const icon = useMemo(() => {
        const cl = hero?.class
        const rarity = hero?.rarity
        if (_.isUndefined(cl) || _.isUndefined(rarity)) return null
        return heroesImages.get(cl)?.get(rarity) || null
    }, [hero])

    return {
        icon,
    }
}

export function useHeroDescription(heroClass: HeroClass) {
    const classDescription = useMemo(() => {
        return heroDescriptionMap.get(heroClass)
    }, [heroClass])

    return { ...classDescription }
}

const notStateParams = new Set<keyof HeroComputed>([
    'lvl',
    'next_exp',
    'max_hp',
])

export function useHeroEquipment(
    computed: HeroComputed,
    dropStats: Set<keyof HeroComputed | string> = notStateParams
) {
    return useMemo(() => {
        return Object.entries(computed).reduce<EquipmentState[]>(
            (acc, [_key, value]) => {
                if (dropStats.has(_key)) return acc
                const values = stateHeroMap.get(_key)

                if (!values) return acc
                const { label, icon, name } = values

                return [
                    ...acc,
                    {
                        value,
                        label,
                        icon,
                        name,
                    },
                ]
            },
            []
        )
    }, [computed, dropStats])
}
