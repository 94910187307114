import { FC, useCallback } from 'react'
import { Player } from '../Types/Player'
import { User } from '../Types/User'
import { PopupInner } from './Popup'
import { Settings } from './Settings'
import { images } from '../Assets'
import { ProgressBarGexed } from './UI/ProgressBar'
import { useTypedSelector } from '../Hooks/useTypedSelector'
import { Referrals } from './Referrals'
import { KingLevel } from './KingLevel'
import { copy } from '../Common/utils'
import { usePlayer } from '../Hooks/usePlayer'

export const Profile: FC<{ user: User; player: Player }> = ({
    user,
    player,
}) => {
    const referrals = useTypedSelector((s) => s.referrals)
    const { currentExp, nextExp, hideExp } = usePlayer(player)

    const copyId = useCallback(() => copy(user.id), [user])

    return (
        <div className="profile">
            <div className="profile-main">
                <div className="profile-main__avatar">
                    <div className="profile-avatar">
                        <img src={images.icon_profile_avatar} alt="" />
                    </div>
                </div>
                <div className="profile-main__info">
                    <div className="profile-main__nickname">{user.name}</div>
                    <div className="profile-main__id">
                        ID <span>{user.id}</span>
                        <i
                            className="profile-main__copy fa-regular fa-copy"
                            onClick={copyId}
                        ></i>
                    </div>
                    <div className="profile-main__power">
                        <ProgressBarGexed
                            hideValue={hideExp}
                            current={currentExp}
                            max={nextExp}
                        ></ProgressBarGexed>
                        <div className="profile-main__kinglevel">
                            {player.kingLevel}
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile__h">Invited friends</div>
            <Referrals users={referrals}></Referrals>
        </div>
    )
}

export const ProfileWrapper: FC<{ user: User; player: Player }> = ({
    user,
    player,
}) => {
    return (
        <PopupInner
            pages={[
                {
                    component: <Profile user={user} player={player}></Profile>,
                    name: 'Profile',
                    icon: <img src={images.icon_profile} alt="" />,
                },
                {
                    component: <KingLevel player={player}></KingLevel>,
                    name: 'KingLevel',
                    icon: <img src={images.icon_vip} alt="" />,
                },
                {
                    component: (
                        <Settings user={user} player={player}></Settings>
                    ),
                    name: 'Settings',
                    icon: <img src={images.icon_settings} alt="" />,
                },
            ]}
        ></PopupInner>
    )
}
