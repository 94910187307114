import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { BN } from 'bn.js'
import { SetStateAction } from '../../../../../Types/Main'
import { gameAPI } from '../../../../../Common/Api'
import { useTypedSelector } from '../../../../../Hooks/useTypedSelector'
import { Lootbox, OpenResult } from '../../../../../Types/Lootbox'
import { ParamList } from './helpers/ParamList'
import { LootboxImage, LootboxPrice } from './helpers'
import { Button } from '../../../../UI/Button'
import { LootboxOpenResult } from './LootboxOpenResult'
import { useLootbox } from '../../../../../Hooks/useLootbox'

// !! Seed Params
const priceOpenLootbox = 10000

export const ItemLootbox: FC<{
    selectedLootbox: Lootbox
    setSelectedLootbox: SetStateAction<Lootbox | undefined>
}> = ({ selectedLootbox, setSelectedLootbox }) => {
    const [lootboxOpenResult, setLootboxOpenResult] = useState<
        OpenResult | undefined
    >()
    const { img } = useLootbox(selectedLootbox)
    const player = useTypedSelector((s) => s.player)

    const closeResult = useCallback(
        (lootbox: Lootbox | undefined) => {
            setSelectedLootbox(lootbox)
            setLootboxOpenResult(undefined)
        },
        [setSelectedLootbox, setLootboxOpenResult]
    )

    async function handleOpenLootbox() {
        const { data } = await gameAPI.lootbox.open({
            item_id: selectedLootbox.itemId,
        })
        setLootboxOpenResult(data)
    }

    const isCanOpenLootbox = useMemo(() => {
        // TODO !! Handle player is undefined
        if (!player) return false

        const gold = new BN(player.gold_value)
        const price = new BN(priceOpenLootbox)

        return gold.gte(price)
    }, [player])
    return (
        <div className="lootbox">
            {!selectedLootbox ? (
                <NoLootboxFound setSelectedLootbox={setSelectedLootbox} />
            ) : lootboxOpenResult ? (
                <LootboxOpenResult
                    previousBox={selectedLootbox}
                    setLootboxOpenResult={closeResult}
                    result={lootboxOpenResult}
                />
            ) : (
                <>
                    <LootboxImage img={img} />
                    <div className="lootbox-info">
                        <ParamList lootbox={selectedLootbox} />
                    </div>
                    <div className="lootbox__buttons">
                        <Button onClick={() => setSelectedLootbox(undefined)}>
                            Назад
                        </Button>
                        <Button
                            disabled={isCanOpenLootbox}
                            onClick={handleOpenLootbox}
                        >
                            <LootboxPrice price={priceOpenLootbox} />
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

const NoLootboxFound: FC<{
    setSelectedLootbox: SetStateAction<Lootbox | undefined>
}> = ({ setSelectedLootbox }) => {
    useEffect(() => setSelectedLootbox(undefined), [setSelectedLootbox])
    return <></>
}
