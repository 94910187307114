import { FC } from 'react'
import { Lootbox } from '../../../../../Types/Lootbox'
import { ParamList } from './helpers/ParamList'
import { LootboxImage } from './helpers'
import { Button } from '../../../../UI/Button'

export const LootboxNotOpen: FC<{
    resultOpen: Lootbox
    closeLootboxResult: () => void
    img?: string
}> = ({ resultOpen, img, closeLootboxResult }) => {
    return (
        <>
            <LootboxImage img={img} />

            <div className="lootbox-info">
                <div className="lootbox-info__header">
                    <p>Сундук не открылся</p>
                    <p>Характеристики повысились</p>
                </div>
                <ParamList lootbox={resultOpen} compareNextParams={true} />
            </div>
            <div className="lootbox__buttons">
                <Button onClick={closeLootboxResult}>OK</Button>
            </div>
        </>
    )
}
