import classNames from 'classnames'
import _ from 'lodash'
import { FC, useMemo, useRef, useState } from 'react'
import { useRouletteDrop } from '../Hooks/useRouletteDrop'
import { useTypedSelector } from '../Hooks/useTypedSelector'
import { Player } from '../Types/Player'
import { RouletteDrop } from '../Types/RouletteDrop'
import { ScreenCard } from './UI/Card'
import { Form, FormElement } from './UI/Form'
import { Input } from './UI/Input'
import { copy } from '../Common/utils'
import { Button } from './UI/Button'
import { ItemTooltip } from './Item'
import { useHorizontalScroll } from '../Hooks/useHorizontalScroll'

export const KingLevel: FC<{ player: Player }> = ({ player }) => {
    const season = useTypedSelector((s) => s.rouletteSeason)
    const rouletteDrops = useTypedSelector((s) => s.rouletteDrops)
    const [showRefCard, setShowRefCard] = useState(false)

    const rowRef = useRef<HTMLDivElement>(null)

    useHorizontalScroll(rowRef)

    const drops = useMemo(() => {
        const map = _.sortBy(rouletteDrops, (i) => i.kingLevel).reduce(
            (acc, drop) => {
                const oldArr = acc.get(drop.kingLevel)

                if (!oldArr) return acc.set(drop.kingLevel, [drop])

                return acc.set(drop.kingLevel, [...oldArr, drop])
            },
            new Map<number, RouletteDrop[]>()
        )
        return Array.from(map?.entries() || [])
    }, [rouletteDrops])

    const link = useMemo(() => {
        return `${window.location.origin}/invite?ref=${player.userId}`
    }, [player])

    if (!season) return null
    return (
        <div className="king-level">
            <div className="king-level__inner" ref={rowRef}>
                <div className="king-level__row">
                    {drops.map(([lvl, drop]) => (
                        <RouletteSeasonDrops
                            drops={drop}
                            lvl={lvl}
                            key={lvl}
                            active={player.kingLevel === lvl}
                        ></RouletteSeasonDrops>
                    ))}
                </div>
                {/* <div className="king-level-progress">
                <div className="king-level-progress__line"></div>
            </div> */}
            </div>
            <div className="king-level__btns">
                <Button onClick={() => setShowRefCard(true)}>
                    Referal Invite
                </Button>
            </div>
            <ScreenCard
                open={showRefCard}
                onClose={() => setShowRefCard(false)}
                title="Referal Invite"
            >
                <Form>
                    <FormElement>
                        <Input state={[link, () => {}]} disabled></Input>
                    </FormElement>
                    <FormElement>
                        <Button onClick={() => copy(link)}>Copy Link</Button>
                    </FormElement>
                </Form>
            </ScreenCard>
        </div>
    )
}

export const RouletteSeasonDrops: FC<{
    drops: RouletteDrop[]
    lvl: number
    active?: boolean
}> = ({ drops, lvl, active }) => {
    return (
        <div className={classNames('king-level-drop')}>
            <div
                className={classNames('king-level-drop__inner', {
                    'king-level-drop__inner-active': active,
                })}
            >
                {drops.map((drop) => (
                    <RouletteSeasonDrop
                        drop={drop}
                        key={drop.id}
                    ></RouletteSeasonDrop>
                ))}
            </div>
            <div className="king-level-drop__lvl">{lvl}</div>
        </div>
    )
}

export const RouletteSeasonDrop: FC<{
    drop: RouletteDrop
}> = ({ drop }) => {
    const meta = useRouletteDrop(drop)

    return (
        <ItemTooltip
            id={drop.id}
            prefix="king-level-drop"
            name={meta?.name}
            rarityColor={meta?.rarityAsColor}
            rarityValue={meta?.rarityAsValue}
            tier={meta?.tier}
            description={meta?.description}
        >
            <div
                key={drop.id}
                className={classNames(
                    'king-level-drop__item',
                    `king-level-drop-resource__${meta?.rarityAsColor?.toLowerCase()}`
                )}
            >
                <img src={meta?.img} alt="" />
                <div className="king-level-drop__count">
                    {drop.resourceCount || 0}
                </div>
            </div>
        </ItemTooltip>
    )
}
