import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { authAPI } from '../../Common/Api'
import { nErr } from '../../Common/notifications'
import { Button } from '../../Components/UI/Button'
import { ScreenCard } from '../../Components/UI/Card'
import { Form, FormElement } from '../../Components/UI/Form'
import { Input } from '../../Components/UI/Input'
import { useInit } from '../../Hooks/useInit'
import { useTypedLocationState } from '../../Hooks/useTypedLocationState'
import { routes } from '../../Router/RoutesName'

export const Register = () => {
    const locationState = useTypedLocationState()
    const { t } = useTranslation()
    const [codeSending, setCodeSending] = useState(false)

    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [pass, setPass] = useState('')
    const [pass_remember, setPass_remember] = useState('')
    const [ref_id, setRef_id] = useState(locationState.state?.ref || '')

    const [codeSent, setCodeSent] = useState(false)

    const initial = useInit()

    async function sendData(e?: FormEvent) {
        e?.preventDefault()
        if (!codeSent) {
            setCodeSending(true)
            try {
                await authAPI.genCode({ email, type: 'register' })
                setCodeSent(true)
            } catch (error) {
                console.error(error)
            }
            setCodeSending(false)

            return
        }
        if (pass !== pass_remember) return nErr(t('Password not confirmed!'))

        await authAPI.register({
            email,
            code,
            password: pass,
            ref_id: ref_id !== '' ? ref_id : undefined,
        })
        initial()
    }

    return (
        <ScreenCard
            title="Register"
            open={true}
            bg="art"
            notification={codeSent ? 'Code sent' : undefined}
            loading={codeSending}
            logo
        >
            <Form onSubmit={sendData}>
                <FormElement>
                    <Input
                        state={[email, setEmail]}
                        placeholder="E-mail"
                        disabled={codeSent}
                    ></Input>
                </FormElement>
                {codeSent && (
                    <>
                        <FormElement>
                            <Input
                                state={[code, setCode]}
                                placeholder="Email code"
                            ></Input>
                        </FormElement>
                        <FormElement>
                            <Input
                                state={[pass, setPass]}
                                placeholder="Password"
                                type="password"
                            ></Input>
                        </FormElement>
                        <FormElement>
                            <Input
                                state={[pass_remember, setPass_remember]}
                                placeholder="Password Confirmation"
                                type="password"
                            ></Input>
                        </FormElement>
                        <FormElement>
                            <Input
                                state={[ref_id, setRef_id]}
                                placeholder="Referral ID"
                                disabled={!!locationState.state?.ref}
                            ></Input>
                        </FormElement>
                    </>
                )}
                <FormElement>
                    <Link to={routes.auth.login}>Sign In</Link>
                </FormElement>
                <FormElement>
                    <Button
                        type="submit"
                        disabled={codeSending}
                        color="blue"
                        fill
                    >
                        {codeSent ? 'Register' : 'Send code'}
                    </Button>
                </FormElement>
            </Form>
        </ScreenCard>
    )
}
