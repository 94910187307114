import { FC } from 'react'
import { PopupInner } from '../../../../Popup'
import { images } from '../../../../../Assets'
import { HeroCall } from './helpers/HeroCall'
import { Incubator } from './helpers/Incubator'

export const AltarOfSummon: FC = () => {
    return (
        <>
            <PopupInner
                pages={[
                    {
                        component: <HeroCall />,
                        name: 'Hero Call',
                        icon: (
                            <img src={images.ui_popup_icon_hero_call} alt="" />
                        ),
                    },
                    {
                        component: <Incubator />,
                        name: 'Incubator',
                        icon: (
                            <img src={images.ui_popup_icon_incubator} alt="" />
                        ),
                    },
                ]}
            ></PopupInner>
        </>
    )
}
