import { FC } from 'react'
import { HeroEgg } from '../../../../../Types/HeroEgg'
import { EggImage, EggPower } from './helpers'
import { useEgg } from '../../../../../Hooks/useEgg'
import { Button } from '../../../../UI/Button'

export const EggPowerUp: FC<{
    egg: HeroEgg
    closeEggResult: () => void
}> = ({ egg, closeEggResult }) => {
    const { img } = useEgg(egg)
    return (
        <>
            <EggImage img={img}></EggImage>
            <div className="lootbox-info text-center">
                <div className="lootbox-info__header">
                    <p>The call failed!</p>
                </div>
                <p className="lootbox-info__text">Egg intensified</p>
                <EggPower
                    className="m-y-auto"
                    showPowerUp
                    eggPower={egg.n_power}
                />
            </div>
            <div className="lootbox__buttons center">
                <Button onClick={closeEggResult}>OK</Button>
            </div>
        </>
    )
}
