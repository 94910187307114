import { FC, useCallback } from 'react'
import {
    OpenResult,
    LootboxStatus,
    Lootbox,
} from '../../../../../Types/Lootbox'
import { LootboxRarityUp } from './LootboxRarityUp'
import { LootboxNotOpen } from './LootboxNotOpen'
import { LootboxOpen } from './LootboxOpen'
import { useLootbox } from '../../../../../Hooks/useLootbox'

export const LootboxOpenResult: FC<{
    previousBox: Lootbox
    result: OpenResult
    setLootboxOpenResult: (lootbox: Lootbox | undefined) => void
}> = ({ previousBox, result, setLootboxOpenResult }) => {
    const { img, name } = useLootbox(previousBox)

    const closeResultAndOpenList = useCallback(
        () => setLootboxOpenResult(undefined),
        [setLootboxOpenResult]
    )
    const closeResultAndSetItem = useCallback(
        () => setLootboxOpenResult(result.lootbox.lootbox),
        [setLootboxOpenResult, result]
    )
    return (
        <>
            {result.status === LootboxStatus.Opened ? (
                <LootboxOpen
                    resultOpen={result}
                    closeLootboxResult={closeResultAndOpenList}
                />
            ) : result.status === LootboxStatus.RarityUp ? (
                <LootboxRarityUp
                    resultOpen={result}
                    previousRarity={name}
                    closeLootboxResult={closeResultAndOpenList}
                />
            ) : (
                <LootboxNotOpen
                    resultOpen={previousBox}
                    img={img}
                    closeLootboxResult={closeResultAndSetItem}
                />
            )}
        </>
    )
}
