import { HeroEgg } from '../../../../../../Types/HeroEgg'

export { EggListItem } from './EggListItem'
export { EggPower } from './EggPower'
export { EggImage } from './EggImage'
export { EggSummonChances } from './EggSummonChances'

export function isEggRarityUp(egg: HeroEgg, prevEgg: HeroEgg): boolean {
    return egg.rarity !== prevEgg.rarity
}
