import { FC } from 'react'
import classNames from 'classnames'

export const TextureField: FC<{
    texture: string
    onClick?: () => void
}> = ({ texture, onClick }) => {
    return (
        <img
            src={texture}
            className={classNames([
                'tile__texture',
                { 'tile__texture-pointer': onClick },
            ])}
            data-for="field"
            alt=""
            onClick={onClick}
        />
    )
}
