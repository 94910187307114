import { images } from '../Assets'
import { ResourceType } from '../Types/Resource'

export const resourcesMap = new Map<ResourceType, string>([
    [ResourceType.TitaniumIngot, images.item_resource_titanium_ingot],
    [ResourceType.IronIngot, images.item_resource_iron_ingot],
    [ResourceType.SilverIngot, images.item_resource_silver_ingot],
    [ResourceType.SteelIngot, images.item_resource_steel_ingot],
    [ResourceType.MythrilIngot, images.item_resource_mythril_ingot],
    [ResourceType.StarMetalIngot, images.item_resource_starmetal_ingot],
    [ResourceType.OrichalconIngot, images.item_resource_orichalcon_ingot],
    [ResourceType.IronOre, images.item_resource_iron_ore],
    [ResourceType.SilverOre, images.item_resource_silver_ore],
    [ResourceType.TitaniumOre, images.item_resource_titanium_ore],
    [ResourceType.StarMetalOre, images.item_resource_starmetal_ore],
    [ResourceType.Coal, images.item_resource_coal_ore],
    [ResourceType.ArcaneDustT1, images.item_resource_arcane_dust_1],
    [ResourceType.ArcaneDustT2, images.item_resource_arcane_dust_2],
    [ResourceType.ArcaneDustT3, images.item_resource_arcane_dust_3],
    [ResourceType.CuredWood, images.item_resource_cured_wood],
    [ResourceType.SpiritWood, images.item_resource_spirit_wood],
    [ResourceType.BlessedWood, images.item_resource_blessed_wood],
    [ResourceType.HardenedWood, images.item_resource_hardened_wood],
    [ResourceType.EnchantedWood, images.item_resource_enchanted_wood],
    [ResourceType.Wood, images.item_resource_wood],
    [ResourceType.Leather, images.item_resource_leather],
    [ResourceType.CuredLeather, images.item_resource_cured_leather],
    [ResourceType.SpiritLeather, images.item_resource_spirit_leather],
    [ResourceType.BlessedLeather, images.item_resource_blessed_leather],
    [ResourceType.HardenedLeather, images.item_resource_hardened_leather],
    [ResourceType.EnchantedLeather, images.item_resource_enchanted_leather],
    [ResourceType.Parchment, images.item_resource_parchment],
    [ResourceType.ArcaneParchment, images.item_resource_arcane_parchment],
    [ResourceType.MysticParchment, images.item_resource_mystic_parchment],
    [
        ResourceType.InstrumentsBlacksmith,
        images.item_resource_instruments_blacksmith,
    ],
    [ResourceType.InstrumentsJeweler, images.item_resource_instruments_jeweler],
    [
        ResourceType.ExcellentInstrumentsBlacksmith,
        images.item_resource_excellent_instruments_blacksmith,
    ],
    [
        ResourceType.ExcellentInstrumentsJeweler,
        images.item_resource_excellent_instruments_jeweler,
    ],
    [ResourceType.ArmorPiece, images.item_resource_armor_piece],
    [ResourceType.WeaponPiece, images.item_resource_weapon_piece],
    [ResourceType.Whetstone, images.item_resource_whetstone],
    [ResourceType.ExcellentWhetstone, images.item_resource_excellent_whetstone],
    [ResourceType.Stardust, images.item_resource_star_dust],

    [ResourceType.StarShard, images.item_resource_star_shard],
    [
        ResourceType.ExcellentArmorPlate,
        images.item_resource_excellent_armor_plate,
    ],
    [ResourceType.ArmorPlate, images.item_resource_armor_plate],
    [ResourceType.ScorchingStone, images.item_resource_scorching_stone],
    [ResourceType.FlareCrystal, images.item_resource_flare_crystal],
    //
    [ResourceType.RECIPE_SWORD, images.item_resource_recipe],
    [ResourceType.Gold, images.item_resource_gold],
    [ResourceType.WildFireCom, images.item_resource_wild_fire_common],
    [ResourceType.WildFireRare, images.item_resource_wild_fire_rare],
    [ResourceType.WildFireEpic, images.item_resource_wild_fire_epic],
    [ResourceType.WildFireMythical, images.item_resource_wild_fire_mythical],
    [ResourceType.WildFireLegendary, images.item_resource_wild_fire_legendary],
])
