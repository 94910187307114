import { Hero, HeroClass } from '../../Types/Hero'
import { Item } from '../../Types/Item'
import { Quest } from '../../Types/Quest'
import { Player } from '../../Types/Player'
import { Region } from '../../Types/Region'
import { User } from '../../Types/User'
import { jwt } from '../Jwt'
import { appAxios } from './axios'
import { Mission } from '../../Types/Mission'
import { Fract } from '../../Types/Fract'
import { OpenResult } from '../../Types/Lootbox'
import { EggStatus, HeroEggOpenResult } from '../../Types/HeroEgg'
import { RouletteSeason } from '../../Types/RouletteSeason'
import { RouletteSponsor } from '../../Types/RouletteSponsor'
import { BattlePass } from '../../Types/BattlePass'
import { RouletteDrop } from '../../Types/RouletteDrop'

export const authAPI = {
    fetch() {
        return appAxios.get<User>('/app/auth')
    },
    genCode(data: { email: string; type: 'register' | 'recover' }) {
        return appAxios.post<string>('/app/auth/create-code', data)
    },
    register(data: {
        email: string
        code: string
        password: string
        ref_id?: string
    }) {
        return appAxios
            .post<{
                token: string
                user: User
            }>('/app/auth/register', data)
            .then(async (res) => {
                jwt.setToken(res.data.token, true)
                return res.data.user
            })
    },
    login(data: { email: string; password: string; remember?: boolean }) {
        return appAxios
            .post<{
                token: string
                user: User
            }>('/app/auth/login', data)
            .then(async (res) => {
                jwt.setToken(res.data.token, data.remember)
                return res.data.user
            })
    },
    changePassword(data: { old_pass: string; new_pass: string }) {
        return appAxios.post<string>('/app/auth/change-password', data)
    },
    recoverPassword(data: { email: string; code: number; password: string }) {
        return appAxios
            .post<{ token: string; user: User }>('/app/auth/recover', data)
            .then(async (res) => {
                jwt.setToken(res.data.token)
                return res.data.user
            })
    },
    metamask: {
        connect(data: { msg: string; sign: string; address: string }) {
            return appAxios.post('/app/blockchain/metamask/connect', data)
        },
        disconnect() {
            return appAxios.post('/app/blockchain/metamask/disconnect')
        },
        // getMsg(address: string) {
        //     return appAxios.get<{ exists: boolean; msg: string }>(
        //         `/app/blockchain/metamask/message/${address}`
        //     )
        // },
        // login(data: { msg: string; sign: string }) {
        //     return appAxios.post<{ token: string; user: User }>(
        //         `/app/blockchain/metamask/login`,
        //         data
        //     )
        // },
        // register(data: {
        //     msg: string
        //     sign: string
        //     address: string
        //     email: string
        //     ref_id?: string
        //     code: number
        // }) {
        //     return appAxios.post<{ token: string; user: User }>(
        //         `/app/blockchain/metamask/register`,
        //         data
        //     )
        // },
    },
}

export const gameAPI = {
    fetch() {
        return appAxios.get<{
            quests: Quest[]
            rouletteSponsor: RouletteSponsor | null
            rouletteSeason: RouletteSeason | null
            rouletteDrops: RouletteDrop[]
            missions: Mission[]
            heroes: Hero[]
            fracts: Fract[]
            battlePasses: BattlePass | null
            player: Player
            items: Item[]
            refs: User[]
            configs: {
                kingLevels: [number, number][]
                playerLevels: [number, string][]
            }
        }>('/game/')
    },
    player: {
        getMe() {
            return appAxios.get<Player>('/game/players/me')
        },
        finishLearning(data: { fract_id: string; hero_class: HeroClass }) {
            return appAxios.post<'OK'>('/game/players/finish-learning', data)
        },
    },
    map: {
        regions: {
            get() {
                return appAxios.get<Region[]>(`/game/regions/`)
            },
            id(id: string) {
                return appAxios.get<Region>(`/game/regions/${id}`)
            },
        },
    },
    lootbox: {
        open(data: { item_id: string }) {
            return appAxios.post<OpenResult>('/high/lootbox/open', data)
        },
    },
    fract: {
        list() {
            return appAxios.get<Fract[]>('/game/fracts/')
        },
    },
    heroEgg: {
        open(data: { item_id: string }): Promise<HeroEggOpenResult> {
            return appAxios
                .post<Item | Hero>('/high/heroegg/open', data)
                .then((res) => {
                    // TODO: change responce in the backend
                    if ('damage_type' in res.data) {
                        return {
                            status: EggStatus.Hero,
                            hero: res.data as Hero,
                        }
                    }
                    return { status: EggStatus.HeroEgg, egg: res.data as Item }
                })
        },
    },
    hero: {
        list() {
            return appAxios.get<Hero[]>('/game/heroes/')
        },
        applyEquipment(data: { item_id: string; hero_id: string }) {
            return appAxios.post<Hero>('/game/heroes/apply-equipment', data)
        },
    },
    items: {
        list() {
            return appAxios.get<Item[]>('/game/items/')
        },
    },
    quests: {
        list() {
            return appAxios.get<Quest[]>('/game/quests/')
        },
        start(field_id: string, heroes_id: string[]) {
            return appAxios.post<Quest>('/game/quests/start/', {
                field_id,
                heroes_id,
            })
        },
    },
    missions: {
        list() {
            return appAxios.get<Mission[]>('/game/missions/')
        },
        close(id: string) {
            return appAxios.post<Mission>('/game/missions/close', { id })
        },
    },
    roulette: {
        getSeason() {
            return appAxios.get<RouletteSeason>('/game/roulette/season')
        },
        getSponsor() {
            return appAxios.get<RouletteSponsor>('/game/roulette/sponsor')
        },
    },
}
