import { useCallback } from 'react'
import { State } from '../Types/Main'

export function useSwitch<T>(st: State<T[]>) {
    const [state, setState] = st

    return useCallback(
        (val: T) => {
            const stateIncludes = state.find((id) => val === id)
            stateIncludes
                ? setState((old) => old.filter((o) => o !== val))
                : setState((old) => [...old, val])
        },
        [state, setState]
    )
}
