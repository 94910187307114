import { Equipment } from './Equipment'
import { Rarity } from './Main'
import { Quest } from './Quest'
import { Player } from './Player'

export interface HeroComputed {
    playerExpWin: number
    heroExpWin: number
    lvl: number
    next_exp: string | undefined
    power: number
    vitality: number
    durability: number
    speed: number
    evasionChance: number
    luck: number
    current_hp: number
    max_hp: number
    damage: number
    physicalDefense: number
    magicalDefense: number
    criticalStrikeChance: number
    minDamage: number
    maxDamage: number
    critDamage: number
}
export interface Hero {
    readonly id: string
    name: string | null
    class: HeroClass
    rarity: Rarity
    experience: string
    type: HeroType
    damage_type: HeroDamageType

    base_current_hp: number
    base_max_hp: number

    base_vitality: number
    base_power: number
    base_durability: number
    base_agility: number
    base_luck: number

    computed: HeroComputed

    tokenId: string | null

    playerId: string | null
    player?: Player
    questId: string | null
    quests?: Quest

    equipment?: Equipment[]
}

export enum HeroClass {
    WARRIOR = 'Warrior',
    BANDIT = 'Bandit',
    PALADIN = 'Paladin',
    MAGE = 'Mage',
}

export enum HeroType {
    HERO = 'HERO',
    ENEMY = 'ENEMY',
}

export enum HeroDamageType {
    PHISICAL = 'PHISICAL',
    MAGICAL = 'MAGICAL',
}
