import { Field } from './Field'
import { Hero } from './Hero'
import { Player } from './Player'

export interface Quest {
    readonly id: string

    playerId: string | null
    clanId: string | null
    finishAt: string | Date
    status: QuestStatus

    player?: Player
    heros?: Hero[]
    fieldId: string
    field?: Field
}

export enum QuestStatus {
    STARTED = 'STARTED', //выполнение запущено
    PROCESSING = 'PROCESSING', //в процессе вычисления победителя
    WIN = 'WIN', // победа
    LOSE = 'LOSE', // проигрыш
}
