import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { io, Socket } from 'socket.io-client'
import { jwt } from '../Common/Jwt'
import { actions } from '../Store/main'

export function useSocket() {
    const [socket, setSocket] = useState<Socket>()
    useEffect(() => {
        const s = io('/client', {
            auth: { token: jwt.getToken() },
            protocols: 'wss',
            // transports: ['websocket']
        })
        setSocket(s)
        return () => {
            s.disconnect()
        }
    }, [])

    return socket
}

export function useWorkingSocket() {
    const socket = useSocket()
    const dispatch = useDispatch()

    useEffect(() => {
        socket?.on('update-user', (msg) => {
            const { key, data } = msg
            if (key === 'user') dispatch(actions.auth(data))
            if (key === 'roulette-sponsor') dispatch(actions.sponsor(data))
            if (key === 'player') dispatch(actions.player(data))
            if (key === 'heroes') dispatch(actions.heroes(data))
            if (key === 'missions') dispatch(actions.mission(data))
            if (key === 'items') dispatch(actions.items(data))
            if (key === 'quests')
                //FIX not working
                dispatch(actions.quest(data))
            // console.log(msg)
        })
        return () => {
            socket?.off('update-user')
        }
    }, [dispatch, socket])
}
