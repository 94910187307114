import { authAPI, gameAPI } from '../../Common/Api'
import { jwt } from '../../Common/Jwt'
import { actions } from '../main'

import { Dispatch } from '../Types'

export function fetchAuth() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const auth = await authAPI.fetch()

        dispatch(actions.auth(auth.data))
    }
}

export function fetchPlayer() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const player = await gameAPI.player.getMe()

        dispatch(actions.player(player.data))
    }
}

export function fetchItems() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const items = await gameAPI.items.list()

        dispatch(actions.items(items.data))
    }
}

export function fetchHeroes() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const heroes = await gameAPI.hero.list()

        dispatch(actions.heroes(heroes.data))
    }
}

export function fetchQuest() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const quests = await gameAPI.quests.list()
        dispatch(actions.quest(quests.data))
    }
}

export function fetchMissions() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const missions = await gameAPI.missions.list()
        dispatch(actions.mission(missions.data))
    }
}

export function fetchRouletteSponsor() {
    return async (dispatch: Dispatch) => {
        if (!jwt.getToken()) return
        const sponsor = await gameAPI.roulette.getSponsor()
        dispatch(actions.sponsor(sponsor.data))
    }
}

// export function fetchMissions() {
//     return async (dispatch: Dispatch) => {
//         if (!jwt.getToken()) return
//         const missions = await gameAPI.missions.list()

//         dispatch(setMissions(missions.data))
//     }
// }
