import { t } from 'i18next'
import { useMemo } from 'react'
import { lootboxMapIcons } from '../Config/Lootbox'
import { Lootbox } from '../Types/Lootbox'

export function useLootbox(lootbox: Lootbox) {
    const img = useMemo(() => lootboxMapIcons.get(lootbox.rarity), [lootbox])
    const name = useMemo(() => t(`name.lootbox.${lootbox.rarity}`), [lootbox])
    return {
        img,
        name,
    }
}
