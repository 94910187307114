import { FC } from 'react'
import { Field } from '../../../../Types/Field'
import { useQuestInfo } from '../../../../Hooks/useQuestInfo'

export const QuestFieldInfo: FC<{ field: Field }> = ({ field }) => {
    const questInfo = useQuestInfo(field.object)
    return (
        <div className="quest">
            <div className="quest__head adjust-top">
                <div className="head__half">
                    <div className="head__title">
                        <div className="title-difficulty">
                            <div className="difficulty-level">
                                {field.questLevel}
                            </div>
                            LVL
                        </div>
                        <div className="title-name adjust-left">
                            {questInfo?.name}
                        </div>
                    </div>
                </div>
                <div className="head__half"></div>
            </div>
            <div className="quest__body gradient">
                <div className="quest__half border-right">
                    <img
                        className="quest-image"
                        src={questInfo?.image}
                        alt=""
                    />
                </div>
                <div className="quest__half">
                    <div className="quest__description">
                        <h3 className="quest__title">{questInfo?.name}</h3>
                        <p className="quest-description">
                            {questInfo?.description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
