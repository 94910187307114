import { FC, useMemo } from 'react'
import { Field } from '../../../../Types/Field'
import { ResourceType } from '../../../../Types/Resource'
import { resourcesMap } from '../../../../Config/Resources'
import { images } from '../../../../Assets'

export const QuestRewardItems: FC<{ field: Field }> = ({ field }) => {
    return (
        <div className="quest-reward">
            {field.questConfig?.resources.map(([type, min, max], i) => (
                <QuestRewardItem resourceType={type} key={i}></QuestRewardItem>
            ))}
        </div>
    )
}

export const QuestRewardItem: FC<{ resourceType: ResourceType }> = ({
    resourceType,
}) => {
    const texture = useMemo(() => {
        return resourcesMap.get(resourceType) || images.icon_army
    }, [resourceType])
    return (
        <div className="quest-reward__item item item-default">
            <div className="item__inner">
                <img src={texture} alt="" className="item__icon" />
            </div>
        </div>
    )
}
