import { FC } from 'react'
import { HeroClass } from '../../../Types/Hero'
import { useHeroAvatar } from '../../../Hooks/useHero'
import classNames from 'classnames'

export const RawHeroAvatar: FC<{
    heroClass?: HeroClass
    showDelete?: boolean
}> = ({ heroClass, showDelete }) => {
    const avatar = useHeroAvatar(heroClass)
    return (
        <div
            className={classNames('heroes-avatar', {
                'delete-mouse': showDelete,
            })}
        >
            {showDelete && <span className="delete"></span>}
            {avatar && <img src={avatar} className="avatar" alt="" />}
        </div>
    )
}
