import classNames from 'classnames'
import { FC } from 'react'

export const Loader = () => {
    return (
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export const Loader2: FC<{ className?: string }> = ({ className }) => {
    return (
        <div className={classNames('loader-2', className)}>
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export const ScreenLoader = () => {
    return (
        <div className="loader-screen">
            <div className="loader-screen__header">
                <div className="loader-screen__inner">
                    Welcome to IMPS KINGDOM
                </div>
            </div>
        </div>
    )
}
