import { Tile, TileDebug } from '../Components/Core/Tile'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { Region } from '../Types/Region'
import { gameAPI } from '../Common/Api'
import { Map } from '../Components/Core/Map'
import { createArrayTo } from '../Common/Array'
import { useTypedSelector } from '../Hooks/useTypedSelector'
import { Input } from '../Components/UI/Input'
import { useSocket } from '../Hooks/useSocket'
import { RectSize } from '../Config/Field'

export const Sector = () => {
    const { id } = useParams<{ id: string }>()
    const [region, setRegion] = useState<Region>()
    const debug = useTypedSelector((s) => s.debug)
    const [debugOffsetX, setDebugOffsetX] = useState('')
    const [debugOffsetY, setDebugOffsetY] = useState('')
    const socket = useSocket()

    useEffect(() => {
        gameAPI.map.regions.id(id).then((res) => setRegion(res.data))
    }, [id])

    useEffect(() => {
        socket?.on('region', (data) => setRegion(data))
        socket?.emit('/join/region', id)

        return () => {
            socket?.emit('/leave/region', id)
            socket?.off('region')
        }
    }, [socket, id])

    return (
        <>
            {region && (
                <Map
                    type="tile"
                    minX={region.borderMinX * RectSize}
                    minY={region.borderMinY * RectSize}
                    maxX={region.borderMaxX * RectSize}
                    maxY={region.borderMaxY * RectSize}
                >
                    {debug &&
                        createArrayTo(30).map((x) => {
                            return createArrayTo(30).map((y) => (
                                <TileDebug
                                    x={x - 15 + +debugOffsetX}
                                    y={y - 15 + +debugOffsetY * -1}
                                    key={`${x}${y}`}
                                ></TileDebug>
                            ))
                        })}

                    {!!region?.fields &&
                        region.fields.map((field) => (
                            <Tile field={field} key={field.id} />
                        ))}
                </Map>
            )}

            {debug && (
                <div
                    style={{
                        position: 'fixed',
                        left: 0,
                        bottom: 0,
                        zIndex: 1000,
                    }}
                >
                    <Input
                        state={[debugOffsetX, setDebugOffsetX]}
                        placeholder="x offset"
                        type="number"
                    ></Input>
                    <Input
                        state={[debugOffsetY, setDebugOffsetY]}
                        placeholder="y offset"
                        type="number"
                    ></Input>
                </div>
            )}
        </>
    )
}
