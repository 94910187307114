import { FC, FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { authAPI } from '../../Common/Api'
import { Button } from '../../Components/UI/Button'
import { ScreenCard } from '../../Components/UI/Card'
import { Checkbox } from '../../Components/UI/Checkbox'
import { Form, FormElement } from '../../Components/UI/Form'
import { Input } from '../../Components/UI/Input'
import { useInit } from '../../Hooks/useInit'
import { routes } from '../../Router/RoutesName'

export const Login: FC = () => {
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [remember, setRemember] = useState(true)

    const initial = useInit()

    async function login(e?: FormEvent) {
        e?.preventDefault()
        await authAPI.login({
            email,
            password: pass,
            remember,
        })
        // .catch(e => {
        //     if(e.response.status === 401) return nErr('sss')
        // })
        initial()
    }

    return (
        <ScreenCard title="Login" open={true} bg="art" logo>
            <Form onSubmit={login}>
                <FormElement>
                    <Input
                        state={[email, setEmail]}
                        placeholder="E-mail"
                        type="email"
                        name="email"
                    ></Input>
                </FormElement>
                <FormElement>
                    <Input
                        state={[pass, setPass]}
                        placeholder="Password"
                        type="password"
                        name="password"
                    ></Input>
                </FormElement>
                <FormElement>
                    <Checkbox
                        state={[remember, setRemember]}
                        label="Remember me"
                    ></Checkbox>
                </FormElement>
                <FormElement>
                    <Link to={routes.auth.register}>Sign Up</Link>
                </FormElement>
                <FormElement>
                    <Link to={routes.auth.restore}>Forgot password</Link>
                </FormElement>
                <FormElement>
                    <Button type="submit" color="blue" fill>
                        Login
                    </Button>
                </FormElement>
            </Form>
        </ScreenCard>
    )
}
