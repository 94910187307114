import { images } from '../Assets'
import { Rarity } from '../Types/Main'

export const lootboxMapIcons = new Map<Rarity | undefined, string>([
    [Rarity.COMMON, images.item_lootbox_common],
    [Rarity.EPIC, images.item_lootbox_epic],
    [Rarity.LEGENDARY, images.item_lootbox_legendary],
    [Rarity.MYTHICAL, images.item_lootbox_mythical],
    [Rarity.RARE, images.item_lootbox_rare],
])
