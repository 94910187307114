import { FC, MouseEventHandler } from 'react'
import classNames from 'classnames'
import { UiNinePassTexture } from '../../../Types/UiTexture'

/**
 *
 * @param preset
 * @param className
 * @param onClick
 * @param children
 */
export const NinePassComponent: FC<{
    preset: Map<UiNinePassTexture, string>
    className?: string
    onClick?: MouseEventHandler<HTMLDivElement>
}> = ({ preset, className, onClick, children }) => {
    return (
        <div className={classNames('textured', className)} onClick={onClick}>
            <table>
                <tbody>
                    <tr>
                        <td
                            className={classNames('textured_image')}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.TOP_LEFT
                                )})`,
                            }}
                        ></td>
                        <td
                            className={classNames(
                                'textured_image',
                                'textured_top'
                            )}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.TOP
                                )})`,
                            }}
                        ></td>
                        <td
                            className={classNames('textured_image')}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.TOP_RIGHT
                                )})`,
                            }}
                        ></td>
                    </tr>
                    <tr>
                        <td
                            className={classNames(
                                'textured_image textured_left'
                            )}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.CENTER_LEFT
                                )})`,
                            }}
                        ></td>
                        <td
                            className={classNames(
                                'textured_image',
                                'content-container'
                            )}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.CENTER
                                )})`,
                            }}
                        >
                            {children}
                        </td>
                        <td
                            className={classNames(
                                'textured_image textured_right'
                            )}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.CENTER_RIGHT
                                )})`,
                            }}
                        ></td>
                    </tr>
                    <tr>
                        <td
                            className={classNames('textured_image')}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.BOTTOM_LEFT
                                )})`,
                            }}
                        ></td>
                        <td
                            className={classNames(
                                'textured_image',
                                'textured_bottom'
                            )}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.BOTTOM
                                )})`,
                            }}
                        ></td>
                        <td
                            className={classNames('textured_image')}
                            style={{
                                backgroundImage: `url(${preset.get(
                                    UiNinePassTexture.BOTTOM_RIGHT
                                )})`,
                            }}
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
