import { useMemo } from 'react'
import { GexHeight, GexWidth, MaxGexZIndex } from '../Config/Field'

export function useGex(x: number, y: number) {
    return useMemo(() => {
        /**
         * Если значение x кратное 2-м, то умножаем коэф на y и прибавляем половину от коэф для смещения по графику
         * @param k - коэф
         * @returns
         */
        const getTop = (k: number) => (!(x % 2) ? y * k : y * k + k / 2)
        const top = getTop(GexHeight)

        return {
            top,
            left: x * (GexWidth - GexWidth / 4),
            zIndex: MaxGexZIndex + getTop(10),
        }
    }, [x, y])
}
