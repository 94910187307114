import { useMemo } from 'react'
import { heroEggMap } from '../Config/HeroEgg'
import { HeroEgg } from '../Types/HeroEgg'

export function useEgg(egg: HeroEgg) {
    const img = useMemo(() => {
        return heroEggMap.get(egg.rarity)
    }, [egg])
    const info = useMemo(() => {
        const rarity = egg.rarity
        const item_id = egg.id
        const itemClass = 'Hero Stone'

        return {
            rarity,
            item_id,
            itemClass,
        }
    }, [egg])
    return {
        img,
        info,
    }
}
