import { FC } from 'react'
import { RectSize } from '../../Config/Field'
import { Field } from '../../Types/Field'
import { useFields } from '../../Hooks/useFields'
import { useTypedSelector } from '../../Hooks/useTypedSelector'

export const Tile: FC<{ field: Field }> = ({ field }) => {
    const FieldHelper = useFields(field)
    const debug = useTypedSelector((s) => s.debug)

    return (
        <div
            className={`tile ${debug ? 'tile_debug-red' : ''}`}
            style={{
                width: field.width * RectSize,
                height: field.height * RectSize,
                top: field.y * RectSize * -1,
                left: field.x * RectSize,
                zIndex: field.z_index,
            }}
            title={debug ? `id:${field.id} texture:${field.object}` : ''}
        >
            {!!FieldHelper && <FieldHelper field={field}></FieldHelper>}
        </div>
    )
}

export const TileDebug: FC<{ x: number; y: number }> = ({ x, y }) => {
    return (
        <div
            className="tile tile_debug"
            data-debug={`x: ${x}; y: ${y * -1}`}
            style={{
                top: y * RectSize,
                left: x * RectSize,
                width: RectSize,
                height: RectSize,
            }}
        />
    )
}
