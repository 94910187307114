import { FC } from 'react'
import { images } from '../../../../../../Assets'

export const LootboxPrice: FC<{
    price: number
}> = ({ price }) => {
    return (
        <div className="lootbox-info__price">
            <img className="currency-icon" src={images.icon_gold} alt="" />
            <span className="cost">{price}</span>
        </div>
    )
}
