import { t } from 'i18next'
import _ from 'lodash'
import { useMemo } from 'react'
import { images } from '../Assets'
import { heroEggMap } from '../Config/HeroEgg'
import { lootboxMapIcons } from '../Config/Lootbox'
import { resourcesMap } from '../Config/Resources'
import { Item } from '../Types/Item'
import { Rarity } from '../Types/Main'

export function getItemName(item: Item) {
    if (item?.resource) {
        const translation = `name.resource.${item.resource.type}`
        if (t(translation) === translation)
            return _.capitalize(_.lowerCase(item.resource.type))
        return t(translation)
    }
    if (item?.equipment) return _.capitalize(_.lowerCase(item.equipment.name))
    if (item?.lootbox) return t(`name.lootbox.${item.lootbox.rarity}`)
    if (item?.heroEgg) return t(`name.heroegg.${item.heroEgg.rarity}`)
}

export function getResourceRarity(tier: number | Rarity) {
    if (_.isNumber(tier)) {
        if (tier === 1) return Rarity.COMMON
        if (tier === 2) return Rarity.RARE
        if (tier === 3) return Rarity.EPIC
        return
    }
    return tier
}

export function useItem(item: Item | undefined) {
    const img = useMemo(() => {
        if (item?.lootbox) return lootboxMapIcons.get(item.lootbox.rarity)
        if (item?.resource) return resourcesMap.get(item.resource.type)
        if (item?.heroEgg) return heroEggMap.get(item.heroEgg.rarity)
        if (item?.equipment) return images.icon_army
    }, [item])

    const name = useMemo(() => {
        if (!item) return ''
        return getItemName(item)
    }, [item])

    const description = useMemo(() => {
        if (item?.resource)
            return t(`description.resource.${item.resource.type}`)
    }, [item])

    const type = useMemo(() => {
        if (item?.equipment) return item?.equipment?.computed?.base?.type
    }, [item])

    const tier = useMemo(() => {
        if (item?.resource?.tier && _.isNumber(item.resource.tier))
            return item.resource.tier
    }, [item])

    const rarityAsColor = useMemo(() => {
        if (item?.resource?.tier) {
            const tier = item.resource.tier
            return getResourceRarity(tier)
        }
        return (
            item?.lootbox?.rarity ||
            item?.heroEgg?.rarity ||
            item?.equipment?.computed?.rarity
        )
    }, [item])

    const rarityAsValue = useMemo(() => {
        if (item?.resource?.tier) {
            const tier = item.resource.tier
            if (_.isNumber(tier)) return
            const set = new Set(Object.values(Rarity))
            if (set.has(tier)) return tier
        }
        return (
            item?.lootbox?.rarity ||
            item?.heroEgg?.rarity ||
            item?.equipment?.computed?.rarity
        )
    }, [item])

    const grid = useMemo(() => {
        return item?.equipment?.computed?.base?.grid
    }, [item])

    const oldCount = useMemo(() => {
        return item?.resource?.oldCount || 0
    }, [item])

    const resCount = useMemo(() => {
        return item?.resource?.count || 0
    }, [item])

    const count = useMemo(() => {
        return resCount - oldCount
    }, [resCount, oldCount])

    const item_id = useMemo(() => {
        return item?.id
    }, [item])

    const hero_id = useMemo(() => {
        return item?.equipment?.heroId
    }, [item])

    const itemClass = useMemo(() => {
        if (item?.equipment) return 'equipment'
        if (item?.heroEgg) return 'heroegg'
        if (item?.resource) return 'resource'
        if (item?.lootbox) return 'lootbox'
    }, [item])

    const info = useMemo(() => {
        return {
            name,
            type,
            grid,
            count,
            item_id,
            hero_id,
            itemClass,
            tier,
            description,
            rarityAsColor,
            rarityAsValue,
        }
    }, [
        name,
        type,
        grid,
        count,
        item_id,
        hero_id,
        itemClass,
        tier,
        description,
        rarityAsColor,
        rarityAsValue,
    ])
    return {
        img,
        info,
    }
}
