import { images } from '../Assets'
import { Rarity } from '../Types/Main'

export const heroEggMap = new Map<Rarity | undefined, string>([
    [Rarity.COMMON, images.icon_egg_common],
    [Rarity.EPIC, images.icon_egg_epic],
    [Rarity.LEGENDARY, images.icon_egg_legendary],
    [Rarity.MYTHICAL, images.icon_egg_mythical],
    [Rarity.RARE, images.icon_egg_rare],
])
