import Axios from 'axios'
import { jwt } from '../Jwt'
import { axiosError } from './Errors'

const a = Axios.create({
    baseURL: '/api',
})

a.interceptors.request.use(function (config) {
    if (!config.headers) {
        config.headers = {
            Authorization: jwt.getToken() || '',
        }
    }
    config.headers.Authorization = jwt.getToken() || ''

    return config
})

a.interceptors.response.use((res) => {
    return Promise.resolve(res)
}, axiosError)

export const appAxios = a
