import classNames from 'classnames'
import {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { PopupContext } from '../Ctx/Popup'
import { useEvent } from '../Hooks/useEvent'
import { State } from '../Types/Main'
import { NavCastle } from './Core/Fields/Castle/NavCastle'

export interface PopupInnerPage {
    component: ReactNode
    icon?: ReactNode
    name: string
}

export type PopupBackgroundType =
    | 'heroes'
    | 'castle'
    | 'lootbox'
    | 'fraction'
    | 'gold'
export type HeaderpresetType = 'default' | 'linear'

export const Popup: FC<{
    show: State<boolean>
    hidden?: boolean
    showHeader?: boolean
    background?: PopupBackgroundType
    headerPreset?: HeaderpresetType
    title?: string
    fullTitle?: boolean
}> = ({
    show,
    showHeader = true,
    children,
    background,
    title,
    headerPreset,
    fullTitle,
}) => {
    const [state, setState] = show
    const [page, setPage] = useState<string | null>(null)
    const [activeChildren, setActiveChildren] = useState<boolean>(false)

    const className = background ? classNames(`popup_${background}`) : ''

    const close = useCallback(() => {
        if (activeChildren) {
            setActiveChildren(false)
            setPage(null)
        }
        return setState(false)
    }, [setState, activeChildren])

    const escHandler = useCallback(
        (ev: KeyboardEvent) => {
            if (ev.key !== 'Escape') return
            ev.preventDefault()
            close()
        },
        [close]
    )

    useEvent('keydown', escHandler)
    if (!state) return null

    return createPortal(
        <PopupContext.Provider
            value={{
                setPopupPage: setPage,
                setActiveChildren,
                activeChildren,
                popupPage: page || undefined,
            }}
        >
            <div
                className={classNames(
                    'popup',
                    { 'popup-show': state },
                    className
                )}
            >
                {(showHeader || activeChildren) && (
                    <div
                        className={classNames(
                            'popup__header',
                            `popup__header-${headerPreset || ''}`
                        )}
                    >
                        <div
                            className={classNames(
                                'popup__title',
                                `popup__title-${headerPreset || ''}`
                            )}
                        >
                            {fullTitle && page
                                ? `${title} - ${page}`
                                : page || title}
                        </div>
                        <div
                            className={classNames(
                                'popup__close',
                                `popup__close-${headerPreset || ''}`
                            )}
                            onClick={close}
                        ></div>
                    </div>
                )}
                <div className="popup__body">{children}</div>
            </div>
        </PopupContext.Provider>,
        document.querySelector('#popup')!
    )
}

export const PopupInner: FC<{
    pages: PopupInnerPage[]
    buttons?: ReactNode[]
    className?: string
}> = ({ pages, buttons, className }) => {
    const [page, setPage] = useState(pages[0]?.name || '')

    const { setPopupPage } = useContext(PopupContext)

    useEffect(() => {
        if (setPopupPage) setPopupPage(page)
    }, [page, setPopupPage])

    const Component = useMemo(
        () => pages.find((p) => p.name === page)?.component,
        [pages, page]
    )
    return (
        <div className="popup-inner">
            <div className="popup-inner__body">{Component}</div>
            <div className={classNames('popup-inner__tabs', className)}>
                {pages.map((p) => (
                    <div
                        className={classNames('popup-inner__tab', {
                            'popup-inner__tab-active': p.name === page,
                        })}
                        key={p.name}
                        onClick={() => setPage(p.name)}
                    >
                        {!!p.icon && (
                            <div className="popup-inner__icon">{p.icon}</div>
                        )}
                        <div className="popup-inner__name">{p.name}</div>
                    </div>
                ))}
                {buttons?.map((button, i) => (
                    <div className="popup-inner__button" key={i}>
                        {button}
                    </div>
                ))}
            </div>
        </div>
    )
}

export const PopupInnerCastle: FC<{
    onPageChanged?: (page: string) => void
    pages: { component: ReactNode; icon: ReactNode; name: string }[]
}> = ({ pages, onPageChanged, children }) => {
    const [page, setPage] = useState('')

    const { setActiveChildren, setPopupPage, activeChildren } =
        useContext(PopupContext)

    useEffect(() => {
        if (setPopupPage) setPopupPage(page)
        if (setActiveChildren) setActiveChildren(page === '' ? false : true)
        if (onPageChanged) onPageChanged(page)
    }, [page, setPopupPage, setActiveChildren, onPageChanged])

    const Component = useMemo(
        () => pages.find((p) => p.name === page)?.component,
        [pages, page]
    )

    const itemsCastle = useMemo(() => {
        return pages.map((page) => {
            return {
                icon: page.icon,
                name: page.name,
            }
        })
    }, [pages])

    return (
        <div className="popup-inner">
            <div className="popup-inner__body">
                {activeChildren ? (
                    Component
                ) : (
                    <>
                        {children}
                        <NavCastle
                            items={itemsCastle}
                            setCastleItem={(p) => setPage(p)}
                        />
                    </>
                )}
            </div>
        </div>
    )
}
