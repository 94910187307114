import { FC } from 'react'
import { HeroEgg } from '../../../../../Types/HeroEgg'
import { useEgg } from '../../../../../Hooks/useEgg'
import { EggImage, EggPower } from './helpers'
import { Button } from '../../../../UI/Button'

export const EggRarityUp: FC<{
    resultEgg: HeroEgg
    previousRarity?: string
    closeEggResult: () => void
}> = ({ resultEgg, previousRarity, closeEggResult }) => {
    const { img, info } = useEgg(resultEgg)
    return (
        <>
            <EggImage img={img}></EggImage>
            <div className="lootbox-info indent-bottom">
                <div className="lootbox-info__header">
                    <p>The call failed!</p>
                </div>
                <p className="lootbox-info__text">
                    The rarity of the egg has been increased!
                </p>
                <p className="lootbox-info__text">
                    {previousRarity} {'>'} {info.rarity}
                </p>
                <EggPower
                    className="m-t-auto"
                    showPowerUp
                    eggPower={resultEgg.n_power}
                />
            </div>
            <div className="lootbox__buttons center">
                <Button onClick={closeEggResult}>OK</Button>
            </div>
        </>
    )
}
