import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

export const Tooltip: FC<TooltipProps> = (props) => {
    const [renderTooltip, setRenderTooltip] = useState(false)
    useEffect(() => {
        setTimeout(() => setRenderTooltip(true), 0)
        return
    })
    return createPortal(
        <>
            {renderTooltip && (
                <ReactTooltip {...props}>{props.children}</ReactTooltip>
            )}
        </>,
        document.getElementById('tooltip')!
    )
}
