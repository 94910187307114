import { FC, useCallback } from 'react'
import classNames from 'classnames'

export const EggPower: FC<{
    eggPower: number
    showPowerUp?: boolean
    className?: string
}> = ({ eggPower, showPowerUp, className }) => {
    const powerUpText = useCallback(
        (key: number) => (showPowerUp && key === eggPower - 1 ? '+1' : ''),
        [showPowerUp, eggPower]
    )
    return (
        <div className={classNames('egg_power', className)}>
            <p className="power__value">NPower {eggPower}/6</p>
            <div className="power__progress">
                {[0, 1, 2, 3, 4, 5].map((_, i) => (
                    <span
                        key={i}
                        className={classNames('progress__stage', {
                            passed: eggPower > i,
                        })}
                    >
                        {powerUpText(i)}
                    </span>
                ))}
            </div>
        </div>
    )
}
