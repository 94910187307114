import { FC } from 'react'
import {
    UiThreePassTexture,
    UIThreePassTextureConfig,
} from '../../../Types/UiTexture'
import classNames from 'classnames'

export const ThreePassComponent: FC<{
    preset: UIThreePassTextureConfig
    className?: string
}> = ({ preset, className, children }) => {
    const { textures, width, height } = preset
    return (
        <div
            className={classNames('textured three-pass', className)}
            style={{ width, height }}
        >
            <div
                className="textured_image"
                style={{
                    backgroundImage: `url(${textures.get(
                        UiThreePassTexture.LEFT
                    )})`,
                }}
            ></div>
            <div
                className="textured_image content-container"
                style={{
                    backgroundImage: `url(${textures.get(
                        UiThreePassTexture.CENTER
                    )})`,
                }}
            >
                {children}
            </div>
            <div
                className="textured_image"
                style={{
                    backgroundImage: `url(${textures.get(
                        UiThreePassTexture.RIGHT
                    )})`,
                }}
            ></div>
        </div>
    )
}
