import gex from './Images/gex.png'
import tile from './Images/tile.png'
import building from './Images/building.png'
import castle_17 from './Images/castles/17.png'
import castle_18 from './Images/castles/18.png'
import castle_19 from './Images/castles/19.png'
import castle_20 from './Images/castles/20.png'
import castle_21 from './Images/castles/21.png'
import castle_22 from './Images/castles/22.png'

import bg_main_art from './Images/backgrounds/blur_art.png'

import mountain_1 from './Images/mountains/1.png'
import mountain_2 from './Images/mountains/2.png'
import mountain_3 from './Images/mountains/3.png'
import mountain_4 from './Images/mountains/4.png'
import mountain_5 from './Images/mountains/5.png'
import mountain_6 from './Images/mountains/6.png'
import mountain_7 from './Images/mountains/7.png'
import mountain_8 from './Images/mountains/8.png'
import mountain_9 from './Images/mountains/9.png'
import mountain_10 from './Images/mountains/10.png'
import mountain_11 from './Images/mountains/11.png'
import mountain_12 from './Images/mountains/12.png'

import oak_1 from './Images/oaks/1.png'
import oak_2 from './Images/oaks/2.png'
import oak_3 from './Images/oaks/3.png'
import oak_4 from './Images/oaks/4.png'
import oak_5 from './Images/oaks/5.png'
import oak_6 from './Images/oaks/6.png'
import oak_7 from './Images/oaks/7.png'
import oak_8 from './Images/oaks/8.png'
import oak_9 from './Images/oaks/9.png'
import oak_10 from './Images/oaks/10.png'
import oak_11 from './Images/oaks/11.png'
import oak_12 from './Images/oaks/12.png'
import oak_13 from './Images/oaks/13.png'

import pine_tree_1 from './Images/pineTree/1.png'
import pine_tree_2 from './Images/pineTree/2.png'
import pine_tree_3 from './Images/pineTree/3.png'
import pine_tree_4 from './Images/pineTree/4.png'
import pine_tree_5 from './Images/pineTree/5.png'
import pine_tree_6 from './Images/pineTree/6.png'
import pine_tree_7 from './Images/pineTree/7.png'
import pine_tree_8 from './Images/pineTree/8.png'
import pine_tree_9 from './Images/pineTree/9.png'
import pine_tree_10 from './Images/pineTree/10.png'
import pine_tree_11 from './Images/pineTree/11.png'
import pine_tree_12 from './Images/pineTree/12.png'

import ui_card_transparent from './Images/ui/ui_card_0.png'
import ui_background_art from './Images/backgrounds/background_art.png'
import ui_card_label_blue from './Images/ui/ui_card_label.png'
import ui_main_sreen_btn from './Images/ui/main_screen_button.png'
import ui_equipment_slot from './Images/ui/equipment_slot.png'
import ui_bg_popup_tab_active from './Images/ui/bg_popup_tab_active.png'
import ui_bg_popup_tab_castle from './Images/ui/bg_popup_tab_castle.png'
import ui_bg_popup_tab from './Images/ui/bg_popup_tab.png'
import ui_bg_hero_avatar from './Images/ui/bg_hero_avatar.png'
import ui_popup_tab_line_focus from './Images/ui/popup_tab_line_focus.png'
import ui_yellow_arrow from './Images/ui/yellow_arrow.png'
import ui_hall_of_keys from './Images/ui/hall_of_keys.png'
import ui_altar_of_summon from './Images/ui/altar_of_summon.png'
import ui_popup_icon_incubator from './Images/ui/ui_popup_icon_incubator.png'
import ui_popup_icon_hero_call from './Images/ui/ui_popup_icon_hero_call.png'
import ui_popup_icon_quest from './Images/ui/ui_popup_icon_quest.png'
import ui_popup_icon_info from './Images/ui/ui_popup_icon_info.png'
import ui_popup_icon_detachment from './Images/ui/ui_popup_icon_detachment.png'
import ui_icon_difficulty from './Images/ui/icon_difficulty.png'
import ui_icon_time from './Images/ui/icon_time.png'
import ui_quest_boss from './Images/ui/quest_boss.png'

import ui_button_green_topleft from './Images/ui/button_green/1.png'
import ui_button_green_top from './Images/ui/button_green/2.png'
import ui_button_green_topright from './Images/ui/button_green/3.png'
import ui_button_green_centerleft from './Images/ui/button_green/4.png'
import ui_button_green_center from './Images/ui/button_green/5.png'
import ui_button_green_centerright from './Images/ui/button_green/6.png'
import ui_button_green_bottomleft from './Images/ui/button_green/7.png'
import ui_button_green_bottom from './Images/ui/button_green/8.png'
import ui_button_green_bottomright from './Images/ui/button_green/9.png'
import ui_bar_left from './Images/ui/progress_bar/bar_1.png'
import ui_bar_center from './Images/ui/progress_bar/bar_2.png'
import ui_bar_right from './Images/ui/progress_bar/bar_3.png'

import ui_progress_bar_left from './Images/ui/progress_bar/1.png'
import ui_progress_bar_center from './Images/ui/progress_bar/2.png'
import ui_progress_bar_right from './Images/ui/progress_bar/3.png'

import icon_army from './Images/icons/army.png'
import icon_castle from './Images/icons/castle.png'
import icon_quest from './Images/icons/quest.png'
import icon_inventory from './Images/icons/inventory.png'
import icon_global_map from './Images/icons/global_map.png'
import icon_gold from './Images/icons/gold.png'
import icon_rank from './Images/icons/rank.png'
import icon_min_generals from './Images/icons/min_generals.png'
import icon_min_heroes from './Images/icons/min_heroes.png'
import icon_min_troops from './Images/icons/min_troops.png'
import icon_min_axe from './Images/icons/min_axe.png'
import icon_min_blue_circe from './Images/icons/min_blue_circe.png'
import icon_min_clever from './Images/icons/min_clever.png'
import icon_min_explosion from './Images/icons/min_explosion.png'
import icon_min_hat from './Images/icons/min_hat.png'
import icon_min_health from './Images/icons/min_health.png'
import icon_min_magic from './Images/icons/min_magic.png'
import icon_min_shield from './Images/icons/min_shield.png'
import icon_profile_avatar from './Images/icons/profile_avatar.png'

import icon_egg_common from './Images/eggs/common.png'
import icon_egg_rare from './Images/eggs/rare.png'
import icon_egg_mythical from './Images/eggs/mythical.png'
import icon_egg_epic from './Images/eggs/epic.png'
import icon_egg_legendary from './Images/eggs/legendary.png'

import item_lootbox_common from './Images/items/lootbox_common.png'
import item_lootbox_epic from './Images/items/lootbox_epic.png'
import item_lootbox_mythical from './Images/items/lootbox_mythical.png'
import item_lootbox_rare from './Images/items/lootbox_rare.png'
import item_lootbox_legendary from './Images/items/lootbox_legendary.png'
import item_resource_arcane_dust_1 from './Images/items/resource_arcane_dust_1.png'
import item_resource_arcane_dust_2 from './Images/items/resource_arcane_dust_2.png'
import item_resource_arcane_dust_3 from './Images/items/resource_arcane_dust_3.png'
import item_resource_coal_ore from './Images/items/resource_coal_ore.png'
import item_resource_iron_ingot from './Images/items/resource_iron_ingot.png'
import item_resource_iron_ore from './Images/items/resource_iron_ore.png'
import item_resource_mythril_ingot from './Images/items/resource_mythril_ingot.png'
import item_resource_orichalcon_ingot from './Images/items/resource_orichalcon_ingot.png'
import item_resource_silver_ingot from './Images/items/resource_silver_ingot.png'
import item_resource_silver_ore from './Images/items/resource_silver_ore.png'
import item_resource_starmetal_ingot from './Images/items/resource_starmetal_ingot.png'
import item_resource_starmetal_ore from './Images/items/resource_starmetal_ore.png'
import item_resource_steel_ingot from './Images/items/resource_steel_ingot.png'
import item_resource_titanium_ingot from './Images/items/resource_titanium_ingot.png'
import item_resource_titanium_ore from './Images/items/resource_titanium_ore.png'
import item_resource_blessed_wood from './Images/items/resource_blessed_wood.png'
import item_resource_cured_wood from './Images/items/resource_cured_wood.png'
import item_resource_enchanted_wood from './Images/items/resource_enchanted_wood.png'
import item_resource_hardened_wood from './Images/items/resource_hardened_wood.png'
import item_resource_spirit_wood from './Images/items/resource_spirit_wood.png'
import item_resource_wood from './Images/items/resource_wood.png'
import item_resource_blessed_leather from './Images/items/resource_blessed_leather.png'
import item_resource_cured_leather from './Images/items/resource_cured_leather.png'
import item_resource_enchanted_leather from './Images/items/resource_enchanted_leather.png'
import item_resource_hardened_leather from './Images/items/resource_hardened_leather.png'
import item_resource_leather from './Images/items/resource_leather.png'
import item_resource_spirit_leather from './Images/items/resource_spirit_leather.png'
import item_resource_arcane_parchment from './Images/items/resource_arcane_parchment.png'
import item_resource_mystic_parchment from './Images/items/resource_mystic_parchment.png'
import item_resource_parchment from './Images/items/resource_parchment.png'
import item_resource_excellent_instruments_blacksmith from './Images/items/resource_excellent_instruments_blacksmith.png'
import item_resource_excellent_instruments_jeweler from './Images/items/resource_excellent_instruments_jeweler.png'
import item_resource_instruments_blacksmith from './Images/items/resource_instruments_blacksmith.png'
import item_resource_instruments_jeweler from './Images/items/resource_instruments_jeweler.png'
import item_resource_armor_piece from './Images/items/resource_armor_piece.png'
import item_resource_weapon_piece from './Images/items/resource_weapon_piece.png'
import item_resource_recipe from './Images/items/resource_recipe.png'
import item_resource_excellent_whetstone from './Images/items/resource_excellent_whetstone.png'
import item_resource_whetstone from './Images/items/resource_whetstone.png'
import item_resource_armor_plate from './Images/items/resource_armor_plate.png'
import item_resource_excellent_armor_plate from './Images/items/resource_excellent_armor_plate.png'
import item_resource_flare_crystal from './Images/items/resource_flare_crystal.png'
import item_resource_scorching_stone from './Images/items/resource_scorching_stone.png'
import item_resource_star_shard from './Images/items/resource_star_shard.png'
import item_resource_gold from './Images/items/resource_gold.png'
import item_resource_wild_fire_common from './Images/items/resource_wild_fire_common.png'
import item_resource_wild_fire_rare from './Images/items/resource_wild_fire_rare.png'
import item_resource_wild_fire_epic from './Images/items/resource_wild_fire_epic.png'
import item_resource_wild_fire_mythical from './Images/items/resource_wild_fire_mythical.png'
import item_resource_wild_fire_legendary from './Images/items/resource_wild_fire_legendary.png'
import item_resource_star_dust from './Images/items/resource_star_dust.png'

import object_claim from './Images/objects/claim.png'
import object_bandit_camp from './Images/objects/bandit_camp.png'
import object_abandoned_tower from './Images/objects/abandoned_tower.png'
import object_crypt from './Images/objects/crypt.png'
import object_ruins from './Images/objects/ruins.png'

import hero_warrior_icon from './Images/heroes/warrior_icon.png'
import hero_warrior_mini from './Images/heroes/mini_warrior.png'
import hero_warrior_common from './Images/heroes/warrior_common.png'
import hero_warrior_rare from './Images/heroes/warrior_rare.png'
import hero_warrior_epic from './Images/heroes/warrior_epic.png'
import hero_warrior_mythic from './Images/heroes/warrior_mythic.png'
import hero_paladin_icon from './Images/heroes/paladin_icon.png'
import hero_paladin_mini from './Images/heroes/mini_paladin.png'
import hero_paladin_common from './Images/heroes/paladin_common.png'
import hero_paladin_rare from './Images/heroes/paladin_rare.png'
import hero_paladin_epic from './Images/heroes/paladin_epic.png'
import hero_paladin_mythic from './Images/heroes/paladin_mythic.png'
import hero_paladin_legendary from './Images/heroes/paladin_legendary.png'
import hero_paladin_astral from './Images/heroes/paladin_astral.png'
import hero_druid_icon from './Images/heroes/druid_icon.png'
import hero_druid_common from './Images/heroes/druid_common.png'
import hero_druid_rare from './Images/heroes/druid_rare.png'
import hero_druid_epic from './Images/heroes/druid_epic.png'
import hero_druid_mythic from './Images/heroes/druid_mythic.png'
import hero_druid_legendary from './Images/heroes/druid_legendary.png'
import hero_druid_astral from './Images/heroes/druid_astral.png'
import hero_mage_icon from './Images/heroes/mage_icon.png'
import hero_mage_mini from './Images/heroes/mini_mage.png'
import hero_mage_common from './Images/heroes/mage_common.png'
import hero_mage_rare from './Images/heroes/mage_rare.png'
import hero_bandit_icon from './Images/heroes/bandit_icon.png'
import hero_bandit_mini from './Images/heroes/mini_bandit.png'
import hero_bandit_common from './Images/heroes/bandit_common.png'
import hero_bandit_rare from './Images/heroes/bandit_rare.png'

import big_logo from './Images/big_logo.png'

import equipment_frame_focus from './Images/equipments/equipment_frame_focus.png'
import equipment_frame from './Images/equipments/equipment_frame.png'
import equipment_focus from './Images/equipments/equipment_focus.png'
import equipment_frame_red from './Images/equipments/equipment_frame_red.png'
import equipment_frame_gold from './Images/equipments/equipment_frame_gold.png'
import equipment_frame_purple from './Images/equipments/equipment_frame_purple.png'
import equipment_frame_silver from './Images/equipments/equipment_frame_silver.png'
import equipment_frame_blue from './Images/equipments/equipment_frame_blue.png'
import accessory_eq from './Images/equipments/bodyPart/accessory_eq.png'
import shield_eq from './Images/equipments/bodyPart/shield_eq.png'
import weapon_eq from './Images/equipments/bodyPart/weapon_eq.png'
import ring_eq from './Images/equipments/bodyPart/ring_eq.png'
import dredus from './Images/dredus.png'
import fraction_flag_1 from './Images/fraction_flag.png'
import infernus from './Images/infernus.png'
import currency_oasis_network from './Images/currencies/oasis_network.png'
import ui_king_ticket from './Images/ui/king_ticket.png'
import ui_inventory from './Images/ui/inventory.png'
import icon_roulette from './Images/ui/icon_roulette.png'
import ui_bg_king_level_items from './Images/ui/bg_king_level_items.png'
import ui_bg_king_ticket from './Images/ui/bg_king_ticket.png'
import ui_market_icon from './Images/ui/market_icon.png'
import altar_of_summon_icon from './Images/ui/altar_of_summon_icon.png'
import auction_icon from './Images/ui/auction_icon.png'
import alchemist_hut_icon from './Images/ui/alchemist_hut_icon.png'
import magician_hut_icon from './Images/ui/magician_hut_icon.png'
import forge_icon from './Images/ui/forge_icon.png'
import jeweler_house_icon from './Images/ui/jeweler_house_icon.png'
import icon_lock from './Images/icons/lock.png'
import icon_vip from './Images/ui/bg_vip.png'
import icon_resources from './Images/icons/resources.png'
import icon_settings from './Images/icons/settings.png'
import icon_equipments from './Images/icons/equipments.png'
import icon_profile from './Images/icons/profile.png'

export const images = {
    gex,
    building,
    tile,
    castle_17,
    castle_18,
    castle_19,
    castle_20,
    castle_21,
    castle_22,
    mountain_1,
    mountain_2,
    mountain_3,
    mountain_4,
    mountain_5,
    mountain_6,
    mountain_7,
    mountain_8,
    mountain_9,
    mountain_10,
    mountain_11,
    mountain_12,
    oak_1,
    oak_2,
    oak_3,
    oak_4,
    oak_5,
    oak_6,
    oak_7,
    oak_8,
    oak_9,
    oak_10,
    oak_11,
    oak_12,
    oak_13,
    pine_tree_1,
    pine_tree_2,
    pine_tree_3,
    pine_tree_4,
    pine_tree_5,
    pine_tree_6,
    pine_tree_7,
    pine_tree_8,
    pine_tree_9,
    pine_tree_10,
    pine_tree_11,
    ui_yellow_arrow,
    pine_tree_12,
    ui_background_art,
    ui_card_transparent,
    ui_card_label_blue,
    ui_main_sreen_btn,
    ui_equipment_slot,
    ui_bg_popup_tab,
    ui_bg_popup_tab_active,
    ui_bg_popup_tab_castle,
    ui_popup_tab_line_focus,
    ui_bg_hero_avatar,
    ui_icon_difficulty,
    ui_icon_time,
    ui_quest_boss,
    ui_button_green_topleft,
    ui_button_green_top,
    ui_button_green_topright,
    ui_button_green_centerleft,
    ui_button_green_center,
    ui_button_green_centerright,
    ui_button_green_bottomleft,
    ui_button_green_bottom,
    ui_button_green_bottomright,
    ui_bar_left,
    ui_bar_center,
    ui_bar_right,
    ui_progress_bar_left,
    ui_progress_bar_center,
    ui_progress_bar_right,
    ui_popup_icon_incubator,
    ui_popup_icon_hero_call,
    ui_popup_icon_detachment,
    ui_popup_icon_info,
    ui_popup_icon_quest,
    ui_hall_of_keys,
    ui_altar_of_summon,
    icon_army,
    icon_quest,
    icon_castle,
    icon_inventory,
    icon_global_map,
    icon_gold,
    icon_rank,
    icon_min_generals,
    icon_min_heroes,
    icon_min_troops,
    icon_min_axe,
    icon_min_blue_circe,
    icon_min_clever,
    icon_min_explosion,
    icon_min_hat,
    icon_min_health,
    icon_min_magic,
    icon_min_shield,
    icon_profile_avatar,
    item_lootbox_common,
    item_lootbox_epic,
    item_lootbox_legendary,
    item_lootbox_mythical,
    item_lootbox_rare,
    item_resource_arcane_dust_1,
    item_resource_arcane_dust_2,
    item_resource_arcane_dust_3,
    item_resource_coal_ore,
    item_resource_iron_ingot,
    item_resource_iron_ore,
    item_resource_mythril_ingot,
    item_resource_orichalcon_ingot,
    item_resource_silver_ingot,
    item_resource_silver_ore,
    item_resource_starmetal_ingot,
    item_resource_starmetal_ore,
    item_resource_steel_ingot,
    item_resource_titanium_ingot,
    item_resource_titanium_ore,
    item_resource_blessed_wood,
    item_resource_cured_wood,
    item_resource_enchanted_wood,
    item_resource_hardened_wood,
    item_resource_spirit_wood,
    item_resource_wood,
    item_resource_blessed_leather,
    item_resource_cured_leather,
    item_resource_enchanted_leather,
    item_resource_hardened_leather,
    item_resource_leather,
    item_resource_spirit_leather,
    item_resource_arcane_parchment,
    item_resource_mystic_parchment,
    item_resource_parchment,
    item_resource_excellent_instruments_blacksmith,
    item_resource_excellent_instruments_jeweler,
    item_resource_instruments_blacksmith,
    item_resource_instruments_jeweler,
    item_resource_armor_piece,
    item_resource_weapon_piece,
    item_resource_recipe,
    item_resource_excellent_whetstone,
    item_resource_whetstone,
    item_resource_armor_plate,
    item_resource_excellent_armor_plate,
    item_resource_flare_crystal,
    item_resource_scorching_stone,
    item_resource_star_shard,
    item_resource_gold,
    item_resource_wild_fire_common,
    item_resource_star_dust,
    item_resource_wild_fire_rare,
    item_resource_wild_fire_epic,
    item_resource_wild_fire_mythical,
    item_resource_wild_fire_legendary,
    icon_egg_common,
    icon_egg_rare,
    icon_egg_mythical,
    icon_egg_epic,
    icon_egg_legendary,
    hero_warrior_common,
    hero_warrior_rare,
    hero_warrior_epic,
    hero_warrior_mythic,
    hero_warrior_icon,
    hero_warrior_mini,
    hero_paladin_mini,
    hero_mage_mini,
    hero_bandit_mini,
    hero_paladin_icon,
    hero_paladin_common,
    hero_paladin_rare,
    hero_paladin_epic,
    hero_paladin_mythic,
    hero_paladin_legendary,
    hero_paladin_astral,
    hero_druid_icon,
    hero_druid_common,
    hero_druid_rare,
    hero_druid_epic,
    hero_druid_mythic,
    hero_druid_legendary,
    hero_druid_astral,
    hero_mage_icon,
    hero_mage_common,
    hero_mage_rare,
    hero_bandit_icon,
    hero_bandit_common,
    hero_bandit_rare,
    big_logo,
    object_claim,
    object_bandit_camp,
    object_abandoned_tower,
    object_crypt,
    object_ruins,
    equipment_frame,
    equipment_focus,
    equipment_frame_focus,
    equipment_frame_red,
    equipment_frame_gold,
    equipment_frame_purple,
    equipment_frame_silver,
    equipment_frame_blue,
    accessory_eq,
    weapon_eq,
    ring_eq,
    shield_eq,
    dredus,
    infernus,
    fraction_flag_1,
    bg_main_art,
    currency_oasis_network,
    ui_king_ticket,
    ui_inventory,
    icon_roulette,
    ui_bg_king_level_items,
    ui_bg_king_ticket,
    ui_market_icon,
    icon_lock,
    altar_of_summon_icon,
    auction_icon,
    forge_icon,
    jeweler_house_icon,
    icon_vip,
    icon_resources,
    icon_settings,
    icon_equipments,
    icon_profile,
    alchemist_hut_icon,
    magician_hut_icon,
}
