import { Switch, Route } from 'react-router-dom'
import { Invite } from '../Pages/Auth/Invite'
import { Login } from '../Pages/Auth/Login'
import { Register } from '../Pages/Auth/Register'
import { RestorePassPage } from '../Pages/Auth/RestorePass'
import { Index } from '../Pages/Index'
import { Sector } from '../Pages/Sector'
import { AuthMiddleware } from './Middlewares/Auth'
import { GuestMiddleware } from './Middlewares/Guest'
import { routes } from './RoutesName'

export const Router = () => {
    return (
        <>
            <Switch>
                <Route path={routes.user.id(':id')}></Route>

                <Route path={routes.auth.login}>
                    <GuestMiddleware>
                        <Login></Login>
                    </GuestMiddleware>
                </Route>

                <Route path={routes.auth.register}>
                    <GuestMiddleware>
                        <Register></Register>
                    </GuestMiddleware>
                </Route>
                <Route path={routes.auth.invite}>
                    <GuestMiddleware>
                        <Invite></Invite>
                    </GuestMiddleware>
                </Route>
                <Route path={routes.auth.restore}>
                    <GuestMiddleware>
                        <RestorePassPage></RestorePassPage>
                    </GuestMiddleware>
                </Route>

                <Route path={routes.sector.tile(':id')}>
                    <AuthMiddleware>
                        <Sector />
                    </AuthMiddleware>
                </Route>

                <Route path="/">
                    <AuthMiddleware>
                        <Index></Index>
                    </AuthMiddleware>
                </Route>
            </Switch>
        </>
    )
}
