import { FC, FormEvent, FormHTMLAttributes, useCallback } from 'react'
import classNames from 'classnames'

export const Form: FC<FormHTMLAttributes<HTMLFormElement>> = ({
    onSubmit,
    ...props
}) => {
    const onSubmitMiddleware = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            if (onSubmit) onSubmit(e)
        },
        [onSubmit]
    )
    return (
        <form
            onSubmit={onSubmitMiddleware}
            {...props}
            autoComplete="on"
            className={classNames('form', props.className)}
        >
            {props.children}
        </form>
    )
}

export const FormElement: FC = ({ children }) => {
    return <div className="form__element">{children}</div>
}
