import { useEffect, useState } from 'react'
import { gameAPI } from '../Common/Api'
import { Map } from '../Components/Core/Map'
import { Gex } from '../Components/Core/Gex'
import { Region } from '../Types/Region'

const centerX = window.innerWidth / 2
const centerY = window.innerHeight / 2

export const Index = () => {
    const [regions, setRegions] = useState<Region[]>([])
    useEffect(() => {
        gameAPI.map.regions.get().then((res) => setRegions(res.data))
    }, [])

    return (
        <div>
            <Map minY={-centerY} minX={-centerX} maxX={centerX} maxY={centerY}>
                {regions.map((reg) => (
                    <Gex region={reg} key={reg.id} />
                ))}
            </Map>
        </div>
    )
}
