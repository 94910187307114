export const routes = {
    auth: {
        register: '/auth',
        login: '/auth/login',
        invite: '/invite',
        restore: '/restore',
    },
    user: {
        id(id: number | ':id') {
            return `/user/${id}`
        },
    },
    sector: {
        tile: (id: number | ':id') => `/sector/${id}`,
    },
}
