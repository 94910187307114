export enum UiNinePassTexture {
    TOP_LEFT = 'TOP_LEFT',
    TOP = 'TOP',
    TOP_RIGHT = 'TOP_RIGHT',
    CENTER_LEFT = 'CENTER_LEFT',
    CENTER = 'CENTER',
    CENTER_RIGHT = 'CENTER_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM = 'BOTTOM',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export enum UiThreePassTexture {
    LEFT = 'LEFT',
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
}

export interface UIThreePassTextureConfig {
    textures: Map<UiThreePassTexture, string>
    width?: string | number
    height?: string | number
}

export interface UINinePassTextureConfig {
    textures: Map<UiNinePassTexture, string>
    minHeight?: string | number
    minWidth?: string | number
    width?: string | number
    height?: string | number
    topCornerProportions: string
    bottomCornerProportions: string
}
