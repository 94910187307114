import toastr from 'toastr'

export const nErr = toastr.error
export const nSuc = toastr.success

export const errors = {
    serverError() {
        nErr('Server Error!')
    },
    custom(text: string) {
        nErr(text)
    },
}
