import { useCallback, useEffect, useState } from 'react'
import { TexturesProvider } from './Common/Textures'
import { ScreenLoader } from './Components/Loader'
import { useDebug } from './Hooks/useDebug'
import { useInit } from './Hooks/useInit'
import { useTypedSelector } from './Hooks/useTypedSelector'
import { BetaLayout } from './Layouts/Beta'
import { UnauthorizedLayout } from './Layouts/Unauthorization'

function App() {
    const [loading, setLoading] = useState(true)
    const auth = useTypedSelector((s) => s.auth)
    const initial = useInit()

    const init = useCallback(async () => {
        setLoading(true)
        try {
            await Promise.all([TexturesProvider.init(), initial()])
        } catch (error) {}
        setLoading(false)
    }, [initial])

    useEffect(() => {
        init()
    }, [init])

    useDebug()

    return (
        <div className="App">
            {loading ? (
                <ScreenLoader></ScreenLoader>
            ) : auth ? (
                <BetaLayout></BetaLayout>
            ) : (
                <UnauthorizedLayout></UnauthorizedLayout>
            )}
        </div>
    )
}

export default App
