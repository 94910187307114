// import { images } from "../Assets"

export const GexHeight = window.innerWidth < 1000 ? 70 : 100
export const GexWidth = window.innerWidth < 1000 ? 140 : 200
export const MaxGexZIndex = 200

export const RectSize = window.innerWidth < 1000 ? 50 : 70

export const maxDragX = 1200
export const maxDragY = 1200

// export const textures = [
//     images.pine_tree_1,
//     images.pine_tree_2,
//     images.pine_tree_3,
//     images.pine_tree_4,
//     images.pine_tree_5,
//     images.pine_tree_6,
//     images.pine_tree_7,
//     images.pine_tree_8,
//     images.pine_tree_9,
//     images.pine_tree_10,
//     images.pine_tree_11,
//     images.mountain_1,
//     images.mountain_2,
//     images.mountain_3,
//     images.mountain_4,
//     images.mountain_5,
//     images.mountain_6,
//     images.mountain_7,
//     images.mountain_8,
//     images.mountain_9,
//     images.mountain_10,
//     images.mountain_11,
//     images.mountain_12,
//     images.oak_1,
//     images.oak_2,
//     images.oak_3,
//     images.oak_4,
//     images.oak_5,
//     images.oak_6,
//     images.oak_7,
//     images.oak_8,
//     images.oak_9,
//     images.oak_10,
//     images.building,

// ]
