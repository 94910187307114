import {
    EquipmentBodyPart,
    EquipmentClass,
    EquipmentName,
} from '../Types/Equipment'
import { images } from '../Assets'

export const EquipmentNameMap = new Map([
    [EquipmentName.BLOOD_CRYSTAL_AXE, 'Blood crystal axe'],
    [EquipmentName.DARK_STEEL_AXE, 'Dark steel axe'],
    [EquipmentName.DEMONIC_AXE, 'Demonic axe'],
    [EquipmentName.FIRE_AXE, 'Fire axe'],
    [EquipmentName.HARDENED_AXE, 'Hardened axe'],
    [EquipmentName.IRON_AXE, 'Iron axe'],
    [EquipmentName.LAVA_STONE_AXE, 'Lava stone axe'],
    [EquipmentName.ROUGH_IRON_AXE, 'Rough iron axe'],
    [EquipmentName.RUSTY_AXE, 'Rusty axe'],
    // [EquipmentName.RUSTY_SWORD, 'Blood Crystal Axe'],
    [EquipmentName.STEEL_AXE, 'Steel axe'],
    // [EquipmentName.STEEL_POLEAXE, 'Blood Crystal Axe'],
])

/**
 * максимально возможное значение, чтобы надеть предметы
 */
export const EquipmentTypeMaxCount = {
    weapons: 2,
    accessories: 3,
}

/**
 * алгоритм экипировки будет учитывать по типу, а не по классу
 */
export const EquipmentsThatMustCheckAsType = [EquipmentClass.ARMOR]

export const stateEquipmentMap = new Map<
    string,
    {
        label: string
        icon: string
        name: string
    }
>([
    [
        'vitality',
        {
            name: 'vitality',
            label: 'Vitality',
            icon: images.icon_min_health,
        },
    ],
    [
        'physicalDefense',
        {
            name: 'physicalDefense',
            label: 'Phy. Protection',
            icon: images.icon_min_magic,
        },
    ],
    [
        'power',
        {
            name: 'power',
            label: 'Power',
            icon: images.icon_min_blue_circe,
        },
    ],
    [
        'durability',
        {
            name: 'durability',
            label: 'Durability',
            icon: images.icon_min_blue_circe,
        },
    ],
    [
        'agility',
        {
            name: 'agility',
            label: 'Agility',
            icon: images.icon_min_blue_circe,
        },
    ],
    [
        'magicDefense',
        {
            name: 'magicDefense',
            label: 'mag. Defense',
            icon: images.icon_min_blue_circe,
        },
    ],
    [
        'speed',
        {
            name: 'speed',
            label: 'Speed',
            icon: images.icon_min_blue_circe,
        },
    ],
    [
        'luck',
        {
            name: 'luck',
            label: 'Fortuna',
            icon: images.icon_min_clever,
        },
    ],
    [
        'minDamage',
        {
            name: 'minDamage',
            label: 'Min. Damage',
            icon: images.icon_min_clever,
        },
    ],
    [
        'maxDamage',
        {
            name: 'maxDamage',
            label: 'Max. Damage',
            icon: images.icon_min_clever,
        },
    ],
    [
        'sharpening',
        {
            name: 'sharpening',
            label: 'Sharpening',
            icon: images.icon_min_clever,
        },
    ],
    [
        'criticalDamage',
        {
            name: 'criticalDamage',
            label: 'Crit. Damage',
            icon: images.icon_min_clever,
        },
    ],
    [
        'criticalStrikeChance',
        {
            name: 'criticalStrikeChance',
            label: 'Crit. Chance',
            icon: images.icon_min_clever,
        },
    ],
])

export const heroListEquipments: [EquipmentBodyPart, string][] = [
    [EquipmentBodyPart.WEAPON, images.weapon_eq],
    [EquipmentBodyPart.WEAPON, images.weapon_eq],
    [EquipmentBodyPart.HEAD, images.ring_eq],
    [EquipmentBodyPart.BODY, images.shield_eq],
    [EquipmentBodyPart.LEGS, images.accessory_eq],
    [EquipmentBodyPart.FOOTS, images.accessory_eq],
    [EquipmentBodyPart.HANDS, images.accessory_eq],
]
