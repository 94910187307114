import { images } from '../Assets'
import {
    UiNinePassTexture,
    UINinePassTextureConfig,
    UiThreePassTexture,
    UIThreePassTextureConfig,
} from '../Types/UiTexture'

export const ninePassTexturesMap = {
    button_green: new Map([
        [UiNinePassTexture.TOP_LEFT, images.ui_button_green_topleft],
        [UiNinePassTexture.TOP, images.ui_button_green_top],
        [UiNinePassTexture.TOP_RIGHT, images.ui_button_green_topright],
        [UiNinePassTexture.CENTER_LEFT, images.ui_button_green_centerleft],
        [UiNinePassTexture.CENTER, images.ui_button_green_center],
        [UiNinePassTexture.CENTER_RIGHT, images.ui_button_green_centerright],
        [UiNinePassTexture.BOTTOM_LEFT, images.ui_button_green_bottomleft],
        [UiNinePassTexture.BOTTOM, images.ui_button_green_bottom],
        [UiNinePassTexture.BOTTOM_RIGHT, images.ui_button_green_bottomright],
    ]) as Map<UiNinePassTexture, string>,
}
export const threePassTexturesMap: Record<
    string,
    Map<UiThreePassTexture, string>
> = {
    progress_bar: new Map([
        [UiThreePassTexture.LEFT, images.ui_progress_bar_left],
        [UiThreePassTexture.CENTER, images.ui_progress_bar_center],
        [UiThreePassTexture.RIGHT, images.ui_progress_bar_right],
    ]),
    bar: new Map([
        [UiThreePassTexture.LEFT, images.ui_bar_left],
        [UiThreePassTexture.CENTER, images.ui_bar_center],
        [UiThreePassTexture.RIGHT, images.ui_bar_right],
    ]),
}

export type NinePassTextureTypes = 'green_button'
export type ThreePassTextureTypes = 'progress_bar' | 'bar'

export const texturesSizesConf = {
    button: {
        topCornerProportions: '32%',
        bottomCornerProportions: '36%',
    },
    progress_bar: {
        width: '100%',
    },
}

export const ninePassTextureConfs: Record<
    NinePassTextureTypes,
    UINinePassTextureConfig
> = {
    green_button: {
        textures: ninePassTexturesMap.button_green,
        ...texturesSizesConf.button,
    },
}

export const threePassTextureConfs: Record<
    ThreePassTextureTypes,
    UIThreePassTextureConfig
> = {
    progress_bar: {
        textures: threePassTexturesMap.progress_bar,
        ...texturesSizesConf.progress_bar,
    },
    bar: {
        textures: threePassTexturesMap.bar,
    },
}
