import _ from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { resourcesMap } from '../Config/Resources'
import { Rarity } from '../Types/Main'
import { RouletteDrop } from '../Types/RouletteDrop'

export function useRouletteDrop(drop?: RouletteDrop) {
    const [t] = useTranslation()

    return useMemo(() => {
        if (!drop) return null
        const name = () => {
            const type = drop.resourceType
            const trans = `name.resource.${type}`
            if (t(trans) === trans) return _.capitalize(_.lowerCase(type))
            return t(trans)
        }
        const img = () => {
            const type = drop.resourceType
            if (type) return resourcesMap.get(type)
        }
        const description = () => {
            const type = drop.resourceType
            return t(`description.resource.${type}`)
        }
        const rarityAsColor = () => {
            const tier = drop.resourceTier
            if (!tier) return Rarity.COMMON
            if (_.isNumber(tier)) {
                if (tier === 1) return Rarity.COMMON
                if (tier === 2) return Rarity.RARE
                if (tier === 3) return Rarity.EPIC
                return
            }
            return tier
        }
        const rarityAsValue = () => {
            const tier = drop.resourceTier
            if (!tier) return
            if (_.isNumber(tier)) return
            return tier
        }
        const tier = () => {
            const tier = drop.resourceTier
            if (!tier) return
            if (_.isNumber(tier)) return tier
        }

        return {
            img: img(),
            name: name(),
            rarityAsColor: rarityAsColor(),
            description: description(),
            rarityAsValue: rarityAsValue(),
            tier: tier(),
        }
    }, [drop, t])
}
