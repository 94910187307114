import { images } from '../Assets'
import { Popup, PopupInner } from '../Components/Popup'
import { Roulette, RouletteSeasonCounter } from '../Components/Roulette'
// import { Sponsor } from '../Components/Sponsor'
import { useTypedSelector } from '../Hooks/useTypedSelector'
import { UserBar } from '../Components/UX/UserBar'
import { useWorkingSocket } from '../Hooks/useSocket'
import { Inventory } from '../Components/Inventory'
import { CastleCardItem, CastleCards } from '../Components/UI/CastleCard'
import { useMemo, useState } from 'react'
import _ from 'lodash'
import { getItemName } from '../Hooks/useItem'
// import { KingTicketNotification } from '../Components/KingTicket'

export const BetaLayout = () => {
    // const sponsor = useTypedSelector((s) => s.sponsor)
    const auth = useTypedSelector((s) => s.auth)
    const player = useTypedSelector((s) => s.player)
    const items = useTypedSelector((s) => s.items)

    const sortedItems = useMemo(() => {
        return _.sortBy(
            items.filter((item) => !item.equipment),
            (item) => getItemName(item)
        )
    }, [items])

    const sortedEquipment = useMemo(() => {
        const sorted = _.sortBy(
            items.filter((item) => item.equipment),
            (item) => item.equipment?.computed?.base?.type
        )
        return _.sortBy(sorted, (item) => item.equipment?.name)
    }, [items])

    // const [sponsorPopup, setSponsorPopup] = useState(false)
    const [roulettePopup, setRoulettePopup] = useState(false)
    const [inventoryPopup, setInventoryPopup] = useState(false)

    useWorkingSocket()

    if (!player || !auth) return null

    return (
        <div className="beta">
            <div className="beta__top">
                <UserBar
                    user={auth}
                    player={player}
                    className="beta__userbar"
                ></UserBar>
                <RouletteSeasonCounter />
            </div>
            <CastleCards>
                {/* <CastleCardItem
                    name="King Ticket"
                    upInfo={!sponsor ? '4.99$' : undefined}
                    bg="kingticket"
                    icon={<img src={images.ui_bg_king_ticket} alt="" />}
                    onClick={() => setSponsorPopup(true)}
                ></CastleCardItem> */}
                <CastleCardItem
                    name="Roulette"
                    icon={<img src={images.icon_roulette} alt="" />}
                    onClick={() => setRoulettePopup(true)}
                    downInfo={
                        <>
                            You have <br />
                            <span>{player.roulette_free_spin}/10 spins</span>
                        </>
                    }
                ></CastleCardItem>
                <CastleCardItem
                    name="Inventory"
                    icon={<img src={images.ui_inventory} alt="" />}
                    onClick={() => setInventoryPopup(true)}
                ></CastleCardItem>
                <CastleCardItem
                    name="Altar of Summon"
                    icon={<img src={images.altar_of_summon_icon} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="Forge"
                    icon={<img src={images.forge_icon} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="Auction"
                    icon={<img src={images.auction_icon} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="Jeweler's House"
                    icon={<img src={images.jeweler_house_icon} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="Hall of Keys"
                    icon={<img src={images.ui_hall_of_keys} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="The Magician's Hut"
                    icon={<img src={images.magician_hut_icon} alt="" />}
                    disabled
                ></CastleCardItem>
                <CastleCardItem
                    name="The Alchemist's Hut"
                    icon={<img src={images.alchemist_hut_icon} alt="" />}
                    disabled
                ></CastleCardItem>
            </CastleCards>
            {/* <Popup
                show={[sponsorPopup, setSponsorPopup]}
                title="King Ticket"
                showHeader={!sponsor}
            >
                {!!sponsor ? (
                    <KingTicketNotification
                        onClose={() => setSponsorPopup(false)}
                    />
                ) : (
                    <Sponsor />
                )}
            </Popup> */}
            <Popup
                show={[roulettePopup, setRoulettePopup]}
                background="gold"
                headerPreset="linear"
                title="Roulette"
            >
                <Roulette></Roulette>
            </Popup>
            <Popup
                show={[inventoryPopup, setInventoryPopup]}
                title="Inventory"
                fullTitle
            >
                <PopupInner
                    pages={[
                        {
                            name: 'Resources',
                            component: <Inventory items={sortedItems} />,
                            icon: <img src={images.icon_resources} alt="" />,
                        },
                        {
                            name: 'Equipment',
                            component: <Inventory items={sortedEquipment} />,
                            icon: <img src={images.icon_equipments} alt="" />,
                        },
                    ]}
                ></PopupInner>
            </Popup>
        </div>
    )
}
