import _ from 'lodash'
import { nSuc } from './notifications'

export function percent(value: number, max: number) {
    return (value / max) * 100
}

/**
 * метод принимает массив и отдает рандомное значение из этого массива
 * @param arr массив значений
 * @returns значение
 */
export function getRandomValueFromArray<T>(arr: T[]): T {
    return arr[_.random(0, arr.length - 1)]
}

export const copy = (text: string) => {
    const i = document.createElement('input')
    i.value = text
    document.body.append(i)
    i.select()
    document.execCommand('copy')
    i.remove()
    nSuc('Copied')
}

export const isMainnet = process.env.REACT_APP_BLOCKCHAIN_MAINNET === 'true'
