import { FC, useCallback, useMemo, useState } from 'react'
import { Field } from '../../../../Types/Field'
import { State } from '../../../../Types/Main'
import { useTypedSelector } from '../../../../Hooks/useTypedSelector'
import { useSwitch } from '../../../../Hooks/useSwitch'
import _ from 'lodash'
import { HeroesList } from '../../../Heroes/Heroes'
import { RawHeroAvatar } from '../../../Heroes/HeroMeta/Avatar'
import { Hero } from '../../../../Types/Hero'
import { HeroMeta } from '../../../Heroes/HeroMeta/Meta'

export const QuestFieldDetachment: FC<{
    field: Field
    selectedHeroesIdState: State<string[]>
}> = ({ field, selectedHeroesIdState }) => {
    const [selectedHeroesId] = selectedHeroesIdState
    const rawSwitch = useSwitch(selectedHeroesIdState)
    const maxHeroes = useMemo(() => field.questEnemies.length, [field])
    const heroes = useTypedSelector((s) => s.heroes)
    const switchHeroes = useCallback(
        (heroId) => {
            if (selectedHeroesId.length >= maxHeroes) {
                return
            }
            rawSwitch(heroId)
        },
        [selectedHeroesId, maxHeroes, rawSwitch]
    )

    return (
        <div className="quest">
            <div className="quest__body">
                <HeroesList
                    heroes={heroes}
                    onClick={switchHeroes}
                    selectedId={selectedHeroesId}
                ></HeroesList>
                <div className="quest__half gradient bigger indent">
                    <div className="quest__title gradient">Opponents</div>
                    <SelectedHeroSlots
                        maxHeroes={maxHeroes}
                        heroes={heroes}
                        selectedHeroesId={selectedHeroesId}
                        heroClick={rawSwitch}
                    />
                </div>
            </div>
        </div>
    )
}

const SelectedHeroSlots: FC<{
    maxHeroes: number
    heroes: Hero[]
    selectedHeroesId: string[]
    heroClick: (id: string) => void
}> = ({ maxHeroes, heroes, selectedHeroesId, heroClick }) => {
    const selectedHeroes = useMemo(
        () => heroes.filter((hero) => _.includes(selectedHeroesId, hero.id)),
        [heroes, selectedHeroesId]
    )
    return (
        <div className="quest__heroes">
            {Array(maxHeroes)
                .fill('')
                .map((_, i) => (
                    <HeroSlot
                        key={i}
                        hero={selectedHeroes[i]}
                        heroClick={heroClick}
                    />
                ))}
        </div>
    )
}

const HeroSlot: FC<{ hero: Hero; heroClick: (id: string) => void }> = ({
    hero,
    heroClick,
}) => {
    const [showMeta, setShowMeta] = useState(false)
    return (
        <div
            className="quest__hero"
            onMouseEnter={() => setShowMeta(true)}
            onMouseOut={() => setShowMeta(false)}
            onClick={() => heroClick(hero.id)}
        >
            <RawHeroAvatar
                heroClass={hero?.class}
                showDelete={hero?.class && true}
            />
            {showMeta && hero && (
                <div>
                    <HeroMeta hero={hero}></HeroMeta>
                </div>
            )}
        </div>
    )
}
