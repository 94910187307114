import { FC } from 'react'
import { useItem } from '../../../../../Hooks/useItem'
import { LootboxImage } from './helpers/LootboxImage'
import { OpenResult } from '../../../../../Types/Lootbox'
import { Button } from '../../../../UI/Button'

export const LootboxRarityUp: FC<{
    resultOpen: OpenResult
    previousRarity?: string
    closeLootboxResult: () => void
}> = ({ resultOpen, previousRarity, closeLootboxResult }) => {
    const { img, info } = useItem(resultOpen.lootbox)
    return (
        <>
            <div className="lootbox-info center">
                <div className="lootbox-info__header">
                    <p>Редкость сундука повысилась!</p>
                </div>
                <div className="lootbox-rarity-up">
                    <LootboxImage img={img} />
                    <p className="lootbox-rarity-up__text-rarity">
                        {previousRarity} {'>'} {info.name}
                    </p>
                </div>
            </div>
            <div className="lootbox__buttons">
                <Button onClick={closeLootboxResult}>OK</Button>
            </div>
        </>
    )
}
