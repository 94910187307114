import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useTypedSelector } from '../Hooks/useTypedSelector'
import { web3Instance } from '../Common/blockchain/web3'
import { useSocket } from '../Hooks/useSocket'
import { RouletteDrop } from '../Types/RouletteDrop'
import { ConnectWalletButton } from './UI/Button'
import { useRouletteDrop } from '../Hooks/useRouletteDrop'
import moment from 'moment'
import { RouletteConfig } from '../Config/Roulette'
import ms from 'ms'
import { useInit } from '../Hooks/useInit'
import { useTranslation } from 'react-i18next'
import { useAnime } from '../Hooks/useAnime'
import { store } from '../Store'
import { Player } from '../Types/Player'
import { RouletteSeason } from '../Types/RouletteSeason'
import { nErr } from '../Common/notifications'
import { useInterval } from '../Hooks/useInterval'
import { ItemTooltip } from './Item'
import { Rarity } from '../Types/Main'

export const Roulette = () => {
    const season = useTypedSelector((s) => s.rouletteSeason)
    const rouletteDrops = useTypedSelector((s) => s.rouletteDrops)
    const player = useTypedSelector((s) => s.player)

    const { animate: spinAnimate } = useAnime()
    const { animate: glowAnimate } = useAnime()

    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rotation, setRotation] = useState(0)
    const [glow, setGlow] = useState(0)
    const [nextRestoration, setNextRestoration] = useState<string | null>(null)
    const auth = useTypedSelector((s) => s.auth)
    const sponsor = useTypedSelector((s) => s.sponsor)
    const init = useInit()

    const nextRestorationSpinSet = useCallback(() => {
        if (!player || player.roulette_free_spin > 0)
            return setNextRestoration(null)

        const sponsorSpin = !sponsor
            ? 0
            : RouletteConfig.sponsor_additional_spin
        const maxSpinPerDay = RouletteConfig.max_spin_per_day + sponsorSpin
        const cooldown = 24 / maxSpinPerDay
        const next = moment(player.roulette_last_restored_spin).add(
            cooldown,
            'hours'
        )
        const diff = next.diff(moment())

        if (diff <= 0) return setNextRestoration('Loading...')

        const duration = moment.duration(diff)
        const norm = (val: number) => `${val >= 10 ? val : `0${val}`}`
        const hours = duration.get('hour')
        const minutes = duration.get('minute')
        const seconds = duration.get('seconds')

        setNextRestoration(`${hours}:${norm(minutes)}:${norm(seconds)}`)
    }, [sponsor, player])

    useInterval(nextRestorationSpinSet, ms('1s'), true)

    const mapDrops = useCallback(
        (drops: RouletteDrop[], player: Player, season: RouletteSeason) => {
            return (
                drops
                    .filter(
                        (drop) =>
                            drop.kingLevel === player?.kingLevel &&
                            drop.rouletteSeason === season?.name &&
                            drop.week === season.week
                    )
                    .map((drop) => ({
                        id: drop.id,
                        angle: drop.seed * 45,
                        drop,
                    })) || []
            )
        },
        []
    )

    const drops = useMemo(() => {
        if (!player) return []
        if (!season) return []
        return mapDrops(rouletteDrops, player, season)
    }, [rouletteDrops, player, season, mapDrops])
    const socket = useSocket()

    const startBlockchain = useCallback(async () => {
        setGlow(0)
        setDisabled(true)
        setLoading(true)
        try {
            const web3 = await (await web3Instance)()
            await web3.spinRoulette()
        } catch (error) {
            console.error(error)
            setDisabled(false)
            setLoading(false)
        }
    }, [])

    const startSpin = useCallback(
        async (rawDrop: RouletteDrop) => {
            const getDrop = () => {
                const storeDrops = store.getState().rouletteDrops
                const player = store.getState().player
                const season = store.getState().rouletteSeason
                if (!storeDrops) return
                if (!player) return
                if (!season) return
                const drops = mapDrops(storeDrops, player, season)
                return drops.find((drop) => drop.id === rawDrop.id)
            }

            let drop = getDrop()

            if (!drop) {
                await init()
                drop = getDrop()
            }

            if (!drop) return nErr('Not found reward')

            const deg = drop.angle

            const values = { deg: -5000, glow: 0 }
            await spinAnimate({
                targets: values,
                deg: -deg,
                easing: 'easeOutCirc',
                duration: 10000,
                update() {
                    setRotation(values.deg)
                },
            })

            setDisabled(false)

            await glowAnimate({
                targets: values,
                glow: 14,
                loop: 20,
                easing: 'linear',
                duration: 500,
                direction: 'alternate',
                update() {
                    setGlow(values.glow)
                },
            })
            setGlow(0)
        },
        [spinAnimate, init, mapDrops, glowAnimate]
    )

    useEffect(() => {
        socket?.on('update-user', async (msg) => {
            const { key, data } = msg

            if (key !== 'roulette') return
            setLoading(false)
            startSpin(data)
        })
    }, [socket, startSpin])

    if (!season) return null

    return (
        <div className="roulette-container">
            <div className="roulette-msg">
                {nextRestoration ? (
                    <>
                        Next spin <br />
                        <span>{nextRestoration}</span>
                    </>
                ) : (
                    <>
                        You have <br />
                        <span>{player?.roulette_free_spin || 0} spin</span>
                    </>
                )}
            </div>

            <div className="roulette">
                <div className="roulette__inner">
                    <div
                        className="roulette__result"
                        style={{
                            filter: `drop-shadow(0px 0px ${glow}px #FFE4A0)`,
                        }}
                    ></div>
                    <button
                        className={classNames('roulette__btn')}
                        disabled={
                            disabled ||
                            (player?.roulette_free_spin || 0) <= 0 ||
                            !auth?.eth_address
                        }
                        onClick={startBlockchain}
                    >
                        <span>{loading ? 'LOADING' : 'SPIN'}</span>
                    </button>
                    {/* <div className="roulette__triangle"></div> */}
                    <div
                        className="roulette__body"
                        style={{
                            transform: `rotate(${rotation}deg)`,
                        }}
                    >
                        <div className="roulette__items">
                            {drops.map((drop) => (
                                <RouletteItem
                                    angle={drop.angle}
                                    drop={drop.drop}
                                    key={drop.id}
                                ></RouletteItem>
                            ))}
                        </div>
                    </div>
                    {!auth?.eth_address && (
                        <div className="roulette-block">
                            <ConnectWalletButton />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const RouletteItem: FC<{ angle: number; drop: RouletteDrop }> = ({
    angle,
    drop,
}) => {
    const meta = useRouletteDrop(drop)
    return (
        <>
            <ItemTooltip
                id={drop.id}
                prefix="roulette-item"
                name={meta?.name}
                rarityColor={meta?.rarityAsColor}
                tier={drop.resourceTier || 0}
                description={meta?.description}
            >
                <div
                    className="roulette-item"
                    style={{
                        transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                    }}
                >
                    <div
                        className={classNames(
                            'roulette-item__inner',
                            `roulette-item-resource__${
                                meta?.rarityAsColor?.toLowerCase() ||
                                Rarity.COMMON
                            }`
                        )}
                    >
                        <div className="roulette__count">
                            {drop.resourceCount}
                        </div>
                        <div className="roulette__img">
                            <img src={meta?.img} alt="" />
                        </div>
                    </div>
                </div>
            </ItemTooltip>
        </>
    )
}

export const RouletteSeasonCounter: FC = () => {
    const season = useTypedSelector((s) => s.rouletteSeason)
    const [time, setTime] = useState<null | string>(null)
    const init = useInit()
    const { t } = useTranslation()

    const computeTime = useCallback(() => {
        const end = moment(season?.endDate)
        const now = moment()
        const diff = end.diff(now)
        if (diff <= 0) return init()

        const duration = moment.duration(diff)
        const hours = duration.get('hours')
        const days = duration.get('days')
        const minutes = duration.get('minutes')
        setTime(`${days}d ${hours}h ${minutes}m`)
    }, [init, season?.endDate])

    useInterval(computeTime, ms('10s'), true)

    if (!season) return null

    return (
        <div className="roulette-season">
            <div className="roulette-season__name">
                {t('Season')} {season.name} {t('Week')} {season.week}
            </div>
            <div className="roulette-season__counter">
                {t('End of season in:')} <span>{time}</span>
            </div>
        </div>
    )
}
