import { FC } from 'react'
import { State } from '../../Types/Main'

export const Input: FC<{
    state: State<string>
    placeholder?: string
    type?: React.HTMLInputTypeAttribute
    disabled?: boolean
    name?: string
}> = ({ state, placeholder, type, disabled, name }) => {
    const [val, setVal] = state
    const inputProps = { placeholder, disabled }
    return (
        <div className="input">
            <input
                name={name}
                type={type || 'text'}
                {...inputProps}
                value={val}
                onChange={(e) => setVal(e.target.value)}
            />
        </div>
    )
}
