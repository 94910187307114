import { Redirect } from 'react-router-dom'
import { useQuery } from '../../Hooks/useQuery'
import { routes } from '../../Router/RoutesName'

export const Invite = () => {
    const q = useQuery()
    return (
        <Redirect
            to={{ pathname: routes.auth.register, state: { ref: q.ref } }}
        ></Redirect>
    )
}
