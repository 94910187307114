import { images } from '../Assets'
import { HeroClass } from '../Types/Hero'

// TODO vitality not found image
// TODO magicDefense not found image
// TODO agility not found

export interface StateHeroMap {
    label: string
    icon: string
    name: string
}

export const stateHeroMap: Map<string, StateHeroMap> = new Map([
    [
        'vitality',
        {
            label: 'Vitality',
            icon: images.icon_min_health,
            name: 'vitality',
        },
    ],
    [
        'current_hp',
        {
            label: 'Health',
            icon: images.icon_min_health,
            name: 'current_hp',
        },
    ],
    [
        'evasionChance',
        {
            label: 'Evasion Chance',
            icon: images.icon_min_axe,
            name: 'evasionChance',
        },
    ],
    [
        'luck',
        {
            label: 'Fortuna',
            icon: images.icon_min_clever,
            name: 'luck',
        },
    ],
    [
        'physicalDefense',
        {
            label: 'Phy. Protection',
            icon: images.icon_min_magic,
            name: 'physicalDefense',
        },
    ],
    [
        'magicalDefense',
        {
            label: 'Mag. Protection',
            icon: images.icon_min_hat,
            name: 'magicalDefense',
        },
    ],
    [
        'criticalStrikeChance',
        {
            label: 'Crit. Chance',
            icon: images.icon_min_blue_circe,
            name: 'criticalStrikeChance',
        },
    ],
    [
        'critDamage',
        {
            label: 'Crit. Damage',
            icon: images.icon_min_blue_circe,
            name: 'critDamage',
        },
    ],
    [
        'damage',
        {
            label: 'Damage',
            icon: images.icon_min_blue_circe,
            name: 'damage',
        },
    ],
    [
        'minDamage',
        {
            label: 'Min. Damage',
            icon: images.icon_min_blue_circe,
            name: 'minDamage',
        },
    ],
    [
        'maxDamage',
        {
            label: 'Max. Damage',
            icon: images.icon_min_blue_circe,
            name: 'maxDamage',
        },
    ],
    [
        'agility',
        {
            label: 'Agility',
            icon: images.icon_min_blue_circe,
            name: 'agility',
        },
    ],
    [
        'speed',
        {
            label: 'Speed',
            icon: images.icon_min_blue_circe,
            name: 'speed',
        },
    ],
    [
        'durability',
        {
            label: 'Durability',
            icon: images.icon_min_blue_circe,
            name: 'durability',
        },
    ],
    [
        'power',
        {
            label: 'Power',
            icon: images.icon_min_blue_circe,
            name: 'power',
        },
    ],
])

export const heroDescriptionMap = new Map([
    [
        HeroClass.BANDIT,
        {
            text: 'Бандиты - профессиональные бойцы, их сила, агрессия и бесстрашие позволяют им добиваться своих целей. И хотя некоторые герои посмеиваются над их медлительностью, они отлично развиты физически, что всегда дает им преимущество перед другими в силе и умении защищаться.',
            image: images.hero_bandit_rare,
        },
    ],
    [
        HeroClass.MAGE,
        {
            text: 'Маги - профессиональные бойцы, их сила, агрессия и бесстрашие позволяют им добиваться своих целей. И хотя некоторые герои посмеиваются над их медлительностью, они отлично развиты физически, что всегда дает им преимущество перед другими в силе и умении защищаться.',
            image: images.hero_mage_rare,
        },
    ],
    [
        HeroClass.PALADIN,
        {
            text: 'Паладины - профессиональные бойцы, их сила, агрессия и бесстрашие позволяют им добиваться своих целей. И хотя некоторые герои посмеиваются над их медлительностью, они отлично развиты физически, что всегда дает им преимущество перед другими в силе и умении защищаться.',
            image: images.hero_paladin_rare,
        },
    ],
    [
        HeroClass.WARRIOR,
        {
            text: 'Воины - профессиональные бойцы, их сила, агрессия и бесстрашие позволяют им добиваться своих целей. И хотя некоторые герои посмеиваются над их медлительностью, они отлично развиты физически, что всегда дает им преимущество перед другими в силе и умении защищаться.',
            image: images.hero_warrior_rare,
        },
    ],
])
