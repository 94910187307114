import { FC, ReactNode } from 'react'
import { CastleCardItem, CastleCards } from '../../../UI/CastleCard'

type itemCastle = {
    icon: ReactNode
    name: string
}

export const NavCastle: FC<{
    setCastleItem: (val: string) => void
    items: itemCastle[]
}> = ({ setCastleItem, items }) => {
    return (
        <CastleCards>
            {items.map((item) => (
                <CastleCardItem
                    name={item.name}
                    icon={item.icon}
                    onClick={setCastleItem}
                    key={item.name}
                ></CastleCardItem>
            ))}
        </CastleCards>
    )
}
