import { ComponentType } from 'react'
import { Item } from '../../../../Types/Item'
import { SetStateAction } from '../../../../Types/Main'

interface Props<T> {
    items: Item[][]
    setSelectedItem: SetStateAction<T | undefined>
    Child: ComponentType<{
        items: Item[]
        setSelectedItem: SetStateAction<T | undefined>
    }>
}

export const CastleItemList = <T,>({
    items,
    setSelectedItem,
    Child,
}: Props<T>) => {
    return (
        <div className="castle-slider">
            <div className="castle-slider__list">
                {items.map((groupItems, id) => (
                    <Child
                        items={groupItems}
                        setSelectedItem={setSelectedItem}
                        key={id}
                    />
                ))}
            </div>
        </div>
    )
}
