import { Hero } from '../Types/Hero'
import { Item } from '../Types/Item'
import { Mission } from '../Types/Mission'
import { Player } from '../Types/Player'
import { Quest } from '../Types/Quest'
import { RouletteDrop } from '../Types/RouletteDrop'
import { RouletteSeason } from '../Types/RouletteSeason'
import { RouletteSponsor } from '../Types/RouletteSponsor'
import { User } from '../Types/User'
import { createStoreState } from './utils'

const auth = createStoreState<User | null>('auth/set', null)
const referrals = createStoreState<User[]>('refs/set', [])
const player = createStoreState<Player | null>('player/set', null)
const heroes = createStoreState<Hero[]>('heroes/set', [])
const items = createStoreState<Item[]>('items/set', [])
const debug = createStoreState('debug/set', false)
const quest = createStoreState<Quest[]>('quest/set', [])
const mission = createStoreState<Mission[]>('missions/set', [])
const sponsor = createStoreState<RouletteSponsor | null>(
    'roulette/sponsor',
    null
)
const rouletteSeason = createStoreState<RouletteSeason | null>(
    'roulette/season',
    null
)

const rouletteDrops = createStoreState<RouletteDrop[]>('roulette/drops', [])

const kingLevelsConfig = createStoreState<[number, number][]>(
    'config/king-levels',
    []
)

const playerLevelsConfig = createStoreState<[number, string][]>(
    'config/player-levels',
    []
)

const varSponsorLoading = createStoreState<boolean>(
    'var/sponsor-loading',
    false
)

const varSponsorDisabled = createStoreState<boolean>(
    'var/sponsor-disabled',
    false
)

export const actions = {
    auth: auth.action,
    player: player.action,
    heroes: heroes.action,
    items: items.action,
    debug: debug.action,
    quest: quest.action,
    mission: mission.action,
    sponsor: sponsor.action,
    referrals: referrals.action,
    rouletteSeason: rouletteSeason.action,
    kingLevelsConfig: kingLevelsConfig.action,
    playerLevelsConfig: playerLevelsConfig.action,
    rouletteDrops: rouletteDrops.action,
    varSponsorLoading: varSponsorLoading.action,
    varSponsorDisabled: varSponsorDisabled.action,
}

export const reducers = {
    auth: auth.reducer,
    player: player.reducer,
    heroes: heroes.reducer,
    items: items.reducer,
    debug: debug.reducer,
    quest: quest.reducer,
    missions: mission.reducer,
    sponsor: sponsor.reducer,
    referrals: referrals.reducer,
    rouletteSeason: rouletteSeason.reducer,
    kingLevelsConfig: kingLevelsConfig.reducer,
    playerLevelsConfig: playerLevelsConfig.reducer,
    rouletteDrops: rouletteDrops.reducer,
    varSponsorLoading: varSponsorLoading.reducer,
    varSponsorDisabled: varSponsorDisabled.reducer,
}
