import { FC, useMemo, useState } from 'react'
import { useTypedSelector } from '../../../../../Hooks/useTypedSelector'
import { ItemLootbox } from './ItemLootbox'
import { Item } from '../../../../../Types/Item'
import { CastleItemList } from '../CastleItemList'
import { LootboxListItem } from './helpers'
import { Lootbox } from '../../../../../Types/Lootbox'

export const HallOfKeys: FC = () => {
    const items = useTypedSelector((s) => s.items)
    const selectedItemState = useState<Lootbox | undefined>()
    const [selectedLootbox, setSelectedLootbox] = selectedItemState

    const lootboxes = useMemo(() => {
        const groupedLootboxes = items.reduce((group, item) => {
            const { lootbox } = item
            if (!lootbox) return group
            const rarityItems = group.get(lootbox.rarity) || []
            return group.set(lootbox.rarity, [...rarityItems, item])
        }, new Map<string, Item[]>())
        return Array.from(groupedLootboxes.values())
    }, [items])

    return (
        <>
            {selectedLootbox ? (
                <ItemLootbox
                    selectedLootbox={selectedLootbox}
                    setSelectedLootbox={setSelectedLootbox}
                />
            ) : (
                <CastleItemList
                    Child={LootboxListItem}
                    items={lootboxes}
                    setSelectedItem={setSelectedLootbox}
                />
            )}
        </>
    )
}
