import classNames from 'classnames'
import { FC, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Loader2 } from '../Loader'
import { images } from '../../Assets'

export const ScreenCard: FC<{
    title: string
    open?: boolean
    onClose?: () => void
    bg?: 'art'
    notification?: string
    loading?: boolean
    logo?: boolean
}> = ({ title, children, open, onClose, bg, notification, loading, logo }) => {
    const [showNotification, setShowNotification] = useState(false)

    const close = useCallback(() => {
        if (onClose) onClose()
    }, [onClose])

    useEffect(() => {
        if (!notification) return
        setShowNotification(true)
        const id = setTimeout(() => setShowNotification(false), 1000)
        return () => clearTimeout(id)
    }, [notification])

    return createPortal(
        <div
            className={classNames('card-screen', { 'card-screen_show': open })}
        >
            {logo && (
                <img
                    src={images.big_logo}
                    className="card-screen__logo"
                    alt="logo"
                ></img>
            )}
            <div
                className={classNames(
                    'card-screen__bg',
                    { 'card-screen__bg-show': open },
                    bg ? `card-screen__bg-${bg}` : ''
                )}
                onClick={close}
            ></div>
            <div
                className={classNames('card-screen__inner', {
                    'card-screen__inner-show': open,
                })}
            >
                {!!notification && (
                    <div
                        className={classNames('card-screen__notification', {
                            'card-screen__notification-active':
                                showNotification,
                        })}
                    >
                        {notification}
                    </div>
                )}
                {loading && <Loader2 className="card-screen__loader"></Loader2>}
                <div className="card-screen__header">
                    <div className="card-screen__title">{title}</div>
                    {!!onClose && (
                        <div
                            className="card-screen__close"
                            onClick={close}
                        ></div>
                    )}
                </div>
                <div className="card-screen__body">{children}</div>
            </div>
        </div>,
        document.getElementById('card')!
    )
}
