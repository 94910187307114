export const blockchainNetworkConf = {
    testnet: {
        chainId: '0xa515',
        chainName: 'Emerald Testnet',
        rpcUrl: ['https://testnet.emerald.oasis.dev/'],
    },
    mainnet: {
        chainId: '0xa516',
        chainName: 'Emerald',
        rpcUrl: ['https://emerald.oasis.dev/'],
    },
}

export const contractAddressConfTestnet = {
    heroMint: '0xCCB07E97e79817BF9eA0712A4Eb3c159AEF3Fe48',
    equipmentMint: '0xE77da975Fb81e7Ae50A89Be981e60d31e4387A29',
    roulette: '0x8231399f3F0cE1860457AdF3134cd77af584348A',
    rouletteMock: '0xC73283205fC76a18383813DE1338f8C15336F86e',
}

export const contractAddressConfMainnet = {
    roulette: '0x3b5f9dF991C4322C150f45487F99fFe8B1A02E2f',
    rouletteMock: '0xE8A638b3B7565Ee7c5eb9755E58552aFc87b94DD',
}
