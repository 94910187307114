import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { authAPI } from '../../Common/Api'
import { web3Instance } from '../../Common/blockchain/web3'
import { NinePassComponent } from './helpers/NinePassComponent'
import { ninePassTexturesMap } from '../../Config/UiTextures'
import { useTypedSelector } from '../../Hooks/useTypedSelector'
import _ from 'lodash'
import { Loader2 } from '../Loader'

export const Button: FC<{
    onClick?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    color?: 'blue' | 'green'
    fill?: boolean
    className?: string
    type?: 'button' | 'submit' | 'reset'
    loading?: boolean
}> = ({ onClick, children, disabled, color, className, type, loading }) => {
    const finalPreset = useMemo(() => {
        if (disabled) return ninePassTexturesMap.button_green
        if (color === 'blue') return ninePassTexturesMap.button_green
        return ninePassTexturesMap.button_green
    }, [color, disabled])

    return (
        <button
            type={type}
            className={classNames('btn', className)}
            disabled={disabled}
            onClick={onClick}
        >
            {loading && <Loader2 className="btn__loader"></Loader2>}
            <NinePassComponent preset={finalPreset}>
                {children}
            </NinePassComponent>
        </button>
    )
}

export const ConnectWalletButton = () => {
    const auth = useTypedSelector((s) => s.auth)

    const sign = useCallback(async () => {
        const web3 = await (await web3Instance)()
        const { sign, msg } = await web3.sign()
        await authAPI.metamask.connect({ sign, msg, address: web3.address })
    }, [])

    const disconnect = useCallback(() => authAPI.metamask.disconnect(), [])

    if (_.isNull(auth)) return null
    if (!_.isNull(auth.eth_address))
        return <Button onClick={disconnect}>Disconnect wallet</Button>
    return <Button onClick={sign}>Connect wallet</Button>
}

// export const TextureButton: FC = () => {
//     return (
//         <div className="btn-texture">
//             <div className="btn-texture__leftbottom"></div>
//             <div className="btn-texture__left"></div>
//             <div className="btn-texture__lefttop"></div>
//             <div className="btn-texture__top"></div>
//             <div className="btn-texture__righttop"></div>
//             <div className="btn-texture__right"></div>
//             <div className="btn-texture__rightbottom"></div>
//             <div className="btn-texture__bottom"></div>
//             <div className="btn-texture__center"></div>
//         </div>
//     )
// }
