import { t } from 'i18next'
import { ResErrors } from '../../Types/App'
import { errors } from '../notifications'

export enum localErrors {
    playerAlreadyPassedLearning = 'playerAlreadyPassedLearning',
    userAlreadyExist = 'userAlreadyExist',
    refUserIsUndefined = 'refUserIsUndefined',
    userIsUndefined = 'userIsUndefined',
    serverError = 'serverError',
    playerIsUndefined = 'playerIsUndefined',
    insufficientImp = 'insufficientImp',
    lootboxIsUndefined = 'lootboxIsUndefined',
    resourceIsUndefined = 'resourceIsUndefined',
    insufficientResourceCount = 'insufficientResourceCount',
    lootboxNotOpenedButUpRarity = 'lootboxNotOpenedButUpRarity',
    equipmentShardsNotCreated = 'equipmentShardsNotCreated',
    lootboxNotOpened = 'lootboxNotOpened',
    rarityIsUndefined = 'rarityIsUndefined',
    duplicateError = 'duplicateError',
    heroEggIsUndefined = 'heroEggIsUndefined',
    itemIsUndefined = 'itemIsUndefined',
    unauthorized = 'unauthorized',
    equipmentIsAlreadyUsing = 'equipmentIsAlreadyUsing',
    equipmentWithThisTypeAlreadyUsing = 'equipmentWithThisTypeAlreadyUsing',
    toolIsUndefined = 'toolIsUndefined',
    recipeIsUndefined = 'recipeIsUndefined',
    sharpeningRecipeIsUndefined = 'sharpeningRecipeIsUndefined',
    reinforcementRecipeIsUndefined = 'reinforcementRecipeIsUndefined',
    weaponIsUndefined = 'weaponIsUndefined',
    equipmentIsUndefined = 'equipmentIsUndefined',
    equipmentIsDifferentClass = 'equipmentIsDifferentClass',
    equipmentDoesntHaveFreeSlots = 'equipmentDoesntHaveFreeSlots',
    notHaveFreeSlots = 'notHaveFreeSlots',
    configIsUndefined = 'configIsUndefined',
    playerAlreadyHasBattlePass = 'playerAlreadyHasBattlePass',
    notFoundHeroes = 'notFoundHeroes',
    rouletteSeasonIsUndefined = 'rouletteSeasonIsUndefined',
    ethAddressIsAlreadyUsing = 'ethAddressIsAlreadyUsing',
    codeIsUndefined = 'codeIsUndefined',
    emailOrPasswordDoesntMatch = 'emailOrPasswordDoesntMatch',
    passwordDoesntMatch = 'passwordDoesntMatch',
}

export function axiosError(err: any) {
    console.log({ err })
    const error = err.response.data as ResErrors
    const status = err.response.status as number
    switch (status) {
        case 500:
            errors.serverError()
            return Promise.reject(err)
        case 400:
            error.errors.forEach((e) =>
                errors.custom(
                    t(`error.${e.msg}`, { param: t(`field.${e.param || ''}`) })
                )
            )
            return Promise.reject(err)
        case 401:
            // jwt.removeToken()
            // nErr('Unauthorized')
            return Promise.reject(err)
        default:
            return Promise.reject(err)
    }
}
