import { images } from '../Assets'
import { FieldObject } from '../Types/Field'
import { useMemo } from 'react'

const questInfo = new Map<
    FieldObject,
    { image: string; name: string; description: string }
>([
    [
        FieldObject.BANDIT_CAMP,
        {
            image: images.object_bandit_camp,
            name: 'Лагерь разбойников',
            description:
                'Лагерь разбойников - пристанище бандитов, воров и убийц. Это их место отдыха в промежутках между преступлениями. Для обычного прохожего - гиблое место, но если ты воин, то можешь рискнуть и изучить, какая добыча тут хранится',
        },
    ],
    [
        FieldObject.ABANDONED_TOWER,
        {
            image: images.object_abandoned_tower,
            name: 'Заброшенная башня',
            description:
                'Издалека видны неровные контуры этого строения. Когда то тут наверняка жил или могущественный маг или какой-то ученый. Но теперь любой охотник за сокровищами и артефактами может встретить тут лишь призраков или себе подобных',
        },
    ],
    [
        FieldObject.RUINS,
        {
            image: images.object_ruins,
            name: 'Руины',
            description:
                'Руины - загадочное место. Никто не знает сколько лет этим полуразрушенным строениям и какой цивилизации они принадлежат. Здесь всегда можно найти, что-то интересное, но нужно быть осторожным - вы тут не одни!',
        },
    ],
    [
        FieldObject.CRYPT,
        {
            image: images.object_crypt,
            name: 'Склеп',
            description:
                'Каждый искатель приключений чувствует холод и дыхание смерти, приближаясь к склепу. После того как артефакт пробудил его обитателей достать оттуда сокровища стало заметно труднее. Никогда не знаешь кого встретишь внутри - такого же искателя приключений или оживших мертвецов',
        },
    ],
])

export const useQuestInfo = (field?: FieldObject) =>
    useMemo(() => field && questInfo.get(field), [field])
