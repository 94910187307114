import { Item } from '../../../../../../Types/Item'
import { useItem } from '../../../../../../Hooks/useItem'
import { FC, useMemo } from 'react'
import { SetStateAction } from '../../../../../../Types/Main'
import _ from 'lodash'
import { HeroEgg } from '../../../../../../Types/HeroEgg'

export const EggListItem: FC<{
    items: Item[]
    setSelectedItem: SetStateAction<HeroEgg | undefined>
}> = ({ items, setSelectedItem }) => {
    const [item] = useMemo(
        () => _.sortBy(items, (item) => item.heroEgg?.rarity),
        [items]
    )
    const { img, info } = useItem(item)
    return (
        <div
            className="castle-slider__item castle-slider__item_position-marginRight items-center"
            onClick={() => setSelectedItem(item.heroEgg)}
        >
            <div className="castle-slider__label">
                <div className="castle-slider__label-text">
                    NPower {item.heroEgg?.n_power}/6
                </div>
            </div>
            <div className="castle-slider__icon">
                <img src={img} alt="" />
            </div>
            <div className="castle-slider__name">
                {info.rarityAsValue} prize
            </div>
            {items.length !== 1 && (
                <p className="castle-slider__lootbox-amount">x{items.length}</p>
            )}
        </div>
    )
}
