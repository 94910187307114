import { FC } from 'react'
import { Item } from '../../../../../Types/Item'
import { OpenResult } from '../../../../../Types/Lootbox'
import { useItem } from '../../../../../Hooks/useItem'
import classNames from 'classnames'
import { Button } from '../../../../UI/Button'

export const LootboxOpen: FC<{
    resultOpen: OpenResult
    closeLootboxResult: () => void
}> = ({ resultOpen, closeLootboxResult }) => {
    return (
        <>
            <div className="lootbox-info center">
                <div className="lootbox-info__header">
                    <p>Поздравляем! Вы открыли сундук!</p>
                </div>
                <div className="lootbox-opened__list">
                    {resultOpen.items.map((item, i) => (
                        <OpenedItem key={i} item={item} />
                    ))}
                </div>
            </div>
            <div className="lootbox__buttons">
                <Button onClick={closeLootboxResult}>Забрать</Button>
            </div>
        </>
    )
}

const OpenedItem: FC<{
    item: Item
}> = ({ item }) => {
    const { img, info } = useItem(item)
    return (
        <>
            <div className={classNames('opened-item')}>
                <div className="opened-item__icon">
                    <img src={img} alt="" />
                </div>
                {info.count > 0 && (
                    <span className="opened-item__count">{info.count}</span>
                )}
            </div>
        </>
    )
}
