import Web3 from 'web3'
import HeroMintAbi from './abi/HeroMint.json'
import EquipmentMintAbi from './abi/EquipmentMint.json'
import RouletteAbi from './abi/Roulette.json'
import MockAbi from './abi/Mock.json'
import {
    blockchainNetworkConf,
    contractAddressConfMainnet,
    contractAddressConfTestnet,
} from './conf'
import _ from 'lodash'
import { nErr } from '../notifications'
import { t } from 'i18next'
import { store } from '../../Store'
import { isMainnet } from '../utils'

export function createWeb3() {
    const metamask = window.ethereum
    if (!metamask) {
        nErr(t('Not found Metamask'))
        return Promise.reject('metamask is undefined')
    }
    const conf = isMainnet
        ? blockchainNetworkConf.mainnet
        : blockchainNetworkConf.testnet

    const contracts = {
        roulette: isMainnet
            ? contractAddressConfMainnet.roulette
            : contractAddressConfTestnet.roulette,
        rouletteMock: isMainnet
            ? contractAddressConfMainnet.rouletteMock
            : contractAddressConfTestnet.rouletteMock,
    }

    const web3 = new Web3(conf.rpcUrl[0])

    return async () => {
        const accounts = metamask.request({ method: 'eth_requestAccounts' })
        const [account]: string[] = await accounts
        web3.setProvider(metamask)
        web3.defaultAccount = account
        const appAddress = store.getState().auth?.eth_address?.toLowerCase()

        if (!!appAddress && account.toLowerCase() !== appAddress) {
            nErr(
                t(
                    `Your game account and Metamask account has difference addresses. Your game address is {{address}}`,
                    { address: appAddress }
                )
            )
            return Promise.reject('metamask is undefined')
        }

        await ethereum
            .request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: conf.chainId }],
            })
            .catch(async (switchError: any) => {
                if (switchError.code !== 4902) return

                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [conf],
                })
            })

        return {
            web3,
            address: account,
            mintHero: async (heroId: string): Promise<string> => {
                const methods = new web3.eth.Contract(
                    HeroMintAbi as any,
                    contractAddressConfTestnet.heroMint
                ).methods

                const tokenId = await methods.nextTokenId().call()
                await methods.mint(account, +heroId).send({ from: account })
                return tokenId
            },
            mintEquipment: async (equipmentId: string): Promise<string> => {
                const methods = new web3.eth.Contract(
                    EquipmentMintAbi as any,
                    contractAddressConfTestnet.equipmentMint
                ).methods

                const tokenId = await methods.nextTokenId().call()
                await methods
                    .mint(account, +equipmentId)
                    .send({ from: account })
                return tokenId
            },
            spinRoulette: async () => {
                const methods = new web3.eth.Contract(
                    RouletteAbi as any,
                    contracts.roulette
                ).methods

                await methods.spin().send({ from: account })
            },
            buyTicket: async () => {
                const methods = new web3.eth.Contract(
                    RouletteAbi as any,
                    contracts.roulette
                ).methods

                const mockMethods = new web3.eth.Contract(
                    MockAbi as any,
                    contracts.rouletteMock
                ).methods

                const price = await methods.ticketPrice().call()

                if (!isMainnet) {
                    await mockMethods
                        .safeMint(account, price)
                        .send({ from: account })
                }

                await mockMethods
                    .approve(contracts.roulette, price)
                    .send({ from: account })

                await methods.buyTicket(1).send({ from: account })
            },
            sign: async (mes?: string) => {
                const msg =
                    mes ||
                    `address: ${account}\n nonce: ${_.random(100000, 999999)}`
                const sign = await web3.eth.personal.sign(msg, account, '')
                return { msg, sign }
            },
        }
    }
}

export const web3Instance = createWeb3()
