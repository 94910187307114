import { FC } from 'react'
import { Field } from '../../../../Types/Field'
import { RawHeroAvatar } from '../../../Heroes/HeroMeta/Avatar'
import { QuestRewardItems } from './QuestRewardItems'
import { images } from '../../../../Assets'
import { useQuestInfo } from '../../../../Hooks/useQuestInfo'

export const QuestFieldQuest: FC<{ field: Field }> = ({ field }) => {
    const questInfo = useQuestInfo(field.object)
    return (
        <div className="quest">
            <div className="quest__head">
                <div className="head__half">
                    <div className="head__title">
                        <div className="title-difficulty">
                            <div className="difficulty-level">
                                {field.questLevel}
                            </div>
                            LVL
                        </div>
                        <div className="title-name adjust-left">
                            {questInfo?.name}
                        </div>
                    </div>
                </div>
                <div className="head__half">
                    {/*<div className="head__badge adjust-left">*/}
                    {/*  <img src={images.ui_icon_difficulty} alt="" className="badge" />*/}
                    {/*  <span className="badge-title">Тяжело</span>*/}
                    {/*</div>*/}

                    <div className="head__badge badge-small adjust-left">
                        <img
                            src={images.ui_icon_time}
                            alt=""
                            className="badge"
                        />
                        <span className="badge-title">
                            {field.questComputed?.questTime} minutes
                        </span>
                    </div>
                </div>
            </div>
            <div className="quest__body">
                <div className="quest__half gradient border-right">
                    <div className="quest__title gradient">Rewards</div>
                    <div className="quest__body">
                        <QuestRewardItems field={field}></QuestRewardItems>
                    </div>
                </div>
                <div className="quest__half gradient">
                    <div className="quest__title gradient">Enemies</div>
                    <div className="quest__body">
                        <div className="quest__heroes">
                            {field.questEnemies.map(([heroClass], i) => (
                                <div className="quest__hero" key={i}>
                                    <RawHeroAvatar
                                        heroClass={heroClass}
                                    ></RawHeroAvatar>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
