import { FC } from 'react'
import { Redirect } from 'react-router'
import { useTypedSelector } from '../../Hooks/useTypedSelector'
import { routes } from '../RoutesName'

export const AuthMiddleware: FC = ({ children }) => {
    const auth = useTypedSelector((s) => s.auth)

    return <>{auth ? children : <Redirect to={routes.auth.login}></Redirect>}</>
}
