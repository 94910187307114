const name = 'token'
export const jwt = {
    setToken(token: string, remember?: boolean) {
        if (remember) return localStorage.setItem(name, token)
        return sessionStorage.setItem(name, token)
    },
    removeToken() {
        sessionStorage.removeItem(name)
        localStorage.removeItem(name)
    },
    getToken() {
        return sessionStorage.getItem(name) || localStorage.getItem(name)
    },
}
