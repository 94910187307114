import { FC, useMemo } from 'react'
import { SetStateAction } from '../../../../../../Types/Main'
import { useItem } from '../../../../../../Hooks/useItem'
import classNames from 'classnames'
import { Item } from '../../../../../../Types/Item'
import _ from 'lodash'
import { Lootbox } from '../../../../../../Types/Lootbox'

export const LootboxListItem: FC<{
    items: Item[]
    setSelectedItem: SetStateAction<Lootbox | undefined>
}> = ({ items, setSelectedItem }) => {
    const [item] = useMemo(
        () => _.sortBy(items, (item) => item.lootbox?.n_power).reverse(),
        [items]
    )
    const { img, info } = useItem(item)
    return (
        <div
            className="castle-slider__item castle-slider__item_position-marginRight items-center"
            onClick={() => setSelectedItem(item.lootbox)}
        >
            <div
                className={classNames([
                    'castle-slider__lootboxPower',
                    'castle-slider__lootboxPower_position-topRight',
                ])}
            >
                {item.lootbox?.n_power}
            </div>
            <div className="castle-slider__icon">
                <img src={img} alt="" />
            </div>
            <div className="castle-slider__name">{info.name}</div>
            {items.length !== 1 && (
                <p className="castle-slider__lootbox-amount">x{items.length}</p>
            )}
        </div>
    )
}
